import { AccordionContext } from "components/utils/Accordion/Accordion.context";
import CTAButton from "components/utils/Button/CTAButton";
import { RecordsSetting } from "components/utils/Table/_Table.settings";
import { SportsmanDatasourceIdentifierRecord } from "data/SportsmanDatasourceIdentifierRecord";
import { Routing } from "helpers/routing";
import React, { useContext } from "react";
import { CheckIcon, EllipsisHIcon } from "styles/fontAwesome";

export const Settings = (
  id?: {
    sport?: string
    sportsman?: string
  }
): RecordsSetting<SportsmanDatasourceIdentifierRecord> => [
  {
    accessor: 'id',
    className: 'table-hidden',
  },
  {
    accessor: 'is_enabled',
    Header: 'Enabled',
    className: 'table-enabled',
    Cell: function CellContent({ row }: any) {
      return <>{row.values.is_enabled && <CheckIcon />}</>
    }
  },
  {
    accessor: 'identifier',
    Header: 'Identifier'
  },
  {
    accessor: 'identifier_kind',
    Header: 'Identifier kind'
  },
  {
    accessor: 'admin_note',
    Header: 'Admin Note'
  },
  {
    id: 'ctaButton',
    sticky: 'right',
    className: 'table-cta-button',
    Header: function HeaderContent({ row }: any) {
      const { toggle } = useContext(AccordionContext)

      return (
        <CTAButton onClick={toggle}>
          <EllipsisHIcon />
        </CTAButton>
      )
    },
    Cell: function CellContent({ row }: any) {
      return (
        <CTAButton path={Routing.getSportsmanDatasourceIdentifierDetailUrl({
              sportsman: id?.sportsman ?? Routing.NewRecordSymbol,
              sport: id?.sport ?? Routing.NewRecordSymbol,
              sportsmanDatasourceIdentifier: row.values.id
            })
          }>
          {row.values.isFrozen ? 'View' : 'Edit'}
        </CTAButton>
      )
    },
  },
]

export default Settings
