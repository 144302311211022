import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class DatasourceRecord<TJsonData extends DatasourceRecord.CtorJsonData = DatasourceRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  ident?: string
  name?: string
  is_enabled?: boolean | null
  priority?: number
  admin_note?: string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:DatasourceRecord.CtorJsonData) {
    return new DatasourceRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if(jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.ident = jsonData?.ident
    this.name = jsonData?.name
    this.is_enabled = jsonData?.is_enabled
    this.priority = jsonData?.priority
    this.admin_note = jsonData?.admin_note
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      ident: this.ident,
      name: this.name,
      is_enabled: this.is_enabled,
      priority: this.priority,
      admin_note: this.admin_note
    }) as TJsonData
  }

}

export module DatasourceRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    ident?: string
    name?: string
    is_enabled?: boolean | undefined
    priority?: number
    admin_note?: string
  }

  export class Repository extends RecordRepository<DatasourceRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneDatasource(record)
    }

    protected loadAll(){
      return this.getApi().getAllDatasources()
    }

    protected loadById(id: string): Promise<DatasourceRecord | null> {
      return this.getApi().getOneDatasourceById(id)
    }
    protected updateById(id: string, patch: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneDatasource(id, patch)
    }
    protected deleteById(id: string): Promise<DatasourceRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneDatasource(id)
    }
    protected approveById(id: string, patch: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<DatasourceRecord<CtorJsonData>>): Promise<DatasourceRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }

  }

}
