import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import UIColumnFilter from 'components/utils/Filter/ColumnFilter'
// Styles
import { LockIcon, CheckIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { Tab } from 'static/tabs'
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { MatchRecord } from 'data/MatchRecord'
import { DateTime } from 'utils/dateTime'
import { RecordsSetting } from 'components/utils/Table/_Table.settings'

export module Settings {
  export const get = (
    id?: {
      tournament?: string
    },
    options?: {
      disableNew?: boolean
      hide?: {
        tournament?: boolean
      }
    }
  ): RecordsSetting<MatchRecord> => {
    return [
      {
        accessor: 'id',
        className: 'table-hidden',
      },
      {
        accessor: 'isFrozen',
        className: 'table-lock',
        Header: function HeaderContent({ row }: any) {
          return <LockIcon />
        },
        Cell: function CellContent({ row }: any) {
          return <>{row.values.isFrozen && <LockIcon />}</>
        },
      },
      {
        accessor: 'tournament_id',
        className: 'table-hidden',
      },
      {
        accessor: 'tournamentStage_id_filter',
        className: 'table-hidden',
        Filter: function FilterContent(p: any) {
          return <UIColumnFilter from={Tab.Matches} filter={p} />
        },
        filter: 'fuzzyText',
      },
      {
        accessor: 'tournamentRound_id_filter',
        className: 'table-hidden',
        Filter: function FilterContent(p: any) {
          return <UIColumnFilter from={Tab.Matches} filter={p} />
        },
        filter: 'fuzzyText',
      },
      {
        accessor: 'tournament_name',
        Header: 'Tournament',
        className: options?.hide?.tournament
          ? 'table-hidden'
          : 'table-tournament-name',
      },
      {
        accessor: 'tournamentStage_name',
        Header: 'Stage',
        className: 'table-stage-name',
      },
      {
        accessor: 'tournamentRound_name',
        Header: 'Round',
        className: 'table-round-name',
      },
      {
        accessor: 'starts_at',
        Header: 'Starts At',
        className: 'table-starts-at',
        Cell: ({ row }: any) =>
          row.values?.starts_at
            ? DateTime.shortDate(row.values?.starts_at)
            : '---',
      },
      {
        accessor: 'box',
        Header: 'Box',
        className: 'table-box',
      },
      {
        accessor: 'team1_name',
        Header: 'Team 1',
        className: 'table-first-team',
      },
      {
        accessor: 'team2_name',
        Header: 'Team 2',
        className: 'table-second-team',
      },
      {
        accessor: 'score',
        Header: 'Score',
        className: 'table-score',
      },
      {
        accessor: 'datasource_id',
        Header: 'Datasource-ID',
        className: 'table-datasource-id',
      },
      {
        accessor: 'isApproved',
        Header: 'Approved',
        className: 'table-approved',
        Cell: function CellContent({ row }: any) {
          return <>{row.values.isApproved && <CheckIcon />}</>
        },
      },
      {
        id: 'ctaButton',
        sticky: 'right',
        className: 'table-cta-button',
        Header: function HeaderContent({ row }: any) {
          const { toggle } = useContext(AccordionContext)

          if (options?.disableNew) return <></>

          return (
            <CTAButton onClick={toggle}>
              <EllipsisHIcon />
            </CTAButton>
          )
        },
        Cell: function CellContent({ row }: any) {
          return (
            <CTAButton
              path={Routing.getMatchDetailUrl({
                tournament: id?.tournament ?? Routing.NewRecordSymbol,
                match: row.values.id,
              })}
            >
              {row.values.isFrozen ? 'View' : 'Edit'}
            </CTAButton>
          )
        },
      },
    ]
  }
}
