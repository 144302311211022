import { BaseRecord } from 'data/BaseRecord'
import { OneOrMore } from 'types/oneOrMore'
import { BaseRootRepository } from './BaseRootRepository'
import { Semaphore } from 'utils/semaphore'
import { Id } from 'types/id'

// In milliseconds
const MAX_WAITING_TIMEOUT = 60000
const CHECK_READY_INTERVAL = 100

export abstract class RecordRepository<TRecord extends BaseRecord> {
  #items:TRecord[] = []

  #semaphore: Semaphore<TRecord | null> = new Semaphore<TRecord | null>(MAX_WAITING_TIMEOUT, CHECK_READY_INTERVAL)

  get length() {
    return this.#items.length
  }

  #root:BaseRootRepository
  protected getApi() {
    return this.#root.api
  }
  protected getApiOrThrow() {
    const result = this.getApi()
    if (result) return result

    throw new Error("Api is not available.")
  }

  constructor(root:BaseRootRepository) {
    this.#root = root
  }

  peek(id:Id) {
    return id ? this.#items.find(x => x?.id == id) : null
  }

  peekAll(predicate?:(x:TRecord) => boolean) {
    if (predicate) {
      return this.#items.filter(predicate)
    }
    else {
      return [...this.#items]
    }
  }

  peekMore(ids:Id[]) {
    return ids?.map(x => this.peek(x))?.filter(x => x)
  }

  async get(id:Id, options?:RecordRepository.GetOptions) {
    if (!id) return null

    const getItem = async (id:Id, options?:RecordRepository.GetOptions) => {
      if (!options?.reload) {
        const existing = this.peek(id)
        if (existing) return existing
      }

      const loaded = await this.loadById(id!)
      if (!loaded) return null
      if (loaded.id != id) return null

      this.store(loaded)
      return loaded
    }

    return (await this.#semaphore.registerAndSolveRequest(() => getItem(id, options)))
  }

  store(record:TRecord) {
    const id = record?.id
    if (!id) return false

    const ndx = this.#items.findIndex(x => x?.id == id)
    if (ndx >= 0) {
      const existingRecord = this.#items[ndx]
      if (existingRecord) {
        const jsData = record.toJson()
        existingRecord.patchData(jsData)
      }
      else {
        this.#items[ndx] = record
      }
    }
    else {
      this.#items.push(record)
    }

    return true
  }

  storeMore(records:(TRecord | null)[]) {
    if (!records) return 0

    let counter = 0
    for(const record of records) {
      if (record == null) continue

      if (this.store(record)) {
        counter++
      }
    }

    return counter
  }

  async storePayload(payload:Promise<OneOrMore<TRecord | null>>) {
    if (payload == null) return null

    const data = await payload
    const records = OneOrMore.arrayize(data)
    if (records == null) return null

    this.storeMore(records)

    const recordIds = records?.map(x => x?.id)?.filter(x => x)
    return this.peekMore(recordIds)
  }

  async create(data:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.create(data)
    if (record) {
      this.store(record)
    }

    return record
  }

  async update(id:string, patch:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.updateById(id, patch)
    if (record) {
      this.store(record)
    }

    return record
  }

  async approve(id:string, patch:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.approveById(id, patch)
    if (record) {
      this.store(record)
    }

    return record
  }

  async unapprove(id:string, patch:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.unapproveById(id, patch)
    if (record) {
      this.store(record)
    }

    return record
  }

  async publish(id:string, patch:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.publishById(id, patch)
    if (record) {
      this.store(record)
    }

    return record
  }

  async unpublish(id:string, patch:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.unpublishById(id, patch)
    if (record) {
      this.store(record)
    }

    return record
  }

  async freeze(id:string, patch:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.freezeById(id, patch)
    if (record) {
      this.store(record)
    }

    return record
  }

  async unfreeze(id:string, patch:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.unfreezeById(id, patch)
    if (record) {
      this.store(record)
    }

    return record
  }

  async touch(id:string):Promise<number | null> {
    return await this.touchById(id)
  }

  async touchAll():Promise<number | null> {
    return await this.touchAll_()
  }

  async recalculateStatsById(id:string, data:Partial<TRecord>):Promise<TRecord | null> {
    const record = await this.recalculateStatsById(id, data)
    if (record) {
      this.store(record)
    }

    return record
  }

  async delete(id:string):Promise<boolean> {
    const record = await this.deleteById(id)

    if (record == null) {
      const ndx = this.#items.findIndex(x => x.id == id)
      if (ndx >= 0) {
        this.#items.splice(ndx, 1)
      }

      // record was deleted
      return true
    }
    else {
      this.store(record)
    }

    // record was not deleted
    return false
  }

  protected abstract loadById(id:string):Promise<TRecord | null>

  protected abstract updateById(id:string, patch:Partial<TRecord>):Promise<TRecord | null>

  protected abstract deleteById(id: string):Promise<TRecord | null>

  protected abstract approveById(id:string, patch:Partial<TRecord>):Promise<TRecord | null>

  protected abstract unapproveById(id:string, patch:Partial<TRecord>):Promise<TRecord | null>

  protected abstract publishById(id:string, patch:Partial<TRecord>):Promise<TRecord | null>

  protected abstract unpublishById(id:string, patch:Partial<TRecord>):Promise<TRecord | null>

  protected abstract freezeById(id:string, patch:Partial<TRecord>):Promise<TRecord | null>

  protected abstract unfreezeById(id:string, patch:Partial<TRecord>):Promise<TRecord | null>

  protected abstract touchById(id:string):Promise<number | null>

  protected abstract touchAll_():Promise<number | null>

  protected async peekOrLoad(field:RecordRepository.GetFieldFlags, getOptions:RecordRepository.GetOptions | undefined, options:{
    peek: () => TRecord[],
    load: () => Promise<Array<TRecord | null>>
  }) {
    const reload = getOptions?.reload
    if (!reload && field.loaded && this.length > 0) {
      return options.peek()
    }

    const payload = options.load()
    const result = await this.storePayload(payload)
    field.loaded = true
    return result
  }

  protected async nestedPeekOrLoad(nestingField:RecordRepository.NestingGetFieldFlags, key:string | number, getOptions:RecordRepository.GetOptions | undefined, options:{
    peek: () => TRecord[],
    load: () => Promise<Array<TRecord | null>>
  }) {
    const field = nestingField[key] ?? (nestingField[key] = {})
    return this.peekOrLoad(field, getOptions, options)
  }
}

export module RecordRepository {
  export type GetOptions = {
    reload?: boolean
  }

  export type GetFieldFlags = {
    loaded?: boolean
  }

  export type NestingGetFieldFlags = Record<PropertyKey, GetFieldFlags>
}
