import { DateTime } from "utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { TournamentRecord } from "./TournamentRecord";

export class TournamentBiographyRecord<TJsonData extends TournamentBiographyRecord.CtorJsonData = TournamentBiographyRecord.CtorJsonData>
  extends BaseRecord<TJsonData> {
  perex?: string
  text?: string
  images?: string
  facebook?: string
  twich?: string
  twitter?: string
  instagram?: string
  data_frozen_at?: Date | null

  /// >>> tournament >>>
  #tournament: BaseRecord.RefField<TournamentRecord> = {}

  tournament_id?: string

  get tournament() {
    return this.#tournament.object
  }

  getTournament() {
    this.#tournament.id = this.tournament_id

    return this.getObjectFromRefField(this.#tournament, "tournament")
  }
  /// <<< tournament <<<

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sortValues() {
    return [
      this.tournament_id ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?: BaseRootRepository.OrNothing, jsonData?: TournamentBiographyRecord.CtorJsonData) {
    return new TournamentBiographyRecord(db, jsonData)
  }

  constructor(db?: BaseRootRepository.OrNothing, jsonData?: TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.perex = jsonData?.perex
    this.text = jsonData?.text
    this.images = jsonData?.images
    this.facebook = jsonData?.facebook
    this.twich = jsonData?.twich
    this.twitter = jsonData?.twitter
    this.instagram = jsonData?.instagram
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.tournament_id = jsonData?.tournament_id
  }

  toJson(): TJsonData {
    return Object.assign(super.toJson(), {
      perex: this.perex,
      text: this.text,
      images: this.images,
      facebook: this.facebook,
      twich: this.twich,
      twitter: this.twitter,
      instagram: this.instagram,
      data_frozen_at: this.data_frozen_at?.toISOString(),
      tournament_id: this.tournament_id
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const tournament = await this.getTournament()
    await tournament?.preload()
  }
}

export module TournamentBiographyRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    perex?: string
    text?: string
    images?: string
    facebook?: string
    twich?: string
    twitter?: string
    instagram?: string
    data_frozen_at?: string
    tournament_id?: string
  }

  export class Repository extends RecordRepository<TournamentBiographyRecord> {
    /// >>> by tournament_id >>>
    #getByTournamentId:RecordRepository.NestingGetFieldFlags = {}
    getByTournamentId(tournamentId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTournamentId, tournamentId, options, {
        peek: () => this.peekByTournamentId(tournamentId),
        load: async () => [await this.loadByTournamentId(tournamentId)]
      })
    }

    peekByTournamentId(tournamentId:string) {
      return this.peekAll(x => x.tournament_id == tournamentId)
    }

    protected loadByTournamentId(tournamentId:string) {
      return this.getApi().getOneTournamentBiographyByTournamentId(tournamentId)
    }
    /// <<< by tournament_id <<<

    loadAll() {
      return this.getApi().getAllTournamentBiographies()
    }

    create(record: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTournamentBiography(record)
    }

    protected loadById(id: string) {
      return this.getApi().getOneTournamentBiographyById(id)
    }
    protected updateById(id: string, patch: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTournamentBiography(id, patch)
    }
    protected deleteById(id: string): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTournamentBiography(id)
    }

    /// >>> not implemented methods >>>
    protected approveById(id: string, patch: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<TournamentBiographyRecord<CtorJsonData>>): Promise<TournamentBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    /// <<< not implemented methods <<<<
  }
}
