import React, { ComponentProps, useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FormItem from 'components/utils/FormItem'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
import { ItemWrapper, OffsetedContainer } from 'styles/common'
import { AppContext } from 'App'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Col, Form } from 'react-bootstrap'
import FormCheck from 'components/utils/FormCheck'
import FormDateTimePicker from 'components/utils/Form/Picker/DateTimePicker'
import { TournamentDatasourceIdentifierRecord } from 'data/TournamentDatasourceIdentifierRecord'
import Json from 'components/utils/FormJsonWrapper'


const SubmitKeys = [
  'admin_note',
  'identifier',
  'identifier_kind',
  'is_enabled',
  'is_primary',
  'tournament_id',
  'original_data',
  'last_data_change_at'
] as Array<keyof TournamentDatasourceIdentifierRecord>

type ValidationKeys = Record<
  keyof Pick<TournamentDatasourceIdentifierRecord, 'identifier' | 'identifier_kind'>,
  any
>

const TournamentDatasourceIdentifierForm: React.FC<FormHelper.Props<TournamentDatasourceIdentifierRecord>> = (p) => {
  const { db, alert, history } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: TournamentDatasourceIdentifierRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Tournament Datasource Identifier',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,
    },

    onSubmit: async (values) => {
      if (!db?.teamDatasourceIdentifiers) return

      try {
        const data = await FormHelper.submitChanges<TournamentDatasourceIdentifierRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new TournamentDatasourceIdentifierRecord(), values ?? {}),
          repository: db.tournamentDatasourceIdentifiers,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getTournamentDatasourceIdentifierDetailUrl({
              tournamentDatasourceIdentifier: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      identifier: Yup.string().required('Identifier is a required field'),
      identifier_kind: Yup.string().required('Identifier_kind is a required field'),
    }),
  })

   //#region Form Components
  const _Identifier = (
    <FormItem
      label={<Form.Label>Identifier</Form.Label>}
      input={
        <>
          <Form.Control
            name={'identifier' as keyof TournamentDatasourceIdentifierRecord}
            value={formik.values.identifier ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.identifier}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.identifier}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _IdentifierKind = (
    <FormItem
      label={<Form.Label>Identifier kind</Form.Label>}
      input={
        <>
          <Form.Control
            name={'identifier_kind' as keyof TournamentDatasourceIdentifierRecord}
            value={formik.values.identifier_kind ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.identifier_kind}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.identifier_kind}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _IsEnabled = (
    <FormCheck
      name={'is_enabled' as keyof TournamentDatasourceIdentifierRecord}
      value={formik.values.is_enabled ?? false}
      checked={formik.values.is_enabled ?? false}
      offset={{direction: 'left'}}
      title={'Is Enabled'}
      scheme={'green'}
      checkedCallback={() => {
        formik.setFieldValue('is_enabled' as keyof TournamentDatasourceIdentifierRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('is_enabled' as keyof TournamentDatasourceIdentifierRecord, false)
      }}
    />
  )


  const _IsPrimary = (
    <FormCheck
      name={'is_primary' as keyof TournamentDatasourceIdentifierRecord}
      value={formik.values.is_primary ?? false}
      checked={formik.values.is_primary ?? false}
      offset={{direction: 'left'}}
      title={'Is Primary'}
      scheme={'green'}
      checkedCallback={() => {
        formik.setFieldValue('is_primary' as keyof TournamentDatasourceIdentifierRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('is_primary' as keyof TournamentDatasourceIdentifierRecord, false)
      }}
    />
  )

  const _LastDataChangeAt = (
    <FormItem
      label={<Form.Label>Last data change at</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'last_data_change_at' as keyof TournamentDatasourceIdentifierRecord}
            value={formik.values.last_data_change_at ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue(
                'last_data_change_at' as keyof TournamentDatasourceIdentifierRecord,
                date
              )
            }}
            readOnly
          />
        </>
      }
    />
  )

  const _AdminNote = (
    <FormItem
      label={<Form.Label>Admin note</Form.Label>}
      input={
        <Form.Control
          name={'admin_note' as keyof TournamentDatasourceIdentifierRecord}
          value={formik.values.admin_note ?? ''}
          onChange={formik.handleChange}
        />
      }
    />
  )

  const _OriginalData = (
    <FormItem
      label={<Form.Label>Original data</Form.Label>}
      input={
        <>
          <Form.Control
            name={'original_data' as keyof TournamentDatasourceIdentifierRecord}
            value={formik.values.original_data ?? ''}
            hidden
          />
          <Json
            json={formik.values.original_data ?? ''}
          />
        </>
      }
    />
  )
  //#endregion

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {} }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Header />

          <Form.Row>
            <Col>
              <Form.Row>
                <Col as={ItemWrapper}>{_Identifier}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_IdentifierKind}</Col>
              </Form.Row>
            </Col>
            <Col>
              <Form.Row>
                <Col as={ItemWrapper}>{_IsEnabled}</Col>
                <Col as={ItemWrapper}>{_IsPrimary}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_LastDataChangeAt}</Col>
              </Form.Row>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col as={ItemWrapper}>{_AdminNote}</Col>
          </Form.Row>

          <Form.Row>
            <Col as={ItemWrapper}>{_OriginalData}</Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default TournamentDatasourceIdentifierForm
