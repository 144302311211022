import { DateTime } from "../utils/dateTime"
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { SportRecord } from "./SportRecord"
import { TournamentRoundRecord } from "./TournamentRoundRecord"

export class TournamentRecord<TJsonData extends TournamentRecord.CtorJsonData = TournamentRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  tournament_datasource_identifier_id?: string
  sport_id?: string
  is_sazka_eleague?: boolean | null
  ident?: string
  name?: string
  short_name?: string
  logo_url?: string
  photo_url?: string
  location?: string
  first_place_rank_picture_url?: string
  second_place_rank_picture_url?: string
  third_place_rank_picture_url?: string
  starts_at?: Date | null
  ends_at?: Date | null
  data_frozen_at?: Date | null
  data_approved_at?: Date | null
  published_at?: Date | null
  prize_pool?: string
  related_tournaments?: string
  flag_for_nearest_matches?: boolean
  tier?: number
  format?: string
  detail?: string

  /// >>> current round >>>
  #currentRound: BaseRecord.RefField<TournamentRoundRecord> = {}

  current_round_id?: string

  get current_round() {
    return this.#currentRound.object
  }
  getCurrentRound() {
    this.#currentRound.id = this.current_round_id

    return this.getObjectFromRefField(this.#currentRound, "tournamentRounds")
  }
  /// <<< current round <<<

  /// >>> previous round >>>
  #previousRound: BaseRecord.RefField<TournamentRoundRecord> = {}

  previous_round_id?: string

  get previous_round() {
    return this.#previousRound.object
  }
  getPreviousRound() {
    this.#previousRound.id = this.previous_round_id

    return this.getObjectFromRefField(this.#previousRound, "tournamentRounds")
  }
  /// <<< previous round <<<

  /// >>> next round >>>
  #nextRound: BaseRecord.RefField<TournamentRoundRecord> = {}

  next_round_id?: string

  get next_round() {
    return this.#nextRound.object
  }
  getNextRound() {
    this.#nextRound.id = this.next_round_id

    return this.getObjectFromRefField(this.#nextRound, "tournamentRounds")
  }
  /// <<< next round <<<

  /// >>> sport >>>
  #sport: BaseRecord.RefField<SportRecord> = {}

  get sport() {
    this.#sport.id = this.sport_id

    return this.peekObjectFromRefField(this.#sport, "sports")
  }
  getSport() {
    this.#sport.id = this.sport_id

    return this.getObjectFromRefField(this.#sport, "sports")
  }
  /// <<< sport <<<

  /// >>> computed_values >>>
  get isApproved() {
    return !!this.data_approved_at
  }

  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sport_name() {
    return this.sport?.short_name
  }

  get sortValues() {
    return [
      // sort from the actual date to latest
      Number.MAX_SAFE_INTEGER - (this.starts_at?.getTime() ?? 0)
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?: BaseRootRepository.OrNothing, jsonData?: TournamentRecord.CtorJsonData) {
    return new TournamentRecord(db, jsonData)
  }

  constructor(db?: BaseRootRepository.OrNothing, jsonData?: TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.current_round_id = jsonData?.current_round_id
    this.previous_round_id = jsonData?.previous_round_id
    this.next_round_id = jsonData?.next_round_id
    this.tournament_datasource_identifier_id = jsonData?.tournament_datasource_identifier_id
    this.sport_id = jsonData?.sport_id
    this.is_sazka_eleague = jsonData?.is_sazka_eleague
    this.ident = jsonData?.ident
    this.name = jsonData?.name
    this.short_name = jsonData?.short_name
    this.logo_url = jsonData?.logo_url
    this.photo_url = jsonData?.photo_url
    this.location = jsonData?.location
    this.first_place_rank_picture_url = jsonData?.first_place_rank_picture_url
    this.second_place_rank_picture_url = jsonData?.second_place_rank_picture_url
    this.third_place_rank_picture_url = jsonData?.third_place_rank_picture_url
    this.starts_at = DateTime.parseDateOrNull(jsonData?.starts_at)
    this.ends_at = DateTime.parseDateOrNull(jsonData?.ends_at)
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.data_approved_at = DateTime.parseDateOrNull(jsonData?.data_approved_at)
    this.published_at = DateTime.parseDateOrNull(jsonData?.published_at)
    this.prize_pool = jsonData?.prize_pool
    this.related_tournaments = jsonData?.related_tournaments
    this.flag_for_nearest_matches = jsonData?.flag_for_nearest_matches
    this.tier = jsonData?.tier
    this.format = jsonData?.format
    this.detail = jsonData?.detail
  }

  toJson(): TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      current_round_id: this.current_round_id,
      previous_round_id: this.previous_round_id,
      next_round_id: this.next_round_id,
      tournament_datasource_identifier_id: this.tournament_datasource_identifier_id,
      sport_id: this.sport_id,
      is_sazka_eleague: this.is_sazka_eleague,
      ident: this.ident,
      name: this.name,
      short_name: this.short_name,
      logo_url: this.logo_url,
      photo_url: this.photo_url,
      location: this.location,
      first_place_rank_picture_url: this.first_place_rank_picture_url,
      second_place_rank_picture_url: this.second_place_rank_picture_url,
      third_place_rank_picture_url: this.third_place_rank_picture_url,
      starts_at: this.starts_at?.toISOString(),
      ends_at: this.ends_at?.toISOString(),
      data_frozen_at: this.data_frozen_at?.toISOString(),
      data_approved_at: this.data_approved_at?.toISOString(),
      published_at: this.published_at?.toISOString(),
      prize_pool: this.prize_pool,
      related_tournaments: this.related_tournaments,
      flag_for_nearest_matches: this.flag_for_nearest_matches,
      tier: this.tier,
      format: this.format,
      detail: this.detail
    }) as TJsonData
  }

  #isPreloading = false

  async preload() {
    if (this.#isPreloading) return

    try {
      this.#isPreloading = true

      await super.preload()

      const currentRound = await this.getCurrentRound()
      await currentRound?.preload()

      const previousRound = await this.getPreviousRound()
      await previousRound?.preload()

      const nextRound = await this.getNextRound()
      await nextRound?.preload()
    } finally {
      this.#isPreloading = false
    }
  }
}

export module TournamentRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    current_round_id?: string
    previous_round_id?: string
    next_round_id?: string
    tournament_datasource_identifier_id?: string
    sport_id?: string
    is_sazka_eleague?: boolean | undefined
    ident?: string
    name?: string
    short_name?: string
    logo_url?: string
    photo_url?: string
    location?: string
    first_place_rank_picture_url?: string
    second_place_rank_picture_url?: string
    third_place_rank_picture_url?: string
    starts_at?: string
    ends_at?: string
    data_frozen_at?: string
    data_approved_at?: string
    published_at?: string
    prize_pool?: string
    related_tournaments?: string
    flag_for_nearest_matches?: boolean
    tier?: number
    format?: string
    detail?: string
  }

  export class Repository extends RecordRepository<TournamentRecord> {
    #getAllLoaded: RecordRepository.GetFieldFlags = {}
    async getAll(options?: RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAllLoaded, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTournament(record)
    }

    protected loadById(id: string) {
      return this.getApi().getOneTournamentById(id)
    }

    protected loadAll() {
      return this.getApi().getAllTournaments()
    }

    protected updateById(id: string, patch: Partial<TournamentRecord>) {
      const api = this.getApiOrThrow()
      return api.updateOneTournament(id, patch)
    }

    protected deleteById(id: string): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTournament(id)
    }

    protected approveById(id: string, patch: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.approveOneTournamentById(id, patch)
    }

    protected unapproveById(id: string, patch: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unapproveOneTournamentById(id, patch)
    }

    protected publishById(id: string, patch: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.publishOneTournamentById(id, patch)
    }

    protected unpublishById(id: string, patch: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unpublishOneTournamentById(id, patch)
    }

    protected freezeById(id: string, patch: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneTournamentById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneTournamentById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTournaments()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTournamentById(id)
    }

    recalculateStatsById(id: string, patch: Partial<TournamentRecord<CtorJsonData>>): Promise<TournamentRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.recalculateTournamentStatsById(id, patch)
    }
  }
}
