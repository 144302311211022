import { AlertManager } from 'react-alert'
import { RecordRepository } from 'data/repository/RecordRepository'
import { BaseRecord } from 'data/BaseRecord'
import { Compare } from 'utils/compare'
import { object } from 'utils/object'

export module Form {
  export type FormState = 'new' | 'existing'

  export interface Props<TRecord extends BaseRecord> {
    readonly data?: TRecord
    readonly state?: FormState

    readonly locked?: {
      value: boolean

      callback: (locked: boolean) => void
    }

    readonly submitCallback?: (data?: TRecord) => void
  }

  type SubmitActionType = 'create' | 'update'
  type DeleteActionType = 'delete'
  type ApproveActionType = 'approve' | 'unapprove'
  type PublishActionType = 'publish' | 'unpublish'
  type FreezeActionType = 'freeze' | 'unfreeze'
  type TouchActionType = 'touch'

  type AdditionalActionType =
    | DeleteActionType
    | ApproveActionType
    | PublishActionType
    | FreezeActionType
    | TouchActionType

  export type ActionType = SubmitActionType | AdditionalActionType

  const translation: { [K in ActionType]: string } = {
    create: 'created',
    delete: 'deleted',
    update: 'updated',
    approve: 'approved',
    unapprove: 'unapproved',
    publish: 'publish',
    unpublish: 'unpublish',
    freeze: 'frozen',
    unfreeze: 'unfrozen',
    touch: 'touched',
  }

  export type DataParams<T extends BaseRecord> = {
    data: T
    repository: RecordRepository<T>
  }

  export type AlertParams = {
    manager?: AlertManager
    texts: {
      form: string
      forcePlural?: boolean
    }
  }

  type HistoryParams = any /* History<unknown> */

  type LockParams = {
    callback?: (value: boolean) => void
  }

  type SubmitParams<T extends BaseRecord> = DataParams<T> & {
    action: SubmitActionType
    keys: Array<keyof T>
    optional?: {
      alert?: AlertParams
      history?: HistoryParams
      lock?: LockParams
    }
  }

  export const submitChanges = async <T extends BaseRecord>(
    params: SubmitParams<T>
  ): Promise<T | null> => {
    const { data, repository, optional, action, keys } = params

    object.replaceEmptyStringsByNull(data)

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation[action],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    let resultRecord: T | null = null

    try {
      if (action === 'create') {
        resultRecord = await repository?.create(data)
      } else if (action === 'update') {
        const existingRecord = await repository?.get(data.id)

        if (existingRecord == null) {
          throw new Error('Record was not found.')
        }

        const patchRecord = existingRecord.cloneRecord()
        keys?.forEach((key) => {
          patchRecord[key] = data[key]
        })

        const recordChanges = Compare.getChanges<T>(
          await repository?.get(data.id, { reload: true }),
          data,
          keys ?? []
        )

        if (Object.keys(recordChanges).length < 1) {
          optional?.alert?.manager?.show('Nothing to update!', {
            type: 'info',
          })

          return resultRecord
        }

        resultRecord = await repository?.update(patchRecord.id, patchRecord)
      }

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)

      // // force reload to refresh tables
      if (resultRecord) {
        await repository?.get(resultRecord.id, { reload: true })
      }
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }

    return resultRecord
  }

  type DeleteParams<T extends BaseRecord> = DataParams<T> & {
    optional?: {
      alert?: AlertParams
      history?: HistoryParams
    }
  }

  export const delete_ = async <T extends BaseRecord>(
    params: DeleteParams<T>
  ) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['delete'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      await repository?.delete(data?.id ?? '-')

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)

      optional?.history?.goBack()
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  type ApproveParams<T extends BaseRecord> = DataParams<T> & {
    optional?: {
      alert?: AlertParams
    }
  }

  export const approve = async <T extends BaseRecord>(
    params: ApproveParams<T>
  ) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['approve'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      await repository?.approve(data?.id ?? '-', data)

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  export const unapprove = async <T extends BaseRecord>(
    params: ApproveParams<T>
  ) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['unapprove'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      await repository?.unapprove(data?.id ?? '-', data)

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  type PublishParams<T extends BaseRecord> = DataParams<T> & {
    optional?: {
      alert?: AlertParams
    }
  }

  export const publish = async <T extends BaseRecord>(
    params: PublishParams<T>
  ) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['publish'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      await repository?.publish(data?.id ?? '-', data)

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  type UnpublishParams<T extends BaseRecord> = DataParams<T> & {
    optional?: {
      alert?: AlertParams
    }
  }

  export const unpublish = async <T extends BaseRecord>(
    params: UnpublishParams<T>
  ) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['unpublish'],
      forcePlural: params.optional?.alert?.texts.forcePlural
    } as AlertTextParams

    try {
      await repository?.unpublish(data?.id ?? '-', data)

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  type FreezeParams<T extends BaseRecord> = DataParams<T> & {
    optional?: {
      alert?: AlertParams
      history?: HistoryParams
      lock?: LockParams
    }
  }

  export const freeze = async <T extends BaseRecord>(
    params: FreezeParams<T>
  ) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['freeze'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      await repository?.freeze(data?.id ?? '-', data)

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)

      optional?.lock?.callback?.(true)
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  export const unfreeze = async <T extends BaseRecord>(
    params: FreezeParams<T>
  ) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['unfreeze'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      await repository?.unfreeze(data?.id ?? '-', data)

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)

      optional?.lock?.callback?.(false)
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  type TouchParams<T extends BaseRecord> = DataParams<T> & {
    optional?: {
      alert?: AlertParams
    }
  }

  export const touch = async <T extends BaseRecord>(params: TouchParams<T>) => {
    const { data, repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['touch'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      await repository?.touch(data?.id ?? '-')

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, _texts)
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, _texts)

      throw err
    }
  }

  type TouchAllParams<T extends BaseRecord> = {
    repository: RecordRepository<T>
    optional?: {
      alert?: AlertParams
    }
  }

  export const touchAll = async <T extends BaseRecord>(
    params: TouchAllParams<T>
  ) => {
    const { repository, optional } = params

    const _texts = {
      name: optional?.alert?.texts.form,
      action: translation['touch'],
      forcePlural: params.optional?.alert?.texts.forcePlural,
    } as AlertTextParams

    try {
      const rowCount = await repository?.touchAll()

      optional?.alert?.manager &&
        alert('success', optional.alert.manager, {
          ..._texts,
          //@ts-ignore
          rows: rowCount.rowCount, // TODO fix rowCount
        })
    } catch (err) {
      optional?.alert?.manager &&
        alert('danger', optional.alert.manager, {
          ..._texts,
          forcePlural: true,
        })

      throw err
    }
  }

  type AlertTextParams = {
    name: string
    action: string
    rows?: number | null
    forcePlural?: boolean
  }

  export const alert = (
    type: 'success' | 'danger',
    alert: AlertManager,
    texts: AlertTextParams
  ) => {
    const _form = `${texts.name} ${texts.forcePlural ? 'were' : 'was'}`

    const _rows =
      texts.rows &&
      `${texts.rows} ${texts.name.toLowerCase()} ${
        texts.forcePlural || texts.rows !== 1 ? 'were' : 'was'
      }`

    const _success = `${_rows ?? _form} successfully ${texts.action}`

    const _fail = `Something went wrong. ${_form} not ${texts.action}!`

    alert?.show(type === 'success' ? _success : _fail, {
      //@ts-ignore
      type: type,
    })
  }
}
