import { DateTime } from "../utils/dateTime"
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"

export class PeriodKindRecord<TJsonData extends PeriodKindRecord.CtorJsonData = PeriodKindRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  ident?: string
  start_at?: Date | null
  end_at?: Date | null

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:PeriodKindRecord.CtorJsonData) {
    return new PeriodKindRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.ident = jsonData?.ident
    this.start_at = DateTime.parseDateOrNull(jsonData?.start_at)
    this.end_at = DateTime.parseDateOrNull(jsonData?.end_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      ident: this.ident,
      start_at: this.start_at?.toISOString(),
      end_at: this.end_at?.toISOString(),
    }) as TJsonData
  }

  async preload() {
    await super.preload()
  }
}

export module PeriodKindRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    ident?: string
    start_at?: string
    end_at?: string
  }

  export class Repository extends RecordRepository<PeriodKindRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOnePeriodKind(record)
    }

    protected loadAll() {
      return this.getApi().getAllPeriodKinds()
    }

    protected loadById(id: string): Promise<PeriodKindRecord | null> {
      return this.getApi().getOnePeriodKindById(id)
    }

    protected updateById(id: string, patch: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOnePeriodKind(id, patch)
    }

    protected deleteById(id: string): Promise<PeriodKindRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOnePeriodKind(id)
    }

    protected approveById(id: string, patch: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected publishById(id: string, patch: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.")
    }

    protected unpublishById(id: string, patch: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.")
    }

    protected freezeById(id: string, patch: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<PeriodKindRecord<CtorJsonData>>): Promise<PeriodKindRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllPeriodKinds()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOnePeriodKindById(id)
    }
  }
}
