import styled, { css } from 'styled-components'

const InjectOverlayContentWrapperLayer = 10

export const InjectOverlayContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  
  background-color: white;
  
  opacity: 0.5;
  filter: brightness(1);

  z-index: ${InjectOverlayContentWrapperLayer};
`

export const TableWrapper = styled.div<{ pageHeightByRows?: number, useScrollHide?: boolean }>`
  ${({ pageHeightByRows }) => {
    if (!pageHeightByRows) return

    return css`
      min-height: ${(pageHeightByRows + 1) * 42}px;

      /* max-height: 750px; */
    `
  }}

  z-index: ${InjectOverlayContentWrapperLayer + 1};

  // >>> content centering >>>
  position: relative;

  ${InjectOverlayContentWrapper} {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      position: absolute;
    }
  }
  // <<< content centering <<<

  ${({ useScrollHide }) => {
    if (!useScrollHide) return
      
    return css`
      overflow: hidden;
    `
  }}
`

export const Order = styled.div<{ isRow?: boolean }>`
  display: flex;

  gap: 15px;
  
  ${({ isRow }) => {
    const _isRow = isRow ?? false

    if (!_isRow) {
      return css`
        flex-direction: column;
      `
    }
  }}
`

export const OrderItem = styled.div<{ order: number }>`
  ${({ order }) => {
    return css`
      order: ${order};
    `
  }}
`