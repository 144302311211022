import { css } from 'styled-components'
// Styles
import * as stls from 'styles/common'
import { TableRowStyle as _TableRowStyle, TableHeadStyle as _TableHeadStyle } from 'components/utils/Table/_Table.styles'

export const TableHeadStyle = css`
  ${stls.SecondaryBackgroundColor}

  ${_TableHeadStyle}
`

export const GetTableRowStyle = (useAsFilterView?: boolean) => css`
  ${_TableRowStyle}

  ${useAsFilterView ? css`
    cursor: pointer;
  ` : ''}

  .table-approved {
    text-align: center;
  }
`

export const TableCellStyle = css`
  ${stls.CenteredContent}
`
