import { DateTime } from '../utils/dateTime'
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"

export class RegionRecord<TJsonData extends RegionRecord.CtorJsonData = RegionRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  ident?: string
  full_name?: string
  data_frozen_at?: Date | null

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:RegionRecord.CtorJsonData) {
    return new RegionRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.ident = jsonData?.ident
    this.full_name = jsonData?.full_name
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      ident: this.ident,
      full_name: this.full_name,
      data_frozen_at: this.data_frozen_at?.toISOString(),
    }) as TJsonData
  }

  async preload() {
    await super.preload()
  }
}

export module RegionRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    ident?: string
    full_name?: string
    data_frozen_at?: string
  }

  export class Repository extends RecordRepository<RegionRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneRegion(record)
    }

    protected loadAll() {
      return this.getApi().getAllRegions()
    }

    protected loadById(id: string): Promise<RegionRecord | null> {
      return this.getApi().getOneRegionById(id)
    }

    protected updateById(id: string, patch: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneRegion(id, patch)
    }

    protected deleteById(id: string): Promise<RegionRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneRegion(id)
    }

    protected approveById(id: string, patch: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected publishById(id: string, patch: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      throw new Error('Method not implemented.')
    }

    protected unpublishById(id: string, patch: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      throw new Error('Method not implemented.')
    }

    protected freezeById(id: string, patch: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<RegionRecord<CtorJsonData>>): Promise<RegionRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllRegions()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneRegionById(id)
    }
  }
}
