import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import SportsmanNickname from '../../Sportsmen/SportsmanNickname'
// Styles
import { LockIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { TournamentSportsmanRankRecord } from 'data/TournamentSportsmanRankRecord'
import { RecordsSetting } from 'components/utils/Table/_Table.settings'

export module Settings {
  export const get = (id?: {
    tournament?: string
  }): RecordsSetting<TournamentSportsmanRankRecord> => [
    {
      accessor: 'id',
      className: 'table-hidden',
    },
    {
      accessor: 'sportsman_is_approved',
      className: 'table-hidden',
    },
    {
      accessor: 'isFrozen',
      className: 'table-lock',
      Header: function HeaderContent({ row }: any) {
        return <LockIcon />
      },
      Cell: function CellContent({ row }: any) {
        return <>{row.values.isFrozen && <LockIcon />}</>
      },
    },
    {
      accessor: 'team_name',
      Header: 'Team',
      className: 'table-team',
    },
    {
      accessor: 'sportsman_nickname',
      Header: 'Sportsman',
      className: 'table-sportsman',
      Cell: function CellContent({ row }: any) {
        return (
          <SportsmanNickname
            nickname={row.values.sportsman_nickname}
            isApproved={row.values.sportsman_is_approved}
          />
        )
      },
    },
    {
      accessor: 'rank_from',
      Header: 'Rank From',
      className: 'table-rank-from',
    },
    {
      accessor: 'rank_to',
      Header: 'Rank To',
      className: 'table-rank-to',
    },
    {
      id: 'ctaButton',
      sticky: 'right',
      className: 'table-cta-button',
      Header: function HeaderContent({ row }: any) {
        const { toggle } = useContext(AccordionContext)

        return (
          <CTAButton onClick={toggle}>
            <EllipsisHIcon />
          </CTAButton>
        )
      },
      Cell: function CellContent({ row }: any) {
        return (
          <CTAButton
            path={Routing.getSportsmanRankDetailUrl({
              tournament: id?.tournament ?? Routing.NewRecordSymbol,
              sportsmanRank: row.values.id,
            })}
          >
            {row.values.isFrozen ? 'View' : 'Edit'}
          </CTAButton>
        )
      },
    },
  ]
}
