import React, { useState, useContext } from 'react'
// Components
import SwitchButton from './SwitchButton'
import { FormContext } from 'components/utils/Form.context'

const PublishButton: React.FC<{
  defaultValue: boolean
  isLocked?: boolean
}> = (p) => {
  const { publish: f_publish, unpublish: f_unpublish } = useContext(
    FormContext.Context
  )

  const [published, setPublished] = useState<{
    value: boolean
  }>({ value: p.defaultValue })

  const handleSubmitPublish = async (): Promise<void> => {
    try {
      await f_publish?.()

      setPublished({ value: true })
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmitUnpublish = async (): Promise<void> => {
    try {
      await f_unpublish?.()

      setPublished({ value: false })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <SwitchButton
      offset={{ direction: 'left' }}
      title={'Publish'}
      scheme={'green'}
      disabled={p.isLocked}
      checked={published.value}
      checkedCallback={handleSubmitPublish}
      uncheckedCallback={handleSubmitUnpublish}
    />
  )
}

export default PublishButton
