import { DateTime } from "utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { SportsmanRecord } from "./SportsmanRecord";

export class SportsmanGamingGearsRecord<TJsonData extends SportsmanGamingGearsRecord.CtorJsonData = SportsmanGamingGearsRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  processor_id?: string
  graphics_card_id?: string
  memory_id?: string
  hard_drive_id?: string
  case_id?: string
  monitor_id?: string
  chair_id?: string
  headset_id?: string
  keyboard_id?: string
  mouse_id?: string
  mousepad_id?: string
  data_frozen_at?: Date | null

  /// >>> sportsman >>>
  #sportsman: BaseRecord.RefField<SportsmanRecord> = {}

  sportsman_id?: string

  get sportsman() {
    return this.#sportsman.object
  }

  getSportsman() {
    this.#sportsman.id = this.sportsman_id

    return this.getObjectFromRefField(this.#sportsman, "sportsmen")
  }
  /// <<< sportsman <<<

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sortValues() {
    return [
      this.sportsman_id ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< comuted_values <<<

  static createNew(db?: BaseRootRepository.OrNothing, jsonData?: SportsmanGamingGearsRecord.CtorJsonData) {
    return new SportsmanGamingGearsRecord(db, jsonData)
  }

  constructor(db?: BaseRootRepository.OrNothing, jsonData?: TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.processor_id = jsonData?.processor_id
    this.graphics_card_id = jsonData?.graphics_card_id
    this.memory_id = jsonData?.memory_id
    this.hard_drive_id = jsonData?.hard_drive_id
    this.case_id = jsonData?.case_id
    this.monitor_id = jsonData?.monitor_id
    this.chair_id = jsonData?.chair_id
    this.headset_id = jsonData?.headset_id
    this.keyboard_id = jsonData?.keyboard_id
    this.mouse_id = jsonData?.mouse_id
    this.mousepad_id = jsonData?.mouse_id
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.sportsman_id = jsonData?.sportsman_id
  }

  toJson(): TJsonData {
    return Object.assign(super.toJson(), {
      processor_id: this.processor_id,
      graphics_card_id: this.graphics_card_id,
      memory_id: this.memory_id,
      hard_drive_id: this.hard_drive_id,
      case_id: this.case_id,
      monitor_id: this.monitor_id,
      chair_id: this.chair_id,
      headset_id: this.headset_id,
      keyboard_id: this.keyboard_id,
      mouse_id: this.mouse_id,
      mousepad_id: this.mousepad_id,
      data_frozen_at: this.data_frozen_at?.toISOString(),
      sportsman_id: this.sportsman_id
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const sportsman = await this.getSportsman()
    await sportsman?.preload()
  }
}

export module SportsmanGamingGearsRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    processor_id?: string
    graphics_card_id?: string
    memory_id?: string
    hard_drive_id?: string
    case_id?: string
    monitor_id?: string
    chair_id?: string
    headset_id?: string
    keyboard_id?: string
    mouse_id?: string
    mousepad_id?: string
    data_frozen_at?: string
    sportsman_id?: string
  }

  export class Repository extends RecordRepository<SportsmanGamingGearsRecord> {
    /// >>> by sportsman_id >>>
    #getBySportsmanId: RecordRepository.NestingGetFieldFlags = {}
    getBySportsmanId(sportsmanId: string, options?: RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getBySportsmanId, sportsmanId, options, {
        peek: () => this.peekBySportsmanId(sportsmanId),
        load: async () => [await this.loadBySportsmanId(sportsmanId)]
      })
    }

    peekBySportsmanId(sportsmanId:string) {
      return this.peekAll(x => x.sportsman_id == sportsmanId)
    }

    protected loadBySportsmanId(sportsmanId:string) {
      return this.getApi().getOneSportsmanGamingGearsBySportsmanId(sportsmanId)
    }
    /// <<< by sportman_id <<<

    loadAll() {
      return this.getApi().getAllSportsmanGamingGears()
    }

    create(record: Partial<SportsmanGamingGearsRecord<CtorJsonData>>): Promise<SportsmanGamingGearsRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneSportsmanGamignGears(record)
    }

    protected loadById(id: string): Promise<SportsmanGamingGearsRecord<SportsmanGamingGearsRecord.CtorJsonData> | null> {
      return this.getApi().getOneSportsmanGamingGearsById(id)
    }

    protected updateById(id: string, patch: Partial<SportsmanGamingGearsRecord<SportsmanGamingGearsRecord.CtorJsonData>>): Promise<SportsmanGamingGearsRecord<SportsmanGamingGearsRecord.CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneSportsmanGamingGears(id, patch)
    }

    protected deleteById(id: string): Promise<SportsmanGamingGearsRecord<SportsmanGamingGearsRecord.CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneSportsmanGamingGears(id)
    }
    /// >>> not implemented methods >>>
    protected approveById(id: string, patch: Partial<SportsmanGamingGearsRecord<CtorJsonData>>): Promise<SportsmanGamingGearsRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<SportsmanGamingGearsRecord<CtorJsonData>>): Promise<SportsmanGamingGearsRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<SportsmanGamingGearsRecord<CtorJsonData>>): Promise<SportsmanGamingGearsRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<SportsmanGamingGearsRecord<CtorJsonData>>): Promise<SportsmanGamingGearsRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<SportsmanGamingGearsRecord<CtorJsonData>>): Promise<SportsmanGamingGearsRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<SportsmanGamingGearsRecord<CtorJsonData>>): Promise<SportsmanGamingGearsRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    /// <<< not implemented methods <<<
  }
}
