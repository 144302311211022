import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { TournamentStageRecord } from "./TournamentStageRecord";

export class TournamentRoundRecord<TJsonData extends TournamentRoundRecord.CtorJsonData = TournamentRoundRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  name?: string
  order_number?: number
  ident?: string
  data_frozen_at?: Date | null
  data_approved_at?: Date | null
  published_at?: Date | null

  /// >>> tournamentStage >>>
  #tournamentStage:BaseRecord.RefField<TournamentStageRecord> = {}

  tournament_stage_id?: string

  get tournamentStage() {
    return this.#tournamentStage.object
  }

  getTournamentStage() {
    this.#tournamentStage.id = this.tournament_stage_id

    return this.getObjectFromRefField(this.#tournamentStage, "tournamentStages")
  }
  /// <<< tournamentStage <<<

  /// >>> tournament >>>
  get tournament() {
    return this.tournamentStage?.tournament
  }

  async getTournament() {
    return await (await this.getTournamentStage())?.getTournament()
  }
  /// <<< tournament <<<

  /// >>> computed_values >>>
  get isApproved() {
    return !!this.data_approved_at
  }

  get isFrozen() {
    return !!this.data_frozen_at
  }

  get tournament_id() {
    return this.tournament?.id
  }

  get tournamentStage_name() {
    return this.tournamentStage?.name
  }

  get sortValues() {
    return [
      this.tournamentStage?.order_number ?? Number.MAX_SAFE_INTEGER,
      this.order_number ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TournamentRoundRecord.CtorJsonData) {
    return new TournamentRoundRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.tournament_stage_id = jsonData?.tournament_stage_id
    this.name = jsonData?.name
    this.ident = jsonData?.ident
    this.order_number = jsonData?.order_number
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.data_approved_at = DateTime.parseDateOrNull(jsonData?.data_approved_at)
    this.published_at = DateTime.parseDateOrNull(jsonData?.published_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      tournament_stage_id: this.tournament_stage_id,
      name: this.name,
      ident: this.ident,
      order_number: this.order_number,
      data_frozen_at: this.data_frozen_at?.toISOString(),
      data_approved_at: this.data_approved_at?.toISOString(),
      published_at: this.published_at?.toISOString()
    }) as TJsonData
  }

  // #alreadyPreloaded = false

  async preload() {
    // if(this.#alreadyPreloaded) return

    await super.preload()

    // this.#alreadyPreloaded = true

    const stage = await this.getTournamentStage()
    await stage?.preload()
  }
}

export module TournamentRoundRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    tournament_stage_id?: string
    name?: string
    order_number?: number
    ident?: string
    data_frozen_at?: string
    data_approved_at?: string
    published_at?: string
  }

  export class Repository extends RecordRepository<TournamentRoundRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by tournament_stage_id >>>
    #getByTournamentStageId:RecordRepository.NestingGetFieldFlags = {}

    getByTournamentStageId(tournamentStageId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTournamentStageId, tournamentStageId, options, {
        peek: () => this.peekByTournamentStageId(tournamentStageId),
        load: () => this.loadByTournamentStageId(tournamentStageId)
      })
    }

    peekByTournamentStageId(tournamentStageId:string) {
      return this.peekAll(x => x?.tournament_stage_id == tournamentStageId)
    }

    getIdents() {
      return this.getApi().getTournamentRoundIdents()
    }

    create(record: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTournamentRound(record)
    }

    protected loadAll() {
      return this.getApi().getAllTournamentRounds()
    }

    protected loadByTournamentStageId(tournamentStageId:string) {
      return this.getApi().getMoreTournamentRoundsByTournamentStageId(tournamentStageId)
    }
    /// <<< by tournament_stage_id <<<

    protected loadById(id: string) {
      return this.getApi().getOneTournamentRoundById(id)
    }

    protected updateById(id: string, patch: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTournamentRound(id, patch)
    }

    protected deleteById(id: string): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTournamentRound(id)
    }

    protected approveById(id: string, patch: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.approveOneTournamentRoundById(id, patch)
    }

    protected unapproveById(id: string, patch: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unapproveOneTournamentRoundById(id, patch)
    }

    protected publishById(id: string, patch: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.publishOneTournamentRoundById(id, patch)
    }

    protected unpublishById(id: string, patch: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unpublishOneTournamentRoundById(id, patch)
    }

    protected freezeById(id: string, patch: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneTournamentRoundById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<TournamentRoundRecord<CtorJsonData>>): Promise<TournamentRoundRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneTournamentRoundById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTournamentRounds()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTournamentRoundById(id)
    }
  }
}
