import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { SportRecord } from "data/SportRecord"

export class SportsmanRoleRecord<TJsonData extends SportsmanRoleRecord.CtorJsonData = SportsmanRoleRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  ident?: string
  name?: string
  is_in_match?: boolean

  /// >>> sport >>>
  #sport:BaseRecord.RefField<SportRecord> = {}
  get sport_id() {
    return this.#sport.id
  }
  set sport_id(value) {
    this.#sport.id = value
  }

  get sport() {
    return this.#sport?.object
  }
  async getSport() {
    return this.getObjectFromRefField(this.#sport, "sports")
  }
  /// <<< sport <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportsmanRoleRecord.CtorJsonData) {
    return new SportsmanRoleRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.ident = jsonData?.ident
    this.name = jsonData?.name
    this.is_in_match = jsonData?.is_in_match
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      ident: this.ident,
      name: this.name,
      is_in_match: this.is_in_match,
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const sport = await this.getSport()
    await sport?.preload()
  }
}

export module SportsmanRoleRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    ident?: string
    name?: string
    is_in_match?: boolean
  }

  export class Repository extends RecordRepository<SportsmanRoleRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneSportsmanRole(record)
    }

    protected loadAll() {
      return this.getApi().getAllSportsmanRoles()
    }

    protected loadById(id: string): Promise<SportsmanRoleRecord | null> {
      return this.getApi().getOneSportsmanRoleById(id)
    }

    protected updateById(id: string, patch: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneSportsmanRole(id, patch)
    }

    protected deleteById(id: string): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneSportsmanRole(id)
    }

    protected approveById(id: string, patch: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected publishById(id: string, patch: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.")
    }

    protected unpublishById(id: string, patch: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.")
    }

    protected freezeById(id: string, patch: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<SportsmanRoleRecord<CtorJsonData>>): Promise<SportsmanRoleRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllSportsmanRoles()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneSportsmanRoleById(id)
    }
  }
}
