import React, { ComponentProps, useContext, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import FormDropdown from 'components/utils/FormDropdown'
import FormDatePicker from 'components/utils/Form/Picker/DatePicker'
import RecalculateButton from 'components/utils/Button/RecalculateButton'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
import Image from 'components/utils/Image/Image'
// Styles
import { ItemWrapper } from 'styles/common'
// Additional
import { Tab } from 'static/tabs'
import { TournamentRecord } from 'data/TournamentRecord'
import { AppContext } from 'App'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Form as FormTournamentHelper } from 'helpers/formTournament'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'
import ImageGalleryPickerParent from 'components/utils/ImageGalleryPicker/ImageGalleryPickerParent'
import RichTextEditor from 'components/RichTextEditor'

const SubmitKeys = [
  'name',
  'short_name',
  'ident',
  'starts_at',
  'ends_at',
  'tournament_datasource_identifier_id',
  'staging_id',
  'current_round_id',
  'previous_round_id',
  'next_round_id',
  'logo_url',
  'photo_url',
  'location',
  'first_place_rank_picture_url',
  'second_place_rank_picture_url',
  'third_place_rank_picture_url',
  'flag_for_nearest_matches',
  'detail'
] as Array<keyof TournamentRecord>

type ValidationKeys = Record<
  keyof Pick<TournamentRecord, 'name' | 'sport_id' >,
  any
>

const TournamentForm: React.FC<FormHelper.Props<TournamentRecord>> = (p) => {
  const { db, alert, history, modal } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: TournamentRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Tournament',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      sport_id: form.data?.sport_id,
      sport_text: form.data?.sport?.full_name,

      current_round_id: form.data?.current_round?.id,
      current_round_text: form.data?.current_round?.name,

      previous_round_id: form.data?.previous_round?.id,
      previous_round_text: form.data?.previous_round?.name,

      next_round_id: form.data?.next_round?.id,
      next_round_text: form.data?.next_round?.name,
    },

    onSubmit: async (values) => {
      if (!db?.tournaments) return

      try {
        const data = await FormHelper.submitChanges<TournamentRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new TournamentRecord(), values ?? {}),
          repository: db.tournaments,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getTournamentDetailUrl({
              tournament: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      name: Yup.string().nullable().required('Name is a required field'),
      sport_id: Yup.string().required('Sport is a required field'),
      // starts_at: Yup.date()
      //   .max(
      //     Yup.ref('ends_at'),
      //     '"Start" date should be greater than "Finish" date'
      //   )
      //   .nullable(),
      // ends_at: Yup.date()
      //   .min(
      //     Yup.ref('starts_at'),
      //     '"Finish" date should be earlier than "Start" date'
      //   )
      //   .nullable()
    }),
  })

  const approve = async () => {
    if (!db?.tournaments) return

    await FormHelper.approve({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt,
    })
  }

  const unapprove = async () => {
    if (!db?.tournaments) return

    await FormHelper.unapprove({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt,
    })
  }

  const publish = async () => {
    if (!db?.tournaments) return

    await FormHelper.publish({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt
    })
  }

  const unpublish = async () => {
    if (!db?.tournaments) return

    await FormHelper.unpublish({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt
    })
  }

  const freeze = async () => {
    if (!db?.tournaments) return

    await FormHelper.freeze({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.tournaments) return

    await FormHelper.unfreeze({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.tournaments) return

    await FormHelper.delete_({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.tournaments) return

    await FormHelper.touch({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt,
    })
  }

  const recalculate = async () => {
    if (!db?.tournaments) return

    await FormTournamentHelper.recalculate({
      data: Object.assign(new TournamentRecord(), formik.values ?? {}),
      repository: db.tournaments,
      optional: opt,
    })
  }

  const [sports, setSports] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.sport_id ?? '',
      text: formik.values.sport_text,
    },
    loaded: false,
  })

  const currentRoundsDefaultItem: Item = { id: null, text: '&nbsp;' }

  const [currentRounds, setCurrentRounds] = useState<{
    value?: Item
    data?: Array<Item>
  }>({
    value: {
      id: formik.values.current_round_id ?? '',
      text: formik.values.current_round_text,
    },
  })

  const previousRoundsDefaultItem: Item = { id: null, text: '&nbsp;' }

  const [previousRounds, setPreviousRounds] = useState<{
    value?: Item
    data?: Array<Item>
  }>({
    value: {
      id: formik.values.previous_round_id ?? '',
      text: formik.values.previous_round_text,
    },
  })

  const nextRoundsDefaultItem: Item = { id: null, text: '&nbsp;' }

  const [nextRounds, setNextRounds] = useState<{
    value?: Item
    data?: Array<Item>
  }>({
    value: {
      id: formik.values.next_round_id ?? '',
      text: formik.values.next_round_text,
    },
  })

  const _setSports = async () => {
    const _data = await DataHelper.getAllSports(db!)

    setSports((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.full_name } as Item
      }),
    }))
  }

  const _setRounds = async () => {
    if (!formik.values.id) return

    const _data = (
      await DataHelper.getRoundsByTournamentId(db!, formik.values.id)
    )?.map((s) => ({ id: s.id ?? '', text: s.name ?? '' } as Item))

    setCurrentRounds((prev) => ({
      value: prev?.value,
      data: _data,
    }))

    setPreviousRounds((prev) => ({
      value: prev?.value,
      data: _data,
    }))

    setNextRounds((prev) => ({
      value: prev?.value,
      data: _data,
    }))
  }

  const _setSport = (item: Item) => {
    formik.setFieldValue('sport_id' as keyof TournamentRecord, item.id)

    setSports((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const _setCurrentRound = (item: Item) => {
    formik.setFieldValue('current_round_id' as keyof TournamentRecord, item.id)

    setCurrentRounds((prev) => ({ value: item, data: prev.data ?? [] }))
  }

  const _setPreviousRound = (item: Item) => {
    formik.setFieldValue('previous_round_id' as keyof TournamentRecord, item.id)

    setPreviousRounds((prev) => ({ value: item, data: prev.data ?? [] }))
  }

  const _setNextRound = (item: Item) => {
    formik.setFieldValue('next_round_id' as keyof TournamentRecord, item.id)

    setNextRounds((prev) => ({ value: item, data: prev.data ?? [] }))
  }

  const handleSportChange = (item: Item) => {
    _setSport(item)
  }

  const handleCurrentRoundChange = (item: Item) => {
    _setCurrentRound(item)
  }

  const handlePreviousRoundChange = (item: Item) => {
    _setPreviousRound(item)
  }

  const handleNextRoundChange = (item: Item) => {
    _setNextRound(item)
  }

  useEffect(() => {
    _setRounds()
    _setSports()
  }, [])

  //#region Form Components
  const _Sport = (
    <FormItem
      label={<Form.Label>Sport</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'sport_id' as keyof TournamentRecord}
            items={sports.data ?? []}
            onSelect={handleSportChange}
            value={sports.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.sport_id}
            disabled={form.state === 'existing' || p.locked?.value}
            loading={!sports.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.sport_id &&
                  RoutingHelper.getSportDetailUrl({
                    sport: formik.values.sport_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.sport_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _CurrentRound = (
    <FormItem
      label={<Form.Label>Current Round</Form.Label>}
      input={
        <FormDropdown
          name={'current_round' as keyof TournamentRecord}
          defaultItem={currentRoundsDefaultItem}
          items={currentRounds.data ?? []}
          onSelect={handleCurrentRoundChange}
          value={currentRounds.value}
          disabled={p.locked?.value}
          disableId={[
            previousRounds.value?.id ?? '',
            nextRounds.value?.id ?? '',
          ]}
          append={
            <LinkToButton
              path={
                formik.values.current_round_id &&
                RoutingHelper.getRoundDetailUrl({
                  tournament: formik.values.id ?? RoutingHelper.NewRecordSymbol,
                  round: formik.values.current_round_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _PreviousRound = (
    <FormItem
      label={<Form.Label>Previous Round</Form.Label>}
      input={
        <FormDropdown
          name={'previous_round' as keyof TournamentRecord}
          defaultItem={previousRoundsDefaultItem}
          items={previousRounds.data ?? []}
          onSelect={handlePreviousRoundChange}
          value={previousRounds.value}
          disabled={p.locked?.value}
          disableId={[
            currentRounds.value?.id ?? '',
            nextRounds.value?.id ?? '',
          ]}
          append={
            <LinkToButton
              path={
                formik.values.previous_round_id &&
                RoutingHelper.getRoundDetailUrl({
                  tournament: formik.values.id ?? RoutingHelper.NewRecordSymbol,
                  round: formik.values.previous_round_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _NextRound = (
    <FormItem
      label={<Form.Label>Next Round</Form.Label>}
      input={
        <FormDropdown
          name={'next_round' as keyof TournamentRecord}
          defaultItem={nextRoundsDefaultItem}
          items={nextRounds.data ?? []}
          onSelect={handleNextRoundChange}
          value={nextRounds.value}
          disabled={p.locked?.value}
          disableId={[
            currentRounds.value?.id ?? '',
            previousRounds.value?.id ?? '',
          ]}
          append={
            <LinkToButton
              path={
                formik.values.next_round_id &&
                RoutingHelper.getRoundDetailUrl({
                  tournament: formik.values.id ?? RoutingHelper.NewRecordSymbol,
                  round: formik.values.next_round_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _Name = (
    <FormItem
      label={<Form.Label>Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'name' as keyof TournamentRecord}
            value={formik.values.name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _ShortName = (
    <FormItem
      label={<Form.Label>Short Name</Form.Label>}
      input={
        <Form.Control
          name={'short_name' as keyof TournamentRecord}
          value={formik.values.short_name ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Ident = (
    <FormItem
      label={<Form.Label>Ident</Form.Label>}
      input={
        <Form.Control
          name={'ident' as keyof TournamentRecord}
          value={formik.values.ident ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Start = (
    <FormItem
      label={<Form.Label>Start</Form.Label>}
      input={
        <FormDatePicker
          name={'starts_at' as keyof TournamentRecord}
          value={formik.values.starts_at ?? undefined}
          onChangeCallback={(date: Date | null) => {
            formik.setFieldValue('starts_at' as keyof TournamentRecord, date)
          }}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Finish = (
    <FormItem
      label={<Form.Label>Finish</Form.Label>}
      input={
        <FormDatePicker
          name={'ends_at' as keyof TournamentRecord}
          value={formik.values.ends_at ?? undefined}
          onChangeCallback={(date: Date | null) => {
            formik.setFieldValue('ends_at' as keyof TournamentRecord, date)
          }}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Location = (
    <FormItem
      label={<Form.Label>Location</Form.Label>}
      input={
        <Form.Control
          name={'location' as keyof TournamentRecord}
          value={formik.values.location ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Logo = (
    <Image
      inputName={'logo_url' as keyof TournamentRecord}
      imageUrl={formik.values.logo_url ?? ''}
      isLocked={p.locked?.value}
      labelName='Logo url'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'tournaments'}
                actualImageString={formik.values.logo_url ?? ''}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'logo_url' as keyof TournamentRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('logo_url' as keyof TournamentRecord, val)
      }
    />
  )

  const _Photo = (
    <Image
      inputName={'photo_url' as keyof TournamentRecord}
      imageUrl={formik.values.photo_url ?? ''}
      isLocked={p.locked?.value}
      labelName='Photo url'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'tournaments'}
                actualImageString={formik.values.photo_url ?? ''}
                // useMultipleSelect={{
                //   divider: '|',
                // }}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'photo_url' as keyof TournamentRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('photo_url' as keyof TournamentRecord, val)
      }
    />
  )

  const _TextSummaryEditor = (
    <FormItem
      label={<Form.Label>Text-Summary</Form.Label>}
      input={
        <>
          <ItemWrapper />
          <RichTextEditor
            text={formik.values.detail ?? ''}
            readonly={p.locked?.value}
            onChange={(text?: string) => {
              formik.setFieldValue(
                'detail' as keyof TournamentRecord,
                text
              )
            }}
          />
        </>
      }
    />
  )

  const _FirstPlaceRankPicture = (
    <Image
      inputName={'first_place_rank_picture_url' as keyof TournamentRecord}
      imageUrl={formik.values.first_place_rank_picture_url ?? ''}
      isLocked={p.locked?.value}
      labelName='First place rank'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'tournaments'}
                actualImageString={formik.values.first_place_rank_picture_url ?? ''}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'first_place_rank_picture_url' as keyof TournamentRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('first_place_rank_picture_url' as keyof TournamentRecord, val)
      }
    />
  )

  const _SecondPlaceRankPicture = (
    <Image
      inputName={'second_place_rank_picture_url' as keyof TournamentRecord}
      imageUrl={formik.values.second_place_rank_picture_url ?? ''}
      isLocked={p.locked?.value}
      labelName='Second place rank'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'tournaments'}
                actualImageString={formik.values.second_place_rank_picture_url ?? ''}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'second_place_rank_picture_url' as keyof TournamentRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('second_place_rank_picture_url' as keyof TournamentRecord, val)
      }
    />
  )

  const _ThirdPlaceRankPicture = (
    <Image
      inputName={'third_place_rank_picture_url' as keyof TournamentRecord}
      imageUrl={formik.values.third_place_rank_picture_url ?? ''}
      isLocked={p.locked?.value}
      labelName='Third place rank'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'tournaments'}
                actualImageString={formik.values.third_place_rank_picture_url ?? ''}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'third_place_rank_picture_url' as keyof TournamentRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('third_place_rank_picture_url' as keyof TournamentRecord, val)
      }
    />
  )

  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useApprove: {
        defaultValue: !!p.data?.data_approved_at,
      },
      usePublish: {
        defaultValue: !!p.data?.published_at,
      },
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: false,
      insertElements: [
        <RecalculateButton
          key={'RecalculateButton'}
          recalculateFn={recalculate}
          from={Tab.Tournaments}
        />,
      ],
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return {
      useSave: {},
      useDelete: { desktopSize: 2 },
      locked: p.locked?.value,
    }
  }

  return (
    <FormContext.Provider
      value={{
        submit: formik.submitForm,
        delete: delete_,
        approve: approve,
        unapprove: unapprove,
        publish: publish,
        unpublish: unpublish,
        freeze: freeze,
        unfreeze: unfreeze,
      }}
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Header {..._ComputeHeaderProps()} />

        <Form.Row>
          <Col lg={4} as={ItemWrapper}>
            {_Sport}
          </Col>
        </Form.Row>

        <ItemWrapper />

        <Form.Row>
          <Col lg={{ span: 6 }} as={ItemWrapper}>
            {_Name}
          </Col>
          <Col lg={{ span: 6 }} as={ItemWrapper}>
            {_ShortName}
          </Col>
        </Form.Row>

        <ItemWrapper />

        {form.state === 'existing' && (
          <>
            <Form.Row>
              <Col lg={4} as={ItemWrapper}>
                {_CurrentRound}
              </Col>
              <Col lg={4} as={ItemWrapper}>
                {_PreviousRound}
              </Col>
              <Col lg={4} as={ItemWrapper}>
                {_NextRound}
              </Col>
            </Form.Row>

            <ItemWrapper />
          </>
        )}

        <Form.Row>
          <Col lg={8} as={ItemWrapper}>
            <Form.Row>
              <Col as={ItemWrapper}>{_Logo}</Col>
            </Form.Row>
            <Form.Row>
              <Col as={ItemWrapper}>{_Photo}</Col>
            </Form.Row>
          </Col>
          <Col lg={4}>
            <Form.Row>
              <Col as={ItemWrapper}>{_Ident}</Col>
            </Form.Row>
            <Form.Row>
              <Col as={ItemWrapper}>{_Location}</Col>
            </Form.Row>
            <Form.Row>
              <Col as={ItemWrapper}>{_Start}</Col>
            </Form.Row>
            <Form.Row>
              <Col as={ItemWrapper}>{_Finish}</Col>
            </Form.Row>
          </Col>
        </Form.Row>
        <Form.Row>
              <Col as={ItemWrapper}>{_TextSummaryEditor}</Col>
            </Form.Row>

        <ItemWrapper />

        <Form.Row>
          <Col lg={4} as={ItemWrapper}>{_FirstPlaceRankPicture}</Col>
          <Col lg={4} as={ItemWrapper}>{_SecondPlaceRankPicture}</Col>
          <Col lg={4} as={ItemWrapper}>{_ThirdPlaceRankPicture}</Col>
        </Form.Row>

        <Footer {..._ComputeFooterProps()} />
      </Form>
    </FormContext.Provider>
  )
}

export default TournamentForm
