import { type } from "os";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class TournamentStageDatasourceIdentifierRecord<TJsonData extends TournamentStageDatasourceIdentifierRecord.CtorJsonData = TournamentStageDatasourceIdentifierRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  tournament_stage_id?: string
  identifier?: string
  identifier_kind?: string
  is_enabled?: boolean | null
  admin_note?: string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TournamentStageDatasourceIdentifierRecord.CtorJsonData) {
    return new TournamentStageDatasourceIdentifierRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if(jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.tournament_stage_id = jsonData?.tournament_stage_id
    this.identifier = jsonData?.identifier
    this.identifier_kind = jsonData?.identifier_kind
    this.is_enabled = jsonData?.is_enabled
    this.admin_note = jsonData?.admin_note
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      tournament_stage_id: this.tournament_stage_id,
      identifier: this.identifier,
      identifier_kind: this.identifier_kind,
      is_enabled: this.is_enabled,
      admin_note: this.admin_note
    }) as TJsonData
  }
}

export module TournamentStageDatasourceIdentifierRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    tournament_stage_id?: string
    identifier?: string
    identifier_kind?: string
    is_enabled?: boolean | undefined
    priority?: number
    admin_note?: string
  }

  export class Repository extends RecordRepository<TournamentStageDatasourceIdentifierRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by tournament_stage_id >>>
    #getByTournamentStageId:RecordRepository.NestingGetFieldFlags = {}
    getByTournamentStageId(tournamentStageId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTournamentStageId, tournamentStageId, options, {
        peek: () => this.peekByTournamentStageId(tournamentStageId),
        load: () => this.loadByTournamentStageId(tournamentStageId)
      })
    }

    peekByTournamentStageId(tournamentStageId:string) {
      return this.peekAll(x => x?.tournament_stage_id == tournamentStageId)
    }

    protected loadByTournamentStageId(tournamentStageId:string) {
      return this.getApi().getMoreTournamentStageDatasourceIdentifiersByTournamentStageId(tournamentStageId)
    }

    /// <<< by tournament_stage_id <<<

    create(record: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTournamentStageDatasourceIdentifier(record)
    }

    protected loadAll() {
      return this.getApi().getAllTournamenStagetDatasourceIdentifiers()
    }

    protected loadById(id: string): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      return this.getApi().getOneTournamentStageDatasourceIdentifierById(id)
    }
    protected updateById(id: string, patch: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTournamentStageDatasourceIdentifier(id, patch)
    }
    protected deleteById(id: string): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTournamentStageDatasourceIdentifier(id)
    }
    protected approveById(id: string, patch: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<TournamentStageDatasourceIdentifierRecord<CtorJsonData>>): Promise<TournamentStageDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }

  }
}
