import { AnyRecord } from "types/anyRecord";
import { DateTime } from "../utils/dateTime"
import { BaseRecord } from "./BaseRecord"
import { GameMapRecord } from "./GameMapRecord"
import { MatchRecord } from "./MatchRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"

export class MatchMapRecord<TJsonData extends MatchMapRecord.CtorJsonData = MatchMapRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  team1_rounds_won_sum?: number
  team1_rounds_won_sum_orig?: number
  team2_rounds_won_sum?: number
  team2_rounds_won_sum_orig?: number
  order_number?: number
  data_frozen_at?: Date | null
  patch?: AnyRecord

  private writePatch(key:string | number, value:unknown) {
    if (this.patch == null) {
      this.patch = {}
    }

    value = value !== '' ? value : null

    this.patch[key] = value
  }

  get team1_rounds_won_sum_edit() {
    return this.patch?.["team1_rounds_won_sum"] as any
  }

  set team1_rounds_won_sum_edit(value) {
    this.writePatch("team1_rounds_won_sum", value)
  }

  get team2_rounds_won_sum_edit() {
    return this.patch?.["team2_rounds_won_sum"] as any
  }

  set team2_rounds_won_sum_edit(value) {
    this.writePatch("team2_rounds_won_sum", value)
  }

  /// >>> match >>>
  #match:BaseRecord.RefField<MatchRecord> = {}

  match_id?: string

  get match() {
    return this.#match?.object
  }
  getMatch() {
    this.#match.id = this.match_id

    return this.getObjectFromRefField(this.#match, "matches")
  }
  /// <<< match <<<

  /// >>> game map >>>
  #gameMap:BaseRecord.RefField<GameMapRecord> = {}

  game_map_id?: string

  get gameMap() {
    return this.#gameMap?.object
  }
  async getGameMap() {
    this.#gameMap.id = this.game_map_id

    return this.getObjectFromRefField(this.#gameMap, "gameMaps")
  }
  /// <<< game map <<<

  /// >>> computed values >>>
  get game_map_name() {
    return this.gameMap?.name ?? ''
  }

  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sortValues() {
    return [
      this.order_number ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  get team1_rounds_won_sum_table() {
    return this.team1_rounds_won_sum_edit ?? this.team1_rounds_won_sum_orig
  }
  get team2_rounds_won_sum_table() {
    return this.team2_rounds_won_sum_edit ?? this.team2_rounds_won_sum_orig
  }
  /// <<< computed values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:MatchMapRecord.CtorJsonData) {
    return new MatchMapRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.match_id = jsonData?.match_id
    this.game_map_id = jsonData?.game_map_id
    this.team1_rounds_won_sum_orig = jsonData?.team1_rounds_won_sum_orig
    this.team2_rounds_won_sum_orig = jsonData?.team2_rounds_won_sum_orig
    this.order_number = jsonData?.order_number
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.patch = jsonData?.patch
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      match_id: this.match_id,
      game_map_id: this.game_map_id,
      team1_rounds_won_sum_orig: this.team1_rounds_won_sum_orig,
      team2_rounds_won_sum_orig: this.team2_rounds_won_sum_orig,
      order_number: this.order_number,
      data_frozen_at: this.data_frozen_at?.toISOString(),
      patch: this.patch
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const match = await this.getMatch()
    await match?.preload()

    const gameMaps = await this.getGameMap()
    await gameMaps?.preload()
  }
}

export module MatchMapRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    match_id?: string
    game_map_id?: string
    team1_rounds_won_sum_orig?: number
    team2_rounds_won_sum_orig?: number
    order_number?: number
    data_frozen_at?: string
    patch?: AnyRecord
  }

  export class Repository extends RecordRepository<MatchMapRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by match_id >>>
    #getByMatchId:RecordRepository.NestingGetFieldFlags = {}

    getByMatchId(matchId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByMatchId, matchId, options, {
        peek: () => this.peekByMatchId(matchId),
        load: () => this.loadByMatchId(matchId)
      })
    }

    peekByMatchId(matchId:string) {
      return this.peekAll(x => x?.match_id == matchId)
    }

    protected loadByMatchId(matchId:string) {
      return this.getApi().getMoreMatchMapsByMatchId(matchId)
    }
    /// <<< by match_id <<<

    create(record: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneMatchMap(record)
    }

    protected loadAll() {
      return this.getApi().getAllMatchMaps()
    }

    protected loadById(id: string): Promise<MatchMapRecord | null> {
      return this.getApi().getOneMatchMapById(id)
    }

    protected updateById(id: string, patch: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneMatchMap(id, patch)
    }

    protected deleteById(id: string): Promise<MatchMapRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneMatchMap(id)
    }

    protected approveById(id: string, patch: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected publishById(id: string, patch: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }

    protected unpublishById(id: string, patch: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }

    protected freezeById(id: string, patch: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneMatchMapById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<MatchMapRecord<CtorJsonData>>): Promise<MatchMapRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneMatchMapById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllMatchMaps()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneMatchMapById(id)
    }
  }
}
