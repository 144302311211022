import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import UIColumnFilter from 'components/utils/Filter/ColumnFilter'
// Styles
import {
  LockIcon,
  CheckIcon,
  EllipsisHIcon,
  StarIcon,
} from 'styles/fontAwesome'
// Additional
import { Tab } from 'static/tabs'
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { TeamRecord } from 'data/TeamRecord'
import {
  RecordSetting,
  RecordsSetting,
} from 'components/utils/Table/_Table.settings'

type CoreColumns = 'sport_id' | 'id'

export type AvailableColumns =
  | 'full_name'
  | 'short_name'
  | 'is_popular'
  | 'isApproved'
  | 'isFrozen'
  | 'ctaButton'

export const Settings = (
  columns: Array<AvailableColumns>,
  options?: {
    disableNew?: boolean
  }
): RecordsSetting<TeamRecord> => {
  const Columns: {
    [K in CoreColumns | AvailableColumns]: RecordSetting<TeamRecord>
  } = {
    sport_id: {
      accessor: 'sport_id',
      className: 'table-hidden',
      Filter: function FilterContent(p: any) {
        return <UIColumnFilter from={Tab.Teams} filter={p} />
      },
      filter: 'fuzzyText',
    },
    id: {
      accessor: 'id',
      className: 'table-hidden',
    },
    full_name: {
      accessor: 'full_name',
      Header: 'Full Name',
    },
    short_name: {
      accessor: 'short_name',
      Header: 'Short Name',
    },
    isApproved: {
      accessor: 'isApproved',
      Header: 'Approved',
      className: 'table-approved',
      Cell: function CellContent({ row }: any) {
        return <>{row.values.isApproved && <CheckIcon />}</>
      },
    },
    isFrozen: {
      accessor: 'isFrozen',
      className: 'table-lock',
      Header: function HeaderContent({ row }: any) {
        return <LockIcon />
      },
      Cell: function CellContent({ row }: any) {
        return <>{row.values.isFrozen && <LockIcon />}</>
      },
    },
    is_popular: {
      accessor: 'is_popular',
      Header: <StarIcon />,
      Cell: function CellContent({ row }: any) {
        return <>{row.values.is_popular && <StarIcon color='goldenrod' />}</>
      },
      Filter: function FilterContent(p: any) {
        return <UIColumnFilter from={Tab.Teams} filter={p} />
      },
    },
    ctaButton: {
      // @ts-ignore
      id: 'ctaButton',
      sticky: 'right',
      className: 'table-cta-button',
      // @ts-ignore
      Header: function HeaderContent({ row }: any) {
        const { toggle } = useContext(AccordionContext)

        if (options?.disableNew) return <></>

        return (
          <CTAButton onClick={toggle}>
            <EllipsisHIcon />
          </CTAButton>
        )
      },
      Cell: function CellContent({ row }: any) {
        return (
          <CTAButton
            path={Routing.getTeamDetailUrl({
              sport: row.values.sport_id ?? Routing.NewRecordSymbol,
              team: row.values.id,
            })}
          >
            {row.values.isFrozen ? 'View' : 'Edit'}
          </CTAButton>
        )
      },
    },
  }

  return [Columns['sport_id'], Columns['id'], ...columns.map((x) => Columns[x])]
}
