import React, { ComponentProps, useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import Image from 'components/utils/Image/Image'
import Footer from 'components/utils/Form/Footer'
import FormCheck from 'components/utils/FormCheck'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { NearestMatchRecord } from "data/NearesMatchRecord";
import { AppContext } from 'App'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Item } from 'components/utils/Dropdown'
import { Data as DataHelper } from 'helpers/data'
import FormDropdown from 'components/utils/FormDropdown'
import LinkToButton from 'components/utils/Button/LinkToButton'
import FormDateTimePicker from 'components/utils/Form/Picker/DateTimePicker'
import ImageGalleryPickerParent from 'components/utils/ImageGalleryPicker/ImageGalleryPickerParent'

const SubmitKeys = [
  'match_id',
  'order_number',
  'box',
  'team1_id',
  'team1_name',
  'team1_logo_url',
  'team1_score',
  'team2_id',
  'team2_name',
  'team2_logo_url',
  'team2_score',
  'tournament_id',
  'tournament_name',
  'tournament_logo_url',
  'sport_ident',
  'highlight_match',
  'starts_at',
  'started_at',
  'finished_at',
  'canceled_at',
  'data_frozen_at',
  'flag_for_nearest_matches',
] as Array<keyof NearestMatchRecord>

const NearestMatchForm: React.FC<FormHelper.Props<NearestMatchRecord>> = (p) => {
  const { db, alert, history, modal } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: NearestMatchRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Nearest Match'
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback
    }
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,
    },

    onSubmit: async (values) => {
      if (!db?.nearestMatches) return

      //fix order number, when its empty
      if (!values.order_number)
        values.order_number= 0

      try {
        const data = await FormHelper.submitChanges<NearestMatchRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new NearestMatchRecord(), values ?? {}),
          repository: db.nearestMatches,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getNearestMatchDetailUrl({
              nearestMatch: data.id
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    }
  })

  const delete_ = async () => {
    if (!db?.nearestMatches) return

    await FormHelper.delete_({
      data: Object.assign(new NearestMatchRecord(), formik.values ?? {}),
      repository: db.nearestMatches,
      optional: opt,
    })
  }

  const matchDefaultItem: Item = { id: null, text: '&nbsp;' }

  // const [matches, setMatches] = useState<{
  //   value?: Item
  //   data?: Array<Item>
  //   loaded: boolean
  // }>({
  //   value: {
  //     id: formik.values.match_id ?? '',
  //     text: formik.values.match_id ?? '',
  //   },
  //   loaded: false
  // })

  const team1DefaultItem: Item = { id: null, text: '&nbsp;' }

  const [teams1, setTeams1] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team1_id ?? '',
      text: formik.values.team1_name,
    },
    loaded: false,
  })

  const team2DefaultItem: Item = { id: null, text: '&nbsp;' }

  const [teams2, setTeams2] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team2_id ?? '',
      text: formik.values.team2_name,
    },
    loaded: false,
  })

  const tournamentDefaultItem: Item = { id: null, text: '&nbsp;' }

  const [tournaments, setTournaments] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.tournament_id ?? '',
      text: formik.values.tournament_name,
    },
    loaded: false
  })


  // const _setMatches = async () => {
  //   const _data = await DataHelper.getAllMatches(db!)

  //   setMatches((prev) => ({
  //     loaded: true,
  //     value: prev?.value,
  //     data: _data?.map((m) => {
  //       return {
  //         id: m.id ?? '',
  //         text: `${m.id}-${m.team1_name} vs ${m.team2_name}`
  //       } as Item
  //     }),
  //   }))
  // }

  const _setTeams = async () => {
    const _data = await DataHelper.getAllTeams(db!)

    const _mappedData = _data?.map((s) => {
      return { id: s.id ?? '', text: `[${s.id}] ${s.full_name}` } as Item
    })

    setTeams1((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _mappedData,
    }))

    setTeams2((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _mappedData,
    }))
  }

  const _setTournaments = async () => {
    const _data = await DataHelper.getAllTournaments(db!)

    setTournaments((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((t) => {
        return {
          id: t.id ?? '',
          text: t.name
        } as Item
      }),
    }))
  }


  // const _setMatch = (item: Item) => {
  //   formik.setFieldValue('match_id' as keyof NearestMatchRecord, item.id)

  //   setMatches((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  // }

  const _setTeam1 = (item: Item) => {
    formik.setFieldValue('team1_id' as keyof NearestMatchRecord, item.id)
    formik.setFieldValue('team1_name' as keyof NearestMatchRecord, item.text)

    setTeams1((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const _setTeam2 = (item: Item) => {
    formik.setFieldValue('team2_id' as keyof NearestMatchRecord, item.id)
    formik.setFieldValue('team2_name' as keyof NearestMatchRecord, item.text)

    setTeams2((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const _setTournament = (item: Item) => {
    formik.setFieldValue('tournament_id' as keyof NearestMatchRecord, item.id)
    formik.setFieldValue('tournament_name' as keyof NearestMatchRecord, item.text)

    setTournaments((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleMatchChange = (item: Item) => {
    // _setMatch(item)

    //_fillFormFromMatch()

    //TODO fill rest fields a lock them
  }

  const handleTeam1Change = (item: Item) => {
    _setTeam1(item)
  }

  const handleTeam2Change = (item: Item) => {
    _setTeam2(item)
  }

  const handleTournamentChange = (item: Item) => {
    _setTournament(item)
  }

  // const _fillFormFromMatch = async () => {
  //   const id = formik.values.match_id
  //   const _data = await DataHelper.getMatchById(
  //      db!,
  //      id!
  //   )
  //   formik.setFieldValue('team_1_name' as keyof NearestMatchRecord, _data?.team1_name)
  //   formik.setFieldValue('team_2_name' as keyof NearestMatchRecord, _data?.team2_name)

  // }

  useEffect(() => {
    // _setMatches()
    _setTeams()
    _setTournaments()
  }, [])

  //#region Form Components

  // const _Match = (
  //   <FormItem
  //     label={<Form.Label>Existing match</Form.Label>}
  //     input={
  //       <>
  //         <FormDropdown
  //           name={'match_id' as keyof NearestMatchRecord}
  //           defaultItem={matchDefaultItem}
  //           items={matches.data ?? []}
  //           onSelect={handleMatchChange}
  //           value={matches.value}
  //           isInvalid={formik.submitCount > 0 && !!formik.errors.match_id}
  //           loading={!matches.loaded}
  //         />
  //         <Form.Control.Feedback type={'invalid'}>
  //           {formik.errors.match_id}
  //         </Form.Control.Feedback>
  //       </>
  //     }
  //   />
  // )

  const _IsHighlight = (
    <FormCheck
      name={'highlight_match' as keyof NearestMatchRecord}
      value={formik.values.highlight_match ?? false}
      checked={formik.values.highlight_match ?? false}
      offset={{ direction: 'left' }}
      title={'Highlight'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue('highlight_match' as keyof NearestMatchRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('highlight_match' as keyof NearestMatchRecord, false)
      }}
    />
  )

  const _IsNearestMatch = (
    <FormCheck
      name={'flag_for_nearest_matches' as keyof NearestMatchRecord}
      value={formik.values.flag_for_nearest_matches ?? false}
      checked={formik.values.flag_for_nearest_matches ?? false}
      offset={{ direction: 'left' }}
      title={'Highlight'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue('flag_for_nearest_matches' as keyof NearestMatchRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('flag_for_nearest_matches' as keyof NearestMatchRecord, false)
      }}
    />
  )

  // const _OrderNumber = (
  //   <FormItem
  //     label={<Form.Label>Order number</Form.Label>}
  //     input={
  //       <Form.Control
  //         name={'order_number' as keyof NearestMatchRecord}
  //         value={formik.values.order_number ?? ''}
  //         onChange={formik.handleChange}
  //         readOnly={p.locked?.value}
  //       />
  //     }
  //   />
  // )

  const _Team1 = (
    <FormItem
      label={<Form.Label>Existing Team 1</Form.Label>}
      input={
        <FormDropdown
          name={'team1_id' as keyof NearestMatchRecord}
          defaultItem={team1DefaultItem}
          items={teams1.data ?? []}
          onSelect={handleTeam1Change}
          value={teams1.value}
          isInvalid={formik.submitCount > 0 && !!formik.errors.team1_id}
          disabled={p.locked?.value}
          disableId={[teams2.value?.id ?? '']}
          loading={!teams1.loaded}
          append={
            <LinkToButton
              path={
                formik.values.team1_id &&
                RoutingHelper.getTeamDetailUrl({
                  sport: RoutingHelper.NewRecordSymbol,
                  team: formik.values.team1_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _Team1Name = (
    <FormItem
      label={<Form.Label>Team1 Name</Form.Label>}
      input={
        <Form.Control
          name={'team1_name' as keyof NearestMatchRecord}
          value={formik.values.team1_name ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Team1Score = (
    <FormItem
      label={<Form.Label>Team1 score</Form.Label>}
      input={
        <Form.Control
          name={'team1_score' as keyof NearestMatchRecord}
          value={formik.values.team1_score ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Team2 = (
    <FormItem
      label={<Form.Label>Existing Team 2</Form.Label>}
      input={
        <FormDropdown
          name={'team2_id' as keyof NearestMatchRecord}
          defaultItem={team2DefaultItem}
          items={teams2.data ?? []}
          onSelect={handleTeam2Change}
          value={teams2.value}
          isInvalid={formik.submitCount > 0 && !!formik.errors.team2_id}
          disabled={p.locked?.value}
          disableId={[teams2.value?.id ?? '']}
          loading={!teams2.loaded}
          append={
            <LinkToButton
              path={
                formik.values.team2_id &&
                RoutingHelper.getTeamDetailUrl({
                  sport: RoutingHelper.NewRecordSymbol,
                  team: formik.values.team2_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _Team2Name = (
    <FormItem
      label={<Form.Label>Team2 Name</Form.Label>}
      input={
        <Form.Control
          name={'team2_name' as keyof NearestMatchRecord}
          value={formik.values.team2_name ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )


  const _Team2Score = (
    <FormItem
      label={<Form.Label>Team2 score</Form.Label>}
      input={
        <Form.Control
          name={'team2_score' as keyof NearestMatchRecord}
          value={formik.values.team2_score ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Tournament = (
    <FormItem
      label={<Form.Label>Existing Tournament</Form.Label>}
      input={
        <FormDropdown
          name={'tournament_id' as keyof NearestMatchRecord}
          defaultItem={tournamentDefaultItem}
          items={tournaments.data ?? []}
          onSelect={handleTournamentChange}
          value={tournaments.value}
          isInvalid={formik.submitCount > 0 && !!formik.errors.tournament_id}
          disabled={p.locked?.value}
          disableId={[tournaments.value?.id ?? '']}
          loading={!tournaments.loaded}
          append={
            <LinkToButton
              path={
                formik.values.tournament_id &&
                RoutingHelper.getTeamDetailUrl({
                  sport: RoutingHelper.NewRecordSymbol,
                  team: formik.values.tournament_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _TournamentName = (
    <FormItem
      label={<Form.Label>Tournament</Form.Label>}
      input={
        <Form.Control
          name={'tournament_name' as keyof NearestMatchRecord}
          value={formik.values.tournament_name ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _TournamentLogo = (
    <Image
      inputName={'tournament_logo_url' as keyof NearestMatchRecord}
      imageUrl={formik.values.tournament_logo_url ?? ''}
      isLocked={p.locked?.value}
      labelName='Tournament Logo'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'tournaments'}
                actualImageString={formik.values.tournament_logo_url ?? ''}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'tournament_logo_url' as keyof NearestMatchRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('tournament_logo_url' as keyof NearestMatchRecord, val)
      }
    />
  )

  const _SportIdent = (
    <FormItem
      label={<Form.Label>Sport ident</Form.Label>}
      input={
        <Form.Control
          name={'sport_ident' as keyof NearestMatchRecord}
          value={formik.values.sport_ident ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )


  const _StartsAt = (
    <FormItem
      label={<Form.Label>Starts At</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'starts_at' as keyof NearestMatchRecord}
            value={formik.values.starts_at ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue('starts_at' as keyof NearestMatchRecord, date)
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _StartedAt = (
    <FormItem
      label={<Form.Label>Started At</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'started_at' as keyof NearestMatchRecord}
            value={formik.values.started_at ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue('started_at' as keyof NearestMatchRecord, date)
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _FinishedAt = (
    <FormItem
      label={<Form.Label>Finished At</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'finished_at' as keyof NearestMatchRecord}
            value={formik.values.finished_at ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue(
                'finished_at' as keyof NearestMatchRecord,
                date
              )
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _CanceledAt = (
    <FormItem
      label={<Form.Label>Canceled At</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'canceled_at' as keyof NearestMatchRecord}
            value={formik.values.canceled_at ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue(
                'canceled_at' as keyof NearestMatchRecord,
                date
              )
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Box = (
    <FormItem
      label={<Form.Label>BOX</Form.Label>}
      input={
        <Form.Control
          name={'box' as keyof NearestMatchRecord}
          value={formik.values.box ?? ''}
          type={'number'}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )


  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return {
      useSave: {},
      useDelete: { desktopSize: 2 },
      locked: p.locked?.value,
    }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>

          <ItemWrapper>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <Form.Row>
                  <Col as={ItemWrapper}>{_Tournament}</Col>
                </Form.Row>
                <Form.Row>
                  <Col>{_TournamentName}</Col>
                </Form.Row>
                <Form.Row>
                  <Col>{_TournamentLogo}</Col>
                </Form.Row>
              </Col>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <Form.Row>
                  <Col as={ItemWrapper}>{_IsHighlight}</Col>
                  <Col as={ItemWrapper}>{_IsNearestMatch}</Col>
                </Form.Row>
                <Form.Row>
                  <Col as={ItemWrapper}>{_SportIdent}</Col>
                </Form.Row>
                <Form.Row>
                  <Col as={ItemWrapper}>{_Box}</Col>
                </Form.Row>
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team1}
              </Col>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team2}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team1Name}
              </Col>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team2Name}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team1Score}
              </Col>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team2Score}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_StartsAt}
              </Col>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_FinishedAt}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_StartedAt}
              </Col>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_CanceledAt}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )

}

export default NearestMatchForm
