import React from 'react'
// Styles
import { LockIcon } from 'styles/fontAwesome'
// Additional
import { TeamStatSummaryRecord } from 'data/TeamStatSummaryRecord'
import { RecordsSetting } from 'components/utils/Table/_Table.settings'

const Settings: RecordsSetting<TeamStatSummaryRecord> = [
  {
    accessor: 'id',
    className: 'table-hidden',
  },
  {
    accessor: 'isFrozen',
    className: 'table-lock',
    Header: function HeaderContent({ row }: any) {
      return <LockIcon />
    },
    Cell: function CellContent({ row }: any) {
      return <>{row.values.isFrozen && <LockIcon />}</>
    },
  },
  {
    accessor: 'team_name',
    Header: 'Team',
    className: 'table-stick-left',
    sticky: 'left'
  },
  {
    accessor: 'group_size',
    Header: 'Group size',
    className: 'table-group-size',
  },
  // >>> SF points >>>
  // {
  //   accessor: 'fantasy_points_sum',
  //   Header: 'Fantasy Points Sum',
  // },
  // {
  //   accessor: 'fantasy_points_per_match_avg',
  //   Header: 'Fantasy Points Per Match Avg',
  // },
  // {
  //   accessor: 'fantasy_points_per_map_avg',
  //   Header: 'Fantasy Points Per Map Avg',
  // },
  // {
  //   accessor: 'fantasy_points_per_round_avg',
  //   Header: 'Fantasy Points Per Round Avg',
  // },
  // {
  //   accessor: 'fantasy_points_rank_from',
  //   Header: 'Fantasy Points Per Rank From',
  // },
  // {
  //   accessor: 'fantasy_points_rank_to',
  //   Header: 'Fantasy Points Per Rank To',
  // },
  // <<< SF points <<<
  // >>> match summary >>>
  {
    accessor: 'matches_played_sum',
    Header: 'Matches Played Sum',
  },
  {
    accessor: 'matches_won_sum',
    Header: 'Matches Won Sum',
  },
  {
    accessor: 'matches_lost_sum',
    Header: 'Matches Lost Sum',
  },
  {
    accessor: 'matches_tied_sum',
    Header: 'Matches Tied Sum',
  },
  {
    accessor: 'matches_won_ratio',
    Header: 'Matches Won Ratio',
  },
  // <<< match summary <<<
  // >>> map summary >>>
  {
    accessor: 'maps_played_sum',
    Header: 'Maps Played Sum',
  },
  {
    accessor: 'maps_won_sum',
    Header: 'Maps Won Sum',
  },
  {
    accessor: 'maps_lost_sum',
    Header: 'Maps Lost Sum',
  },
  {
    accessor: 'maps_tied_sum',
    Header: 'Maps Tied Sum',
  },
  // <<< map summary <<<
  // >>> round summary >>>
  {
    accessor: 'rounds_played_sum',
    Header: 'Rounds Played Sum',
  },
  {
    accessor: 'rounds_played_ct_sum',
    Header: 'Rounds Played CT Sum',
  },
  {
    accessor: 'rounds_played_t_sum',
    Header: 'Rounds Played T Sum',
  },
  {
    accessor: 'rounds_won_sum',
    Header: 'Rounds Won Sum',
  },
  {
    accessor: 'rounds_won_ct_sum',
    Header: 'Rounds Won CT Sum',
  },
  {
    accessor: 'rounds_won_t_sum',
    Header: 'Rounds Won T Sum',
  },
  {
    accessor: 'rounds_lost_sum',
    Header: 'Rounds Lost Sum',
  },
  {
    accessor: 'rounds_lost_ct_sum',
    Header: 'Rounds Lost CT Sum',
  },
  {
    accessor: 'rounds_lost_t_sum',
    Header: 'Rounds Lost T Sum',
  },
  {
    accessor: 'rounds_won_ratio',
    Header: 'Rounds Won Ratio',
  },
  {
    accessor: 'rounds_won_t_ratio',
    Header: 'Rounds Won T Ratio',
  },
  {
    accessor: 'rounds_won_ct_ratio',
    Header: 'Rounds Won CT Ratio',
  },
  // <<< round summary <<<
  // >>> kill summary >>>
  {
    accessor: 'kills_sum',
    Header: 'Kill Sum',
  },
  {
    accessor: 'teamkills_sum',
    Header: 'Teamkills Sum',
  },
  {
    accessor: 'deaths_sum',
    Header: 'Deaths Sum',
  },
  {
    accessor: 'suicides_sum',
    Header: 'Suicides Sum',
  },
  // <<< kill summary <<<
  // >>> bombs >>>
  {
    accessor: 'opponent_bomb_plants_sum',
    Header: 'Opponent Bomb Plants Sum',
  },
  {
    accessor: 'bomb_defuses_sum',
    Header: 'Bomb Defuses Sum',
  },
  {
    accessor: 'bomb_plants_sum',
    Header: 'Bomb Plants Sum',
  },
  {
    accessor: 'bomb_explodes_sum',
    Header: 'Bomb Explodes Sum',
  },
  {
    accessor: 'bomb_plants_ratio_per_round',
    Header: 'Bomb Plants Ratio Per Round',
  },
  {
    accessor: 'bomb_defuses_ratio_per_round',
    Header: 'Bomb Defuses Ratio Per Round',
  },
  {
    accessor: 'bomb_explodes_ratio_per_round',
    Header: 'Bomb Explodes Ratio Per Round',
  },
  // <<< bombs <<<
  // >>> league points >>>
  {
    accessor: 'league_points_sum',
    Header: 'League Points Sum',
  },
  {
    accessor: 'league_points_rank_from',
    Header: 'League Points Rank From',
  },
  {
    accessor: 'league_points_rank_to',
    Header: 'League Points Rank To',
  },
  // <<< league points <<<
  {
    accessor: 'match_win_streak',
    Header: 'Match Win Streak',
  },
  {
    accessor: 'last_match_id',
    Header: 'Last Match Id',
  },
]

export default Settings
