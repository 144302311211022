import { DateTime } from "utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class TeamOrganizationDatasourceIdentifierRecord<TJsonData extends TeamOrganizationDatasourceIdentifierRecord.CtorJsonData = TeamOrganizationDatasourceIdentifierRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  team_organization_id?: string
  identifier?: string
  identifier_kind?: string
  is_enabled?: boolean | null
  admin_note?: string
  original_data?: string
  is_primary?: boolean | null
  last_data_change_at?: Date | null

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TeamOrganizationDatasourceIdentifierRecord.CtorJsonData) {
    return new TeamOrganizationDatasourceIdentifierRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db);

    if(jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.team_organization_id = jsonData?.team_organization_id
    this.identifier = jsonData?.identifier
    this.identifier_kind = jsonData?.identifier_kind
    this.is_enabled = jsonData?.is_enabled
    this.admin_note = jsonData?.admin_note
    this.original_data = jsonData?.original_data
    this.is_primary = jsonData?.is_primary
    this.last_data_change_at = DateTime.parseDateOrNull(jsonData?.last_data_change_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      team_organization_id: this.team_organization_id,
      identifier: this.identifier,
      identifier_kind: this.identifier_kind,
      is_enabled: this.is_enabled,
      admin_note: this.admin_note,
      original_data: this.original_data,
      is_primary: this.is_primary,
      last_data_change_at: this.last_data_change_at?.toISOString()
    }) as TJsonData
  }
}

export module TeamOrganizationDatasourceIdentifierRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    team_organization_id?: string
    identifier?: string
    identifier_kind?: string
    is_enabled?: boolean | undefined
    priority?: number
    admin_note?: string
    original_data?: string
    is_primary?: boolean | null
    last_data_change_at?: string
  }

  export class Repository extends RecordRepository<TeamOrganizationDatasourceIdentifierRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by team_organizotion_id <<<
    #getByTeamOrgId:RecordRepository.NestingGetFieldFlags = {}

    getByTeamOrgId(teamOrgId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTeamOrgId, teamOrgId, options, {
        peek: () => this.peekByTeamOrgId(teamOrgId),
        load: () => this.loadByTeamOrgId(teamOrgId)
      })
    }

    peekByTeamOrgId(teamOrgId:string) {
      return this.peekAll(x => x?.team_organization_id == teamOrgId)
    }

    protected loadByTeamOrgId(teamOrgId:string) {
      return this.getApi().gwtMoreTeamOrganizationDatasourceIdentifiersByTeamOrgId(teamOrgId)
    }
    /// <<< by team_organizotion_id >>>

    create(record: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTeamOrganizationDatasourceIdentifier(record)
    }

    protected loadAll() {
      return this.getApi().getAllTeamOrganizationDatasourceIdentifiers()
    }

    protected loadById(id: string): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      return  this.getApi().getOneTeamOrganizationDatasourceIdentifierById(id)
    }
    protected updateById(id: string, patch: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTeamOrganizationDatasourceIdentifier(id, patch)
    }
    protected deleteById(id: string): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTeamOrganizationDatasourceIdentifier(id)
    }
    protected approveById(id: string, patch: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamOrganizationDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }

  }
}
