import React from "react";
import { AccordionContext } from "components/utils/Accordion/Accordion.context";
import CTAButton from "components/utils/Button/CTAButton";
import { RecordsSetting } from "components/utils/Table/_Table.settings";
import { TeamOrganizationRecord } from "data/TeamOrganizationRecord";
import { Routing } from "helpers/routing";
import { useContext } from "react";
import { EllipsisHIcon } from "styles/fontAwesome";

const Settings: RecordsSetting<TeamOrganizationRecord> = [
  {
    accessor: 'id',
    className: 'table-hidden',
  },
  {
    accessor: 'name',
    Header: 'Name',
    className: 'table-full-name'
  },
  {
    id: 'ctaButton',
    sticky: 'right',
    className: 'table-cta-button',
    Header: function HeaderContent({ row }: any) {
      const { toggle } = useContext(AccordionContext)

      return (
        <CTAButton onClick={toggle}>
          <EllipsisHIcon />
        </CTAButton>
      )
    },
    Cell: function CellContent({ row }: any) {
      return (
        <CTAButton path={Routing.getTeamOrganizationDetailUrl({ teamOrganization: row.values.id })}>
          {row.values.isFrozen ? 'View' : 'Edit'}
        </CTAButton>
      )
    },
  },
]

export default Settings
