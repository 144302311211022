import axios, { AxiosRequestConfig, Method } from 'axios'
import config from 'config/config.json'
import { Token } from 'helpers/token'
// Records
import { BaseRootRepository } from 'data/repository/BaseRootRepository'
import { BaseRecord } from 'data/BaseRecord'
import { SportRecord } from 'data/SportRecord'
import { TournamentRecord } from 'data/TournamentRecord'
import { TournamentStageRecord } from 'data/TournamentStageRecord'
import { TournamentRoundRecord } from 'data/TournamentRoundRecord'
import { MatchRecord } from 'data/MatchRecord'
import { TeamRecord } from 'data/TeamRecord'
import { SportsmanRecord } from 'data/SportsmanRecord'
import { TournamentTeamRankRecord } from 'data/TournamentTeamRankRecord'
import { TournamentSportsmanRankRecord } from 'data/TournamentSportsmanRankRecord'
import { UserRecord } from 'data/User'
import { MatchMapRecord } from 'data/MatchMapRecord'
import { GameMapRecord } from 'data/GameMapRecord'
import { PeriodKindRecord } from 'data/PeriodKindRecord'
import { RegionRecord } from 'data/RegionRecord'
import { TimespacesRecord } from 'data/TimespacesRecord'
import { SportsmanStatSummaryRecord } from 'data/SportsmanStatSummaryRecord'
import { TeamStatSummaryRecord } from 'data/TeamStatSummaryRecord'
import { MatchMapVetoRecord } from 'data/MatchMapVetoRecord'
import { SportsmanTeamMembershipRecord } from 'data/SportsmanTeamMembershipRecord'
import { SportsmanRoleRecord } from 'data/SportsmanRoleRecord'
import { PlayerRecord } from 'data/PlayerRecord'
import { SportsmanStatScalesRecord } from 'data/SportsmanStatScalesRecord'
import { TeamStatScalesRecord } from 'data/TeamStatScalesRecord'
import { SportsmanBiographyRecord } from 'data/SportsmanBiographyRecord'
import { TeamBiographyRecord } from 'data/TeamBiographyRecord'
import { GamingGearRecord } from 'data/GamingGearRecord'
import { SportsmanGamingGearsRecord } from 'data/SportsmanGamingGearsRecord'
import { NearestMatchRecord } from 'data/NearesMatchRecord'
import { AzureBlobRecord } from 'data/AzureBlobRecord'
import { AzureDiretcoryRecord } from 'data/AzureDirectoryRecord'
import { DatasourceRecord } from 'data/DatasourceRecord'
import { SportsmanDatasourceIdentifierRecord } from 'data/SportsmanDatasourceIdentifierRecord'
import { TeamOrganizationRecord } from 'data/TeamOrganizationRecord'
import { TeamDatasourceIdentifierRecord } from 'data/TeamDatasourceIdentifierRecord'
import { TeamOrganizationDatasourceIdentifierRecord } from 'data/TeamOrganizationDatasourceIdentifierRecord'
import { TournamentDatasourceIdentifierRecord } from 'data/TournamentDatasourceIdentifierRecord'
import { TournamentStageDatasourceIdentifierRecord } from 'data/TournamentStageDatasourceIdentifierRecord'
import { MatchDatasourceIdentifierRecord } from 'data/MatchDatasourceIdentifierRecord'
import { TournamentBiographyRecord } from 'data/TournamentBiographyRecord'

export class ChroupatorApi {
  db?: BaseRootRepository

  //#region >>> Current User >>>
  async login(email: string, password: string) {
    const result = (await this.callPostEndpoint('/admin/users/login', {
      email: email,
      password: password,
    })) as { ok: boolean; loginToken?: string }
    if (result.ok && result.loginToken) {
      Token.set(result.loginToken)
    }

    return result
  }

  async logout() {
    const result = (await this.callPostEndpoint('/admin/users/logout', {})) as {
      ok: boolean
    }
    if (result.ok) {
      Token.set('')
    }

    return result
  }

  async resetPassword(email: string) {
    const result = (await this.callPostEndpoint('/admin/users/reset-password', {
      email: email,
    })) as { ok: boolean }

    return result
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const result = (await this.callPostEndpoint(
      '/admin/users/me/change-password',
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      }
    )) as { ok: boolean; error: { message: string } }

    return result
  }

  async getMyProfile() {
    let result = null

    try {
      result = (await this.callGetItemEndpoint<UserRecord>(
        '/admin/users/me',
        UserRecord.createNew
      ))
    } catch {
      Token.set('')
    }

    return result
  }
  //#endregion <<< Current User <<<

  //#region >>> Sports >>>
  getAllSports() {
    return this.callGetItemsEndpoint('/admin/sports', SportRecord.createNew)
  }

  getOneSportById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sports/${id}`,
      SportRecord.createNew
    )
  }

  approveOneSportById(id: string, record: Partial<SportRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/${id}/approve`,
      SportRecord.createNew,
      record
    )
  }

  unapproveOneSportById(id: string, record: Partial<SportRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/${id}/unapprove`,
      SportRecord.createNew,
      record
    )
  }

  publishOneSportById(id: string, record: Partial<SportRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/${id}/publish`,
      SportRecord.createNew,
      record
    )
  }

  unpublishOneSportById(id: string, record: Partial<SportRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/${id}/unpublish`,
      SportRecord.createNew,
      record
    )
  }

  freezeOneSportById(id: string, record: Partial<SportRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/${id}/freeze`,
      SportRecord.createNew,
      record
    )
  }

  unfreezeOneSportById(id: string, record: Partial<SportRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/${id}/unfreeze`,
      SportRecord.createNew,
      record
    )
  }

  touchAllSports() {
    return this.callPostEndpoint('/admin/sports/touch-all', {})
  }

  touchOneSportById(id: string) {
    return this.callPostEndpoint(`/admin/sports/${id}/touch`, {})
  }

  createOneSport(record: Partial<SportRecord>) {
    return this.callPostItemEndpoint(
      `/admin/sports`,
      SportRecord.createNew,
      record
    )
  }

  updateOneSport(id: string, record: Partial<SportRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/${id}`,
      SportRecord.createNew,
      record
    )
  }

  deleteOneSport(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/sports/${id}`,
      SportRecord.createNew
    )
  }
  //#endregion <<< Sports <<<<

  //#region >>> Tournaments >>>
  getAllTournaments() {
    return this.callGetItemsEndpoint(
      '/admin/tournaments',
      TournamentRecord.createNew
    )
  }

  getOneTournamentById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/${id}`,
      TournamentRecord.createNew
    )
  }

  approveOneTournamentById(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}/approve`,
      TournamentRecord.createNew,
      record
    )
  }

  unapproveOneTournamentById(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}/unapprove`,
      TournamentRecord.createNew,
      record
    )
  }

  publishOneTournamentById(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}/publish`,
      TournamentRecord.createNew,
      record
    )
  }

  unpublishOneTournamentById(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}/unpublish`,
      TournamentRecord.createNew,
      record
    )
  }

  freezeOneTournamentById(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}/freeze`,
      TournamentRecord.createNew,
      record
    )
  }

  unfreezeOneTournamentById(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}/unfreeze`,
      TournamentRecord.createNew,
      record
    )
  }

  touchAllTournaments() {
    return this.callPostEndpoint('/admin/tournaments/touch-all', {})
  }

  touchOneTournamentById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/${id}/touch`, {})
  }

  recalculateTournamentStatsById(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}/recalculate`,
      TournamentRecord.createNew,
      record
    )
  }

  createOneTournament(record: Partial<TournamentRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments`,
      TournamentRecord.createNew,
      record
    )
  }

  updateOneTournament(id: string, record: Partial<TournamentRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/${id}`,
      TournamentRecord.createNew,
      record
    )
  }

  deleteOneTournament(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/${id}`,
      TournamentRecord.createNew
    )
  }
  //#endregion <<< Tournaments <<<

  //#region >>> Tournament Biographies >>>
  getAllTournamentBiographies() {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/biographies`,
      TournamentBiographyRecord.createNew
    )
  }

  getOneTournamentBiographyByTournamentId(tournamentId: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/biographies/by-tournament/${tournamentId}`,
      TournamentBiographyRecord.createNew
    )
  }

  getOneTournamentBiographyById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/biographies/${id}`,
      TournamentBiographyRecord.createNew
    )
  }

  createOneTournamentBiography(record: Partial<TournamentBiographyRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/biographies`,
      TournamentBiographyRecord.createNew,
      record
    )
  }

  updateOneTournamentBiography(id: string, record: Partial<TournamentBiographyRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/biographies/${id}`,
      TournamentBiographyRecord.createNew,
      record
    )
  }

  deleteOneTournamentBiography(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/biographies/${id}`,
      TournamentBiographyRecord.createNew
    )
  }
  //#endregion <<< Tournament Biographies <<<

  //#region >>> Tournament Stages >>>
  getAllTournamentStages() {
    return this.callGetItemsEndpoint(
      '/admin/tournaments/-/stages',
      TournamentStageRecord.createNew
    )
  }

  getMoreTournamentStagesByTournamentId(tournamentId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/${tournamentId}/stages`,
      TournamentStageRecord.createNew
    )
  }

  getOneTournamentStageById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/stages/${id}`,
      TournamentStageRecord.createNew
    )
  }

  approveOneTournamentStageById(
    id: string,
    record: Partial<TournamentStageRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/${id}/approve`,
      TournamentStageRecord.createNew,
      record
    )
  }

  unapproveOneTournamentStageById(
    id: string,
    record: Partial<TournamentStageRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/${id}/unapprove`,
      TournamentStageRecord.createNew,
      record
    )
  }

  publishOneTOurnamentStageById(
    id: string,
    record: Partial<TournamentStageRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/${id}/publish`,
      TournamentStageRecord.createNew,
      record
    )
  }

  unpublishOneTOurnamentStageById(
    id: string,
    record: Partial<TournamentStageRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/${id}/unpublish`,
      TournamentStageRecord.createNew,
      record
    )
  }

  freezeOneTournamentStageById(
    id: string,
    record: Partial<TournamentStageRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/${id}/freeze`,
      TournamentStageRecord.createNew,
      record
    )
  }

  unfreezeOneTournamentStageById(
    id: string,
    record: Partial<TournamentStageRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/${id}/unfreeze`,
      TournamentStageRecord.createNew,
      record
    )
  }

  touchAllTournamentStages() {
    return this.callPostEndpoint('/admin/tournaments/-/stages/touch-all', {})
  }

  touchOneTournamentStageById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/stages/${id}/touch`, {})
  }

  createOneTournamentStage(record: Partial<TournamentStageRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/stages`,
      TournamentStageRecord.createNew,
      record
    )
  }

  updateOneTournamentStage(id: string, record: Partial<TournamentStageRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/${id}`,
      TournamentStageRecord.createNew,
      record
    )
  }

  deleteOneTournamentStage(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/stages/${id}`,
      TournamentStageRecord.createNew
    )
  }
  //#endregion <<< Tournament Stages <<<

  //#region >>> Tournament Rounds >>>
  getAllTournamentRounds() {
    return this.callGetItemsEndpoint(
      '/admin/tournaments/-/stages/-/rounds',
      TournamentRoundRecord.createNew
    )
  }

  getMoreTournamentRoundsByTournamentStageId(tournamentStageId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/stages/${tournamentStageId}/rounds`,
      TournamentRoundRecord.createNew
    )
  }

  getOneTournamentRoundById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}`,
      TournamentRoundRecord.createNew
    )
  }

  approveOneTournamentRoundById(
    id: string,
    record: Partial<TournamentRoundRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}/approve`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  unapproveOneTournamentRoundById(
    id: string,
    record: Partial<TournamentRoundRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}/unapprove`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  publishOneTournamentRoundById(id: string, record: Partial<TournamentRoundRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}/publish`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  unpublishOneTournamentRoundById(id: string, record: Partial<TournamentRoundRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}/unpublish`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  freezeOneTournamentRoundById(
    id: string,
    record: Partial<TournamentRoundRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}/freeze`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  unfreezeOneTournamentRoundById(
    id: string,
    record: Partial<TournamentRoundRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}/unfreeze`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  touchAllTournamentRounds() {
    return this.callPostEndpoint('/admin/tournaments/-/stages/-/rounds/touch-all', {})
  }

  touchOneTournamentRoundById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/stages/-/rounds/${id}/touch`, {})
  }

  createOneTournamentRound(record: Partial<TournamentRoundRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  updateOneTournamentRound(id: string, record: Partial<TournamentRoundRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}`,
      TournamentRoundRecord.createNew,
      record
    )
  }

  deleteOneTournamentRound(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/stages/-/rounds/${id}`,
      TournamentRoundRecord.createNew
    )
  }

  getTournamentRoundIdents() {
    return this.callGetEndpoint(
      `/admin/tournaments/-/stages/-/rounds/enums`
    )
  }
  //#endregion <<< Tournament Rounds <<<

  //#region >>> Tournament Team Ranks >>>
  getMoreTournamentTeamRanksByTournamentId(tournamentId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/${tournamentId}/team-ranks`,
      TournamentTeamRankRecord.createNew
    )
  }

  getOneTournamentTeamRankById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/team-ranks/${id}`,
      TournamentTeamRankRecord.createNew
    )
  }

  freezeOneTournamentTeamRankById(
    id: string,
    record: Partial<TournamentTeamRankRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/team-ranks/${id}/freeze`,
      TournamentTeamRankRecord.createNew,
      record
    )
  }

  unfreezeOneTournamentTeamRankById(
    id: string,
    record: Partial<TournamentTeamRankRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/team-ranks/${id}/unfreeze`,
      TournamentTeamRankRecord.createNew,
      record
    )
  }

  createOneTournamentTeamRank(record: Partial<TournamentTeamRankRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/team-ranks`,
      TournamentTeamRankRecord.createNew,
      record
    )
  }

  touchAllTournamentTeamRanks() {
    return this.callPostEndpoint('/admin/tournaments/-/team-ranks/touch-all', {})
  }

  touchOneTournamentTeamRankById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/team-ranks/${id}/touch`, {})
  }

  updateOneTournamentTeamRank(
    id: string,
    record: Partial<TournamentTeamRankRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/team-ranks/${id}`,
      TournamentTeamRankRecord.createNew,
      record
    )
  }

  deleteOneTournamentTeamRank(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/team-ranks/${id}`,
      TournamentTeamRankRecord.createNew
    )
  }
  //#endregion <<< Tournament Team Ranks <<<

  //#region >>> Tournament Sportsman Ranks >>>
  getAllTournamentSportsmanRanks() {
    return this.callGetItemsEndpoint(
      '/admin/tournaments/-/sportsman-ranks',
      TournamentSportsmanRankRecord.createNew
    )
  }

  getMoreTournamentSportsmanRanksByTournamentId(tournamentId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/${tournamentId}/sportsman-ranks`,
      TournamentSportsmanRankRecord.createNew
    )
  }

  getOneTournamentSportsmanRankById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/sportsman-ranks/${id}`,
      TournamentSportsmanRankRecord.createNew
    )
  }

  freezeOneTournamentSportsmanRankById(
    id: string,
    record: Partial<TournamentSportsmanRankRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/sportsman-ranks/${id}/freeze`,
      TournamentSportsmanRankRecord.createNew,
      record
    )
  }

  unfreezeOneTournamentSportsmanRankById(
    id: string,
    record: Partial<TournamentSportsmanRankRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/sportsman-ranks/${id}/unfreeze`,
      TournamentSportsmanRankRecord.createNew,
      record
    )
  }

  createOneTournamentSportsmanRank(record: Partial<TournamentSportsmanRankRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/sportsman-ranks`,
      TournamentSportsmanRankRecord.createNew,
      record
    )
  }

  touchAllTournamentSportsmanRanks() {
    return this.callPostEndpoint('/admin/tournaments/-/sportsman-ranks/touch-all', {})
  }

  touchOneTournamentSportsmanRankById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/sportsman-ranks/${id}/touch`, {})
  }

  updateOneTournamentSportsmanRank(
    id: string,
    record: Partial<TournamentSportsmanRankRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/sportsman-ranks/${id}`,
      TournamentSportsmanRankRecord.createNew,
      record
    )
  }

  deleteOneTournamentSportsmanRank(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/sportsman-ranks/${id}`,
      TournamentSportsmanRankRecord.createNew
    )
  }
  //#endregion <<< Tournament Sportsman Ranks <<<

  //#region >>> Matches >>>
  getAllMatches() {
    return this.callGetItemsEndpoint(
      '/admin/tournaments/-/matches',
      MatchRecord.createNew
    )
  }

  getMoreMatchesByTournamentId(tournamentId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/${tournamentId}/matches`,
      MatchRecord.createNew
    )
  }

  getOneMatchById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/matches/${id}`,
      MatchRecord.createNew
    )
  }

  approveOneMatchById(id: string, record: Partial<MatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/${id}/approve`,
      MatchRecord.createNew,
      record
    )
  }

  unapproveOneMatchById(id: string, record: Partial<MatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/${id}/unapprove`,
      MatchRecord.createNew,
      record
    )
  }

  publishOneMatchById(id: string, record: Partial<MatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/${id}/publish`,
      MatchRecord.createNew,
      record
    )
  }

  unpublishOneMatchById(id: string, record: Partial<MatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/${id}/unpublish`,
      MatchRecord.createNew,
      record
    )
  }

  freezeOneMatchById(id: string, record: Partial<MatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/${id}/freeze`,
      MatchRecord.createNew,
      record
    )
  }

  unfreezeOneMatchById(id: string, record: Partial<MatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/${id}/unfreeze`,
      MatchRecord.createNew,
      record
    )
  }

  touchAllMatches() {
    return this.callPostEndpoint('/admin/tournaments/-/matches/touch-all', {})
  }

  touchOneMatchById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/matches/${id}/touch`, {})
  }

  createOneMatch(record: Partial<MatchRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/matches/`,
      MatchRecord.createNew,
      record
    )
  }

  updateOneMatch(id: string, record: Partial<MatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/${id}`,
      MatchRecord.createNew,
      record
    )
  }

  deleteOneMatch(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/matches/${id}`,
      MatchRecord.createNew
    )
  }
  //#endregion <<< Matches <<<

  //#region >>> Match Maps >>>
  getAllMatchMaps() {
    return this.callGetItemsEndpoint(
      '/admin/tournaments/-/matches/-/matchmaps',
      MatchMapRecord.createNew
    )
  }

  getMoreMatchMapsByMatchId(matchId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/matches/${matchId}/matchmaps`,
      MatchMapRecord.createNew
    )
  }

  getOneMatchMapById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/matches/-/matchmaps/${id}`,
      MatchMapRecord.createNew
    )
  }

  freezeOneMatchMapById(id: string, record: Partial<MatchMapRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/matchmaps/${id}/freeze`,
      MatchMapRecord.createNew,
      record
    )
  }

  unfreezeOneMatchMapById(id: string, record: Partial<MatchMapRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/matchmaps/${id}/unfreeze`,
      MatchMapRecord.createNew,
      record
    )
  }

  touchAllMatchMaps() {
    return this.callPostEndpoint('/admin/tournaments/-/matches/-/matchmaps/touch-all', {})
  }

  touchOneMatchMapById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/matches/-/matchmaps/${id}/touch`, {})
  }

  createOneMatchMap(record: Partial<MatchMapRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/matches/-/matchmaps`,
      MatchMapRecord.createNew,
      record
    )
  }

  updateOneMatchMap(id: string, record: Partial<MatchMapRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/matchmaps/${id}`,
      MatchMapRecord.createNew,
      record
    )
  }

  deleteOneMatchMap(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/matches/-/matchmaps/${id}`,
      MatchMapRecord.createNew
    )
  }
  //#endregion <<< Match Maps <<<

  //#region >>> Match Map Vetos >>>
  getAllMatchMapVetos() {
    return this.callGetItemsEndpoint(
      '/admin/tournaments/-/matches/-/match_map_vetos',
      MatchMapVetoRecord.createNew
    )
  }

  getMoreMatchMapVetosByMatchId(matchId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/matches/${matchId}/match_map_vetos`,
      MatchMapVetoRecord.createNew
    )
  }

  getOneMatchMapVetoById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/matches/-/match_map_vetos/${id}`,
      MatchMapVetoRecord.createNew
    )
  }

  touchAllMatchMapVetos() {
    return this.callPostEndpoint('/admin/tournaments/-/matches/-/match_map_vetos/touch-all', {})
  }

  touchOneMatchMapVetoById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/matches/-/match_map_vetos/${id}/touch`, {})
  }

  createOneMatchMapVeto(record: Partial<MatchMapVetoRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/matches/-/match_map_vetos`,
      MatchMapVetoRecord.createNew,
      record
    )
  }

  updateOneMatchMapVeto(id: string, record: Partial<MatchMapVetoRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/match_map_vetos/${id}`,
      MatchMapVetoRecord.createNew,
      record
    )
  }

  deleteOneMatchMapVeto(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/matches/-/match_map_vetos/${id}`,
      MatchMapVetoRecord.createNew
    )
  }
  //#endregion <<< Match Map Vetos <<<

  //#region >>> Teams >>>
  getMoreTeamsBySportId(sportId: string) {
    return this.callGetItemsEndpoint(
      `/admin/sports/${sportId}/teams`,
      TeamRecord.createNew
    )
  }

  getMoreTeamsByTeamOrgId(teamOrgId:string) {
    return this.callGetItemsEndpoint(
      `/admin/team-organizations/${teamOrgId}/teams`,
      TeamRecord.createNew
    )
  }

  getOneTeamById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sports/-/teams/${id}`,
      TeamRecord.createNew
    )
  }

  approveOneTeamById(id: string, record: Partial<TeamRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/teams/${id}/approve`,
      TeamRecord.createNew,
      record
    )
  }

  unapproveOneTeamById(id: string, record: Partial<TeamRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/teams/${id}/unapprove`,
      TeamRecord.createNew,
      record
    )
  }

  publishOneTeamById(id: string, record: Partial<TeamRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/teams/${id}/publish`,
      TeamRecord.createNew,
      record
    )
  }

  unpublishOneTeamById(id: string, record: Partial<TeamRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/teams/${id}/unpublish`,
      TeamRecord.createNew,
      record
    )
  }

  touchAllTeams() {
    return this.callPostEndpoint('/admin/sports/-/teams/touch-all', {})
  }

  touchOneTeamById(id: string) {
    return this.callPostEndpoint(`/admin/sports/-/teams/${id}/touch`, {})
  }

  createOneTeam(record: Partial<TeamRecord>) {
    return this.callPostItemEndpoint(
      `/admin/sports/-/teams`,
      TeamRecord.createNew,
      record
    )
  }

  freezeOneTeamById(id: string, record: Partial<TeamRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/teams/${id}/freeze`,
      TeamRecord.createNew,
      record
    )
  }

  unfreezeOneTeamById(id: string, record: Partial<TeamRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/teams/${id}/unfreeze`,
      TeamRecord.createNew,
      record
    )
  }

  updateOneTeam(id: string, record: Partial<TeamRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/teams/${id}`,
      TeamRecord.createNew,
      record
    )
  }

  deleteOneTeam(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/sports/-/teams/${id}`,
      TeamRecord.createNew
    )
  }
  //#endregion <<< Teams <<<

  //#region >>> Teams Biography >>>
  getAllTeamBiographies() {
    return this.callGetItemsEndpoint(
      `/admin/team-biographies`,
      TeamBiographyRecord.createNew
    )
  }

  getOneTeamBiographyByTeamId(teamId: string) {
    return this.callGetItemEndpoint(
      `/admin/team-biographies/by-team/${teamId}`,
      TeamBiographyRecord.createNew
    )
  }

  getOneTeamBiographyById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/team-biographies/${id}`,
      TeamBiographyRecord.createNew
    )
  }

  createOneTeamBiography(record: Partial<TeamBiographyRecord>) {
    return this.callPostItemEndpoint(
      `/admin/team-biographies`,
      TeamBiographyRecord.createNew,
      record
    )
  }

  updateOneTeamBiography(id: string, record: Partial<TeamBiographyRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/team-biographies/${id}`,
      TeamBiographyRecord.createNew,
      record
    )
  }

  deleteOneTeamBiography(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/team-biographies/${id}`,
      TeamBiographyRecord.createNew
    )
  }
  //#endregion <<< Teams Biography <<<

  //#region >>> Sportsmen >>>
  getMoreSportsmenBySportId(sportId: string) {
    return this.callGetItemsEndpoint(
      `/admin/sports/${sportId}/sportsmen`,
      SportsmanRecord.createNew
    )
  }

  getOneSportsmanById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sports/-/sportsmen/${id}`,
      SportsmanRecord.createNew
    )
  }

  approveOneSportsmanById(id: string, record: Partial<SportsmanRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/sportsmen/${id}/approve`,
      SportsmanRecord.createNew,
      record
    )
  }

  unapproveOneSportsmanById(id: string, record: Partial<SportsmanRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/sportsmen/${id}/unapprove`,
      SportsmanRecord.createNew,
      record
    )
  }

  publishOneSportsmanById(id:string, record: Partial<SportsmanRecord>){
    return this.callPatchItemEndpoint(
      `/admin/sports/-/sportsmen/${id}/publish`,
      SportsmanRecord.createNew,
      record
    )
  }

  unpublishOneSportsmanById(id:string, record: Partial<SportsmanRecord>){
    return this.callPatchItemEndpoint(
      `/admin/sports/-/sportsmen/${id}/unpublish`,
      SportsmanRecord.createNew,
      record
    )
  }

  freezeOneSportsmanById(id: string, record: Partial<SportsmanRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/sportsmen/${id}/freeze`,
      SportsmanRecord.createNew,
      record
    )
  }

  unfreezeOneSportsmanById(id: string, record: Partial<SportsmanRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/sportsmen/${id}/unfreeze`,
      SportsmanRecord.createNew,
      record
    )
  }

  touchAllSportsmen() {
    return this.callPostEndpoint('/admin/sports/-/sportsmen/touch-all', {})
  }

  touchOneSportsmanById(id: string) {
    return this.callPostEndpoint(`/admin/sports/-/sportsmen/${id}/touch`, {})
  }

  createOneSportman(record: Partial<SportsmanRecord>) {
    return this.callPostItemEndpoint(
      `/admin/sports/-/sportsmen`,
      SportsmanRecord.createNew,
      record
    )
  }

  updateOneSportsman(id: string, record: Partial<SportsmanRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sports/-/sportsmen/${id}`,
      SportsmanRecord.createNew,
      record
    )
  }

  deleteOneSportsman(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/sports/-/sportsmen/${id}`,
      SportsmanRecord.createNew
    )
  }
  //#endregion <<< Sportsmen <<<

  //#region >>> Sportsman Biographies >>>
  getAllSportsmanBiographies() {
    return this.callGetItemsEndpoint(
      `/admin/sportsman-biographies`,
      SportsmanBiographyRecord.createNew
    )
  }

  getOneSportmanBiographyBySportsmanId(sportsmanId: string) {
    return this.callGetItemEndpoint(
      `/admin/sportsman-biographies/by-sportsman/${sportsmanId}`,
      SportsmanBiographyRecord.createNew
    )
  }

  getOneSportmanBiographyById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sportsman-biographies/${id}`,
      SportsmanBiographyRecord.createNew
    )
  }


  createOneSportsmanBiography(record: Partial<SportsmanBiographyRecord>) {
    return this.callPostItemEndpoint(
      `/admin/sportsman-biographies`,
      SportsmanBiographyRecord.createNew,
      record
    )
  }

  updateOneSportsmanBiography(id: string, record: Partial<SportsmanBiographyRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sportsman-biographies/${id}`,
      SportsmanBiographyRecord.createNew,
      record
    )
  }

  deleteOneSportsmanBiography(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/sportsman-biographies/${id}`,
      SportsmanBiographyRecord.createNew
    )
  }

  //#endregion <<< Sportsman Biographies <<<

  //#region >>> Sportsman Gaming Gears >>>
  getAllSportsmanGamingGears() {
    return this.callGetItemsEndpoint(
      `/admin/sportsman-gaming-gear`,
      SportsmanGamingGearsRecord.createNew
    )
  }

  getOneSportsmanGamingGearsBySportsmanId(sportsmanId: string) {
    return this.callGetItemEndpoint(
      `/admin/sportsman-gaming-gear/by-sportsman/${sportsmanId}`,
      SportsmanGamingGearsRecord.createNew
    )
  }

  getOneSportsmanGamingGearsById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sportsman-gaming-gear/${id}`,
      SportsmanGamingGearsRecord.createNew
    )
  }

  createOneSportsmanGamignGears(record: Partial<SportsmanGamingGearsRecord>) {
    return this.callPostItemEndpoint(
      `/admin/sportsman-gaming-gear`,
      SportsmanGamingGearsRecord.createNew,
      record
    )
  }

  updateOneSportsmanGamingGears(id: string, record: Partial<SportsmanGamingGearsRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sportsman-gaming-gear/${id}`,
      SportsmanGamingGearsRecord.createNew,
      record
    )
  }

  deleteOneSportsmanGamingGears(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/sportsman-gaming-gear/${id}`,
      SportsmanGamingGearsRecord.createNew
    )
  }

  //#endregion <<< Sportsman Biographies <<<

  //#region >>> Sportsman Roles >>>
  getAllSportsmanRoles() {
    return this.callGetItemsEndpoint(
      `/admin/sportsman_roles`,
      SportsmanRoleRecord.createNew
    )
  }

  getOneSportsmanRoleById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sportsman_roles/${id}`,
      SportsmanRoleRecord.createNew
    )
  }

  touchAllSportsmanRoles() {
    return this.callPostEndpoint('/admin/sportsman_roles/touch-all', {})
  }

  touchOneSportsmanRoleById(id: string) {
    return this.callPostEndpoint(`/admin/sportsman_roles/${id}/touch`, {})
  }

  createOneSportsmanRole(record: Partial<SportsmanRoleRecord>) {
    return this.callPostItemEndpoint(
      `/admin/sportsman_roles`,
      SportsmanRoleRecord.createNew,
      record
    )
  }

  updateOneSportsmanRole(id: string, record: Partial<SportsmanRoleRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sportsman_roles/${id}`,
      SportsmanRoleRecord.createNew,
      record
    )
  }

  deleteOneSportsmanRole(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/sportsman_roles/${id}`,
      SportsmanRoleRecord.createNew
    )
  }

  //#endregion <<< Sportsman Roles <<<

  //#region >>> Player >>>
  getAllPlayers() {
    return this.callGetItemsEndpoint(`/admin/tournaments/-/matches/-/players`, PlayerRecord.createNew)
  }

  getOnePlayerById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/matches/-/players/${id}`,
      PlayerRecord.createNew
    )
  }

  getMorePlayersByMatchId(matchId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/matches/${matchId}/players`,
      PlayerRecord.createNew
    )
  }

  approveOnePlayerById(id: string, record: Partial<PlayerRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/players/${id}/approve`,
      PlayerRecord.createNew,
      record
    )
  }

  unapproveOnePlayerById(id: string, record: Partial<PlayerRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/players/${id}/unapprove`,
      PlayerRecord.createNew,
      record
    )
  }

  publishOnePlayerById(id: string, record: Partial<PlayerRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/players/${id}/publish`,
      PlayerRecord.createNew,
      record
    )
  }

  unpublishOnePlayerById(id: string, record: Partial<PlayerRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/players/${id}/unpublish`,
      PlayerRecord.createNew,
      record
    )
  }

  touchAllPlayers() {
    return this.callPostEndpoint('/admin/tournaments/-/matches/-/players/touch-all', {})
  }

  touchOnePlayerById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/matches/-/players/${id}/touch`, {})
  }

  createOnePlayer(record: Partial<PlayerRecord>) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/matches/-/players`,
      PlayerRecord.createNew,
      record
    )
  }

  updateOnePlayer(id: string, record: Partial<PlayerRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/matches/-/players/${id}`,
      PlayerRecord.createNew,
      record
    )
  }

  deleteOnePlayer(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/matches/-/players/${id}`,
      PlayerRecord.createNew
    )
  }
  //#endregion <<< Player <<<

  //#region >>> Sportsman Team Memberships >>>
  getAllSportsmanTeamMemberships() {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/sportsman_team_memberships/`,
      SportsmanTeamMembershipRecord.createNew
    )
  }

  getMoreSportsmanTeamMembershipsByTournamentId(tournamentId: string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/${tournamentId}/sportsman_team_memberships`,
      SportsmanTeamMembershipRecord.createNew
    )
  }

  getMoreSportsmanTeamMembershipsBySportsmanId(sportsmanId: string) {
    return this.callGetItemsEndpoint(
      `/admin/sports/-/sportsmen/${sportsmanId}/team-memberships`,
      SportsmanTeamMembershipRecord.createNew
    )
  }

  getMoreSportsmanTeamMembershipsByTeamId(teamId: string) {
    return this.callGetItemsEndpoint(
      `/admin/sports/-/teams/${teamId}/sportsman-memberships`,
      SportsmanTeamMembershipRecord.createNew
    )
  }

  getOneSportsmanTeamMembershipById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/tournaments/-/sportsman_team_memberships/${id}`,
      SportsmanTeamMembershipRecord.createNew
    )
  }

  freezeOneSportsmanTeamMembershipById(id: string, record: Partial<SportsmanTeamMembershipRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/sportsman_team_memberships/${id}/freeze`,
      SportsmanTeamMembershipRecord.createNew,
      record
    )
  }

  unfreezeOneSportsmanTeamMembershipById(id: string, record: Partial<SportsmanTeamMembershipRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/sportsman_team_memberships/${id}/unfreeze`,
      SportsmanTeamMembershipRecord.createNew,
      record
    )
  }

  touchAllSportsmanTeamMemberships() {
    return this.callPostEndpoint('/admin/tournaments/-/sportsman_team_memberships/touch-all', {})
  }

  touchOneSportsmanTeamMembershipById(id: string) {
    return this.callPostEndpoint(`/admin/tournaments/-/sportsman_team_memberships/${id}/touch`, {})
  }

  createOneSportsmanTeamMembership(
    record: Partial<SportsmanTeamMembershipRecord>
  ) {
    return this.callPostItemEndpoint(
      `/admin/tournaments/-/sportsman_team_memberships`,
      SportsmanTeamMembershipRecord.createNew,
      record
    )
  }

  updateOneSportsmanTeamMembership(
    id: string,
    record: Partial<SportsmanTeamMembershipRecord>
  ) {
    return this.callPatchItemEndpoint(
      `/admin/tournaments/-/sportsman_team_memberships/${id}`,
      SportsmanTeamMembershipRecord.createNew,
      record
    )
  }

  deleteOneSportsmanTeamMembership(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/tournaments/-/sportsman_team_memberships/${id}`,
      SportsmanTeamMembershipRecord.createNew
    )
  }
  //#endregion <<< Sportsman Team Memberships <<<

  //#region >>> Game Maps >>>
  getAllGameMaps() {
    return this.callGetItemsEndpoint(
      `/admin/game_maps`,
      GameMapRecord.createNew
    )
  }

  getOneGameMapById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/game_maps/${id}`,
      GameMapRecord.createNew
    )
  }

  updateOneGameMap(id: string, record: Partial<GameMapRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/game_maps/${id}`,
      GameMapRecord.createNew,
      record
    )
  }

  touchAllGameMaps() {
    return this.callPostEndpoint('/admin/game_maps/touch-all', {})
  }

  touchOneGameMapById(id: string) {
    return this.callPostEndpoint(`/admin/game_maps/${id}/touch`, {})
  }

  //#endregion <<< Game Maps <<<

  //#region >>> Period Kinds >>>
  createOnePeriodKind(record: Partial<PeriodKindRecord>) {
    return this.callPostItemEndpoint(
      `/admin/period_kinds`,
      PeriodKindRecord.createNew,
      record
    )
  }

  getAllPeriodKinds() {
    return this.callGetItemsEndpoint(
      `/admin/period_kinds`,
      PeriodKindRecord.createNew
    )
  }

  getOnePeriodKindById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/period_kinds/${id}`,
      PeriodKindRecord.createNew
    )
  }

  touchAllPeriodKinds() {
    return this.callPostEndpoint('/admin/period_kinds/touch-all', {})
  }

  touchOnePeriodKindById(id: string) {
    return this.callPostEndpoint(`/admin/period_kinds/${id}/touch`, {})
  }

  updateOnePeriodKind(id: string, record: Partial<PeriodKindRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/period_kinds/${id}`,
      PeriodKindRecord.createNew,
      record
    )
  }

  deleteOnePeriodKind(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/period_kinds/${id}`,
      PeriodKindRecord.createNew
    )
  }

  //#endregion <<< Period Kinds <<<

  //#region >>> Regions >>>
  createOneRegion(record: Partial<RegionRecord>) {
    return this.callPostItemEndpoint(
      `/admin/regions`,
      RegionRecord.createNew,
      record
    )
  }

  getAllRegions() {
    return this.callGetItemsEndpoint(`/admin/regions`, RegionRecord.createNew)
  }

  getOneRegionById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/regions/${id}`,
      RegionRecord.createNew
    )
  }

  touchAllRegions() {
    return this.callPostEndpoint('/admin/regions/touch-all', {})
  }

  touchOneRegionById(id: string) {
    return this.callPostEndpoint(`/admin/regions/${id}/touch`, {})
  }

  updateOneRegion(id: string, record: Partial<RegionRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/regions/${id}`,
      RegionRecord.createNew,
      record
    )
  }

  deleteOneRegion(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/regions/${id}`,
      RegionRecord.createNew
    )
  }

  //#endregion <<< Regions <<<

  //#region >>> Timespaces >>>
  getAllTimespaces() {
    return this.callGetItemsEndpoint(
      `/admin/timespaces`,
      TimespacesRecord.createNew
    )
  }

  getOneTimespaceById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/timespaces/${id}`,
      TimespacesRecord.createNew
    )
  }

  touchAllTimespaces() {
    return this.callPostEndpoint('/admin/timespaces/touch-all', {})
  }

  touchOneTimespaceById(id: string) {
    return this.callPostEndpoint(`/admin/timespaces/${id}/touch`, {})
  }

  //#endregion <<< Timespaces <<<

  //#region >>> Sportsman Stat Summaries >>>
  getAllSportsmanStatSummaries() {
    return this.callGetItemsEndpoint(
      `/admin/sportsman_stat_summary`,
      SportsmanStatSummaryRecord.createNew
    )
  }

  getOneSportsmanStatSummaryById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sportsman_stat_summary/${id}`,
      SportsmanStatSummaryRecord.createNew
    )
  }

  touchAllSportsmanStatSummaries() {
    return this.callPostEndpoint('/admin/sportsman_stat_summary/touch-all', {})
  }

  touchOneSportsmanStatSummaryById(id: string) {
    return this.callPostEndpoint(`/admin/sportsman_stat_summary/${id}/touch`, {})
  }

  //#endregion <<< Sportsman Stat Summaries <<<

  //#region >>> Team Stat Summaries >>>
  getAllTeamStatSummaries() {
    return this.callGetItemsEndpoint(
      `/admin/team_stat_summary`,
      TeamStatSummaryRecord.createNew
    )
  }

  getOneTeamStatSummaryById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/team_stat_summary/${id}`,
      TeamStatSummaryRecord.createNew
    )
  }

  touchAllTeamStatSummaries() {
    return this.callPostEndpoint('/admin/team_stat_summary/touch-all', {})
  }

  touchOneTeamStatSummaryById(id: string) {
    return this.callPostEndpoint(`/admin/team_stat_summary/${id}/touch`, {})
  }

  //#endregion <<< Team Stat Summaries <<<

  //#region >>> Sportsman Stat Scales >>>
  getAllSportsmanStatScales() {
    return this.callGetItemsEndpoint(
      `/admin/sportsman-stat-scales`,
      SportsmanStatScalesRecord.createNew
    )
  }

  getOneSportsmanStatScaleById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/sportsman-stat-scales/${id}`,
      SportsmanStatScalesRecord.createNew
    )
  }

  updateOneSportsmanStatScale(id: string, record: Partial<SportsmanStatScalesRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sportsman-stat-scales/${id}`,
      SportsmanStatScalesRecord.createNew,
      record
    )
  }

  freezeOneSportsmanStatScaleById(id: string, record: Partial<SportsmanStatScalesRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sportsman-stat-scales/${id}/freeze`,
      SportsmanStatScalesRecord.createNew,
      record
    )
  }

  unfreezeOneSportsmanStatScaleById(id: string, record: Partial<SportsmanStatScalesRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/sportsman-stat-scales/${id}/unfreeze`,
      SportsmanStatScalesRecord.createNew,
      record
    )
  }

  touchAllSportsmanStatScales() {
    return this.callPostEndpoint('/admin/sportsman-stat-scales/touch-all', {})
  }

  touchOneSportsmanStatScaleById(id: string) {
    return this.callPostEndpoint(`/admin/sportsman-stat-scales/${id}/touch`, {})
  }
  //#endregion <<< Sportsman Stat Scales <<<

  //#region >>> Team Stat Scales >>>
  getAllTeamStatScales() {
    return this.callGetItemsEndpoint(
      `/admin/team-stat-scales`,
      TeamStatScalesRecord.createNew
    )
  }

  getOneTeamStatScaleById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/team-stat-scales/${id}`,
      TeamStatScalesRecord.createNew
    )
  }

  updateOneTeamStatScale(id: string, record: Partial<TeamStatScalesRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/team-stat-scales/${id}`,
      TeamStatScalesRecord.createNew,
      record
    )
  }

  freezeOneTeamStatScaleById(id: string, record: Partial<TeamStatScalesRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/team-stat-scales/${id}/freeze`,
      TeamStatScalesRecord.createNew,
      record
    )
  }

  unfreezeOneTeamStatScaleById(id: string, record: Partial<TeamStatScalesRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/team-stat-scales/${id}/unfreeze`,
      TeamStatScalesRecord.createNew,
      record
    )
  }

  touchAllTeamStatScales() {
    return this.callPostEndpoint('/admin/team-stat-scales/touch-all', {})
  }

  touchOneTeamStatScaleById(id: string) {
    return this.callPostEndpoint(`/admin/team-stat-scales/${id}/touch`, {})
  }
  //#endregion <<< Team Stat Scales <<<

  //#region >>> Gaming Gears >>>
  getAllGamingGears() {
    return this.callGetItemsEndpoint(
      `/admin/gaming-gears`,
      GamingGearRecord.createNew
    )
  }

  getOneGamingGearById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/gaming-gears/${id}`,
      GamingGearRecord.createNew
    )
  }

  createOneGamingGear(record: Partial<GamingGearRecord>) {
    return this.callPostItemEndpoint(
      `/admin/gaming-gears`,
      GamingGearRecord.createNew,
      record
    )
  }

  updateOneGamingGear(id: string, record: Partial<GamingGearRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/gaming-gears/${id}`,
      GamingGearRecord.createNew,
      record
    )
  }

  deleteOneGamingGear(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/gaming-gears/${id}`,
      GamingGearRecord.createNew
    )
  }
  //#endregion <<< Gaming Gears <<<
  //#region >>> Suport Functions >>>
  getAllNearestMatches() {
    return this.callGetItemsEndpoint(
      `/admin/nearest-matches`,
      NearestMatchRecord.createNew
    )
  }

  getOneNearestMatchById(id: string) {
    return this.callGetItemEndpoint(
      `/admin/nearest-matches/${id}`,
      NearestMatchRecord.createNew
    )
  }

  createOneNearestMatch(record: Partial<NearestMatchRecord>) {
    return this.callPostItemEndpoint(
      `/admin/nearest-matches`,
      NearestMatchRecord.createNew,
      record
    )
  }

  updateOneNearestMatch(id: string, record: Partial<NearestMatchRecord>) {
    return this.callPatchItemEndpoint(
      `/admin/nearest-matches/${id}`,
      NearestMatchRecord.createNew,
      record
    )
  }

  deleteOneNearestMatch(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin/nearest-matches/${id}`,
      NearestMatchRecord.createNew
    )
  }
  //#endregion <<< Gaming Gears <<<
  //#region >>> AzureBlob >>>>
  getAllAzureBlobsByNameAndPrefix(name:string, prefix:string) {
    return this.callGetItemsEndpoint(
      `/admin/images/blobs?name=${name}&prefix=${prefix}`,
      AzureBlobRecord.createNew
    )
  }
  //#endregion <<< AzureBlob <<<
  //#region >>> AzureDirectory >>>>
  getAllAzureDirectoriesByNameAndPrefix(name:string, prefix:string, withSubDirs:boolean) {
    return this.callGetItemsEndpoint(
      `/admin/images/directories?name=${name}&prefix=${prefix}&withSubDirs=${withSubDirs}`,
      AzureDiretcoryRecord.createNew
    )
  }
  //#endregion <<< AzureDirectory <<<
  //#region >>> Datasource >>>
  getAllDatasources() {
    return this.callGetItemsEndpoint(
      `/admin//datasources`,
      DatasourceRecord.createNew
    )
  }

  getOneDatasourceById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//datasources/${id}`,
      DatasourceRecord.createNew
    )
  }

  createOneDatasource(record: Partial<DatasourceRecord>) {
    return this.callPostItemEndpoint(
      `/admin//datasources`,
      DatasourceRecord.createNew,
      record
    )
  }

  updateOneDatasource(id: string, record: Partial<DatasourceRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//datasources/${id}`,
      DatasourceRecord.createNew,
      record
    )
  }

  deleteOneDatasource(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//datasources/${id}`,
      DatasourceRecord.createNew
    )
  }
  //#endregion <<< Datasource <<<
  //#region >>> TeamOrganizationRecord >>>
  getAllTeamOrganizations() {
    return this.callGetItemsEndpoint(
      `/admin//team-organizations`,
      TeamOrganizationRecord.createNew
    )
  }

  getOneTeamOrganizationById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//team-organizations/${id}`,
      TeamOrganizationRecord.createNew
    )
  }

  createOneTeamOrganization(record: Partial<TeamOrganizationRecord>) {
    return this.callPostItemEndpoint(
      `/admin//team-organizations`,
      TeamOrganizationRecord.createNew,
      record
    )
  }

  updateOneTeamOrganization(id: string, record: Partial<TeamOrganizationRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//team-organizations/${id}`,
      TeamOrganizationRecord.createNew,
      record
    )
  }

  deleteOneTeamOrganization(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//sportsman-datasource-identifiers/${id}`,
      TeamOrganizationRecord.createNew
    )
  }
  //#endregion <<< TeamOrganizationRecord <<<
  //#region >>> SportsmanDatasourceIdentifier >>>
  getAllSportsmanDatasourceIdentifiers() {
    return this.callGetItemsEndpoint(
      `/admin//sportsman-datasource-identifiers`,
      SportsmanDatasourceIdentifierRecord.createNew
    )
  }

  getMoreSportsmanDatasourceIdentifiersBySportsmanId(sportsmanId:string) {
    return this.callGetItemsEndpoint(
      `/admin/sports/-/sportsmen/${sportsmanId}/datasource-identifiers`,
      SportsmanDatasourceIdentifierRecord.createNew
    )
  }

  getOneSportsmanDatasourceIdentifierById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//sportsman-datasource-identifiers/${id}`,
      SportsmanDatasourceIdentifierRecord.createNew
    )
  }

  createOneSportsmanDatasourceIdentifier(record: Partial<SportsmanDatasourceIdentifierRecord>) {
    return this.callPostItemEndpoint(
      `/admin//sportsman-datasource-identifiers`,
      SportsmanDatasourceIdentifierRecord.createNew,
      record
    )
  }

  updateOneSportsmanDatasourceIdentifier(id: string, record: Partial<SportsmanDatasourceIdentifierRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//sportsman-datasource-identifiers/${id}`,
      SportsmanDatasourceIdentifierRecord.createNew,
      record
    )
  }

  deleteOneSportsmanDatasourceIdentifier(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//sportsman-datasource-identifiers/${id}`,
      SportsmanDatasourceIdentifierRecord.createNew
    )
  }
  //#endregion <<< SportsmanDatasourceIdentifier <<<
  //#region >>> TeamDatasourceIdentifier >>>
  getAllTeamDatasourceIdentifiers() {
    return this.callGetItemsEndpoint(
      `/admin//team-datasource-identifiers`,
      TeamDatasourceIdentifierRecord.createNew
    )
  }

  getMoreTeamDatasourceIdentifiersByTeamId(teamId: string) {
    return this.callGetItemsEndpoint(
      `/admin/sports/-/teams/${teamId}/datasource-identifiers`,
      TeamDatasourceIdentifierRecord.createNew
    )
  }

  getOneTeamDatasourceIdentifierById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//team-datasource-identifiers/${id}`,
      TeamDatasourceIdentifierRecord.createNew
    )
  }

  createOneTeamDatasourceIdentifier(record: Partial<TeamDatasourceIdentifierRecord>) {
    return this.callPostItemEndpoint(
      `/admin//team-datasource-identifiers`,
      TeamDatasourceIdentifierRecord.createNew,
      record
    )
  }

  updateOneTeamDatasourceIdentifier(id: string, record: Partial<TeamDatasourceIdentifierRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//team-datasource-identifiers/${id}`,
      TeamDatasourceIdentifierRecord.createNew,
      record
    )
  }

  deleteOneTeamDatasourceIdentifier(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//team-datasource-identifiers/${id}`,
      TeamDatasourceIdentifierRecord.createNew
    )
  }
  //#endregion <<< TeamDatasourceIdentifier <<<
  //#region >>> TeamOrganizationDatasourceIdentifier >>>
  getAllTeamOrganizationDatasourceIdentifiers() {
    return this.callGetItemsEndpoint(
      `/admin//team-organization-datasource-identifiers`,
      TeamOrganizationDatasourceIdentifierRecord.createNew
    )
  }

  gwtMoreTeamOrganizationDatasourceIdentifiersByTeamOrgId(teamOrgId:string) {
    return this.callGetItemsEndpoint(
      `/admin/team-organizations/${teamOrgId}/datasource-identifiers`,
      TeamOrganizationDatasourceIdentifierRecord.createNew
    )
  }

  getOneTeamOrganizationDatasourceIdentifierById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//team-organization-datasource-identifiers/${id}`,
      TeamOrganizationDatasourceIdentifierRecord.createNew
    )
  }

  createOneTeamOrganizationDatasourceIdentifier(record: Partial<TeamOrganizationDatasourceIdentifierRecord>) {
    return this.callPostItemEndpoint(
      `/admin//team-organization-datasource-identifiers`,
      TeamOrganizationDatasourceIdentifierRecord.createNew,
      record
    )
  }

  updateOneTeamOrganizationDatasourceIdentifier(id: string, record: Partial<TeamOrganizationDatasourceIdentifierRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//team-organization-datasource-identifiers/${id}`,
      TeamOrganizationDatasourceIdentifierRecord.createNew,
      record
    )
  }

  deleteOneTeamOrganizationDatasourceIdentifier(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//team-organization-datasource-identifiers/${id}`,
      TournamentDatasourceIdentifierRecord.createNew
    )
  }
  //#endregion <<< TeamDatasourceIdentifier <<<
  //#region >>> TournamentDatasourceIdentifier >>>
  getAllTournamentDatasourceIdentifiers() {
    return this.callGetItemsEndpoint(
      `/admin//tournament-datasource-identifiers`,
      TournamentDatasourceIdentifierRecord.createNew
    )
  }

  getMoreTournamentDatasourceIdentifiersByTournamentId(tournamentId:string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/${tournamentId}/datasource-identifiers`,
      TournamentDatasourceIdentifierRecord.createNew
    )
  }

  getOneTournamentDatasourceIdentifierById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//tournament-datasource-identifiers/${id}`,
      TournamentDatasourceIdentifierRecord.createNew
    )
  }

  createOneTournamentDatasourceIdentifier(record: Partial<TournamentDatasourceIdentifierRecord>) {
    return this.callPostItemEndpoint(
      `/admin//tournament-datasource-identifiers`,
      TournamentDatasourceIdentifierRecord.createNew,
      record
    )
  }

  updateOneTournamentDatasourceIdentifier(id: string, record: Partial<TournamentDatasourceIdentifierRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//tournament-datasource-identifiers/${id}`,
      TournamentDatasourceIdentifierRecord.createNew,
      record
    )
  }

  deleteOneTournamentDatasourceIdentifier(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//tournament-datasource-identifiers/${id}`,
      TournamentDatasourceIdentifierRecord.createNew
    )
  }
  //#endregion <<< TournamentDatasourceIdentifier <<<
  //#region >>> TournamentStageDatasourceIdentifier >>>
  getAllTournamenStagetDatasourceIdentifiers() {
    return this.callGetItemsEndpoint(
      `/admin//tournament-stage-datasource-identifiers`,
      TournamentStageDatasourceIdentifierRecord.createNew
    )
  }

  getMoreTournamentStageDatasourceIdentifiersByTournamentStageId(tournamentStageId:string){
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/stages/${tournamentStageId}/datasource-identifiers`,
      TournamentStageDatasourceIdentifierRecord.createNew
    )
  }

  getOneTournamentStageDatasourceIdentifierById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//tournament-stage-datasource-identifiers/${id}`,
      TournamentStageDatasourceIdentifierRecord.createNew
    )
  }

  createOneTournamentStageDatasourceIdentifier(record: Partial<TournamentStageDatasourceIdentifierRecord>) {
    return this.callPostItemEndpoint(
      `/admin//tournament-stage-datasource-identifiers`,
      TournamentStageDatasourceIdentifierRecord.createNew,
      record
    )
  }

  updateOneTournamentStageDatasourceIdentifier(id: string, record: Partial<TournamentStageDatasourceIdentifierRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//tournament-stage-datasource-identifiers/${id}`,
      TournamentStageDatasourceIdentifierRecord.createNew,
      record
    )
  }

  deleteOneTournamentStageDatasourceIdentifier(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//tournament-stage-datasource-identifiers/${id}`,
      TournamentStageDatasourceIdentifierRecord.createNew
    )
  }
  //#endregion <<< TournamentStageDatasourceIdentifier <<<
  //#region >>> MatchDatasourceIdentifier >>>
  getAllMatchDatasourceIdentifiers() {
    return this.callGetItemsEndpoint(
      `/admin//match-datasource-identifiers`,
      MatchDatasourceIdentifierRecord.createNew
    )
  }

  getMoreMatchDatasourceIdentifiersByMatchId(matchId:string) {
    return this.callGetItemsEndpoint(
      `/admin/tournaments/-/matches/${matchId}/datasource-identifiers`,
      MatchDatasourceIdentifierRecord.createNew
    )
  }

  getOneMatchDatasourceIdentifierById(id: string) {
    return this.callGetItemEndpoint(
      `/admin//match-datasource-identifiers/${id}`,
      MatchDatasourceIdentifierRecord.createNew
    )
  }

  createOneMatchDatasourceIdentifier(record: Partial<MatchDatasourceIdentifierRecord>) {
    return this.callPostItemEndpoint(
      `/admin//match-datasource-identifiers`,
      MatchDatasourceIdentifierRecord.createNew,
      record
    )
  }

  updateOneMatchDatasourceIdentifier(id: string, record: Partial<MatchDatasourceIdentifierRecord>) {
    return this.callPatchItemEndpoint(
      `/admin//match-datasource-identifiers/${id}`,
      MatchDatasourceIdentifierRecord.createNew,
      record
    )
  }

  deleteOneMatchDatasourceIdentifier(id: string) {
    return this.callDeleteItemEndpoint(
      `/admin//match-datasource-identifiers/${id}`,
      MatchDatasourceIdentifierRecord.createNew
    )
  }
  //#endregion <<< MatchDatasourceIdentifier <<<
  //#region >>> Suport Functions >>>

  private async callGetItemsEndpoint<TElement extends BaseRecord>(
    urlSuffix: string,
    factory: RecordFactory<TElement>
  ) {
    const jsData = await this.callGetEndpoint(urlSuffix)
    const sourceItems = jsData?.['items'] as any[]

    return sourceItems
      ?.filter((x) => x) // necheme NULLy
      ?.map((x) => factory(this.db, x)) // chceme instance tridy TElement
  }

  private async callGetItemEndpoint<TElement extends BaseRecord>(
    urlSuffix: string,
    factory: RecordFactory<TElement>
  ) {
    const jsData = await this.callGetEndpoint(urlSuffix)
    const sourceItem = jsData?.['item']

    return sourceItem ? factory(this.db, sourceItem) : null
  }

  private callGetEndpoint(urlSuffix: string) {
    return this.callApiEndpoint(urlSuffix, 'GET')
  }

  private async callDeleteItemEndpoint<TElement extends BaseRecord>(
    urlSuffix: string,
    factory: RecordFactory<TElement>,
    data?: any
  ) {
    const jsData = await this.callDeleteEndpoint(urlSuffix, data)
    const sourceItem = jsData?.['item']
    if (sourceItem?.['deleted_at']) {
      // record is deleted
      return null
    }

    return sourceItem ? factory(this.db, sourceItem) : null
  }

  private callDeleteEndpoint(urlSuffix: string, data?: any) {
    return this.callApiEndpoint(urlSuffix, 'DELETE', { data: data })
  }

  private async callPatchItemEndpoint<TElement extends BaseRecord>(
    urlSuffix: string,
    factory: RecordFactory<TElement>,
    data: any
  ) {
    const jsData = await this.callPatchEndpoint(urlSuffix, data)
    const sourceItem = jsData?.['item']

    return sourceItem ? factory(this.db, sourceItem) : null
  }

  private callPatchEndpoint(urlSuffix: string, data: any) {
    return this.callApiEndpoint(urlSuffix, 'PATCH', { data: data })
  }

  private async callPostItemEndpoint<TElement extends BaseRecord>(
    urlSuffix: string,
    factory: RecordFactory<TElement>,
    data: any
  ) {
    const jsData = await this.callPostEndpoint(urlSuffix, data)
    const sourceItem = jsData?.['item']

    return sourceItem ? factory(this.db, sourceItem) : null
  }

  private callPostEndpoint(urlSuffix: string, data: any) {
    return this.callApiEndpoint(urlSuffix, 'POST', { data: data })
  }

  private async callApiEndpoint(
    urlSuffix: string,
    method: Method,
    options?: { data?: any }
  ) {
    this.dispatchOnBeforeCallEndpoint({
      method: method,
      urlSuffix: urlSuffix,
    })

    const axiosConfig: AxiosRequestConfig = {
      method: method,
      url: `${config.url}${urlSuffix}`,
      headers: {
        Authorization: `Bearer ${Token.get()}`,
      },
      data: options?.data,
    }

    const response = await axios(axiosConfig)
    return response.data
  }

  // >>> onBeforeCallEndpoint >>>
  #onBeforeCallEndpoint: EndpointCallListener[] = []
  get onBeforeCallEndpoint() {
    return this.#onBeforeCallEndpoint
  }
  private dispatchOnBeforeCallEndpoint(args: {
    method: string
    urlSuffix: string
  }) {
    this.onBeforeCallEndpoint?.forEach((x) => x?.(args))
  }
  // <<< onBeforeCallEndpoint <<<
}

export type EndpointCallListener = (args: {
  method: string
  urlSuffix: string
}) => void

type RecordFactory<TRecord extends BaseRecord> = (
  db: BaseRootRepository | null | undefined,
  jsData: any
) => TRecord
