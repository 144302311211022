import React, { ComponentProps, useContext, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormDropdown from 'components/utils/FormDropdown'
import FormItem from 'components/utils/FormItem'
import FormDatePicker from 'components/utils/Form/Picker/DatePicker'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
import Image from 'components/utils/Image/Image'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { SportsmanRecord } from 'data/SportsmanRecord'
import { AppContext } from 'App'
import { SportsmanContext } from 'components/Sportsmen/__Sportsmen.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'
import { SportsmanBiographyRecord } from 'data/SportsmanBiographyRecord'
import FormCheck from 'components/utils/FormCheck'
import { StarIcon } from 'styles/fontAwesome'
import ImageGalleryPickerParent from 'components/utils/ImageGalleryPicker/ImageGalleryPickerParent'
import { SportContext } from 'components/Sports/__Sports.context'
import { SportRecord } from 'data/SportRecord'

const SubmitKeys = [
  'first_name',
  'last_name',
  'full_name',
  'nickname',
  'team_position',
  'birthdate',
  'country',
  'binding_id',
  'photo_url',
  'photo_profile',
  'sport_id',
  'is_sazka_eleague'
] as Array<keyof SportsmanRecord>

type ValidationKeys = Record<
  keyof Pick<SportsmanRecord, 'nickname' | 'binding_id' | 'sport_id'>,
  any
>

const SportmanForm: React.FC<FormHelper.Props<SportsmanRecord>> = (p) => {
  const {
    db: a_db,
    alert: a_alert,
    history: a_history,
    modal
  } = useContext(AppContext)
  const { params: s_params } = useContext(SportsmanContext)
  const { params: sp_params } = useContext(SportContext)

  const [form, setForm] = useState<{
    data?: SportsmanRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: a_alert,
      texts: {
        form: 'Sportsman',
      },
    },
    history: a_history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      sport_id: form.data?.sport?.id,
      sport_text: form.data?.sport?.full_name,
    },

    onSubmit: async (values) => {
      if (!a_db?.sportsmen) return

      try {
        const data = await FormHelper.submitChanges<SportsmanRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new SportsmanRecord(), values ?? {}),
          repository: a_db.sportsmen,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          a_history?.replace(
            RoutingHelper.getSportsmanDetailUrl({
              sport: sp_params?.sport_id ?? RoutingHelper.NewRecordSymbol,
              sportsman: data.id,
              //sportsmanBiographies : dataBio.id
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      nickname: Yup.string()
        .nullable()
        .required('Nickname is a required field'),
      binding_id: Yup.string()
        .nullable()
        .required('Binding-ID is a required field'),
      sport_id: Yup.string().nullable().required('Sport is a required field'),
    }),
  })

  const approve = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.approve({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt,
    })
  }

  const unapprove = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.unapprove({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt,
    })
  }

  const publish = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.publish({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt
    })
  }

  const unpublish = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.unpublish({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt
    })
  }

  const freeze = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.freeze({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.unfreeze({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.delete_({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!a_db?.sportsmen) return

    await FormHelper.touch({
      data: Object.assign(new SportsmanRecord(), formik.values ?? {}),
      repository: a_db.sportsmen,
      optional: opt,
    })
  }

  const [sport, setSport] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.sport_id ?? '',
      text: formik.values.sport_text,
    },
    loaded: false,
  })

  const getSportItem = (record: SportRecord): Item => ({
    id: record.id ?? '',
    text: record.full_name,
  })

  const _setSports = async () => {
    const _data = await DataHelper.getAllSports(a_db!)

    const _mData = _data?.map((s) => getSportItem(s))

    setSport((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _mData,
    }))

    return _mData
  }

  const _setSport = (item: Item) => {
    formik.setFieldValue('sport_id' as keyof SportsmanRecord, item.id)

    setSport((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleSportChange = (item: Item) => {
    _setSport(item)
  }

  useAsyncEffect(async () => {
    const _sports = await _setSports()

    if (form.state === 'new' && s_params?.sport_id) {
      const _findItem = _sports?.find((d) => d.id === s_params?.sport_id)

      _findItem && _setSport(_findItem)
    }
  }, [])

  //#region Form Components
  const _Sport = (
    <FormItem
      label={<Form.Label>Sport</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'sport_id' as keyof SportsmanRecord}
            items={sport.data ?? []}
            onSelect={handleSportChange}
            value={sport.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.sport_id}
            disabled={form.state === 'existing' || p.locked?.value}
            loading={!sport.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.sport_id &&
                  RoutingHelper.getSportDetailUrl({
                    sport: formik.values.sport_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.sport_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _FirstName = (
    <FormItem
      label={<Form.Label>First Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'first_name' as keyof SportsmanRecord}
            value={formik.values.first_name ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.first_name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _LastName = (
    <FormItem
      label={<Form.Label>Last Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'last_name' as keyof SportsmanRecord}
            value={formik.values.last_name ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.last_name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _FullName = (
    <FormItem
      label={<Form.Label>Full Name</Form.Label>}
      input={
        <Form.Control
          name={'full_name' as keyof SportsmanRecord}
          value={formik.values.full_name ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Nickname = (
    <FormItem
      label={<Form.Label>Nickname</Form.Label>}
      input={
        <>
          <Form.Control
            name={'nickname' as keyof SportsmanRecord}
            value={formik.values.nickname ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.nickname}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.nickname}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _BirthDate = (
    <FormItem
      label={<Form.Label>Birthdate</Form.Label>}
      input={
        <>
          <FormDatePicker
            name={'starts_at_edit' as keyof SportsmanRecord}
            value={formik.values.birthdate ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue('birthdate' as keyof SportsmanRecord, date)
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _TeamPosition = (
    <FormItem
      label={<Form.Label>Team position</Form.Label>}
      input={
        <Form.Control
          name={'team_position' as keyof SportsmanRecord}
          value={formik.values.team_position ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Country = (
    <FormItem
      label={<Form.Label>Country</Form.Label>}
      input={
        <Form.Control
          name={'country' as keyof SportsmanRecord}
          value={formik.values.country ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _BindingId = (
    <FormItem
      label={<Form.Label>Binding-ID</Form.Label>}
      input={
        <>
          <Form.Control
            name={'binding_id' as keyof SportsmanRecord}
            value={formik.values.binding_id ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.binding_id}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.binding_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Image = (
    <Image
      inputName={'photo_url' as keyof SportsmanRecord}
      imageUrl={formik.values.photo_url ?? ''}
      isLocked={p.locked?.value}
      labelName='Photo'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'sportsmen'}
                actualImageString={formik.values.photo_url ?? ''}
                // useMultipleSelect={{
                //   divider: '|',
                // }}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'photo_url' as keyof SportsmanRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('photo_url' as keyof SportsmanRecord, val)
      }
    />
  )

  const _ImageProfile = (
    <Image
      inputName={'photo_profile' as keyof SportsmanRecord}
      imageUrl={formik.values.photo_profile ?? ''}
      isLocked={p.locked?.value}
      labelName='Profile Photo'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'sportsmen'}
                actualImageString={formik.values.photo_profile ?? ''}
                // useMultipleSelect={{
                //   divider: '|',
                // }}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'photo_profile' as keyof SportsmanRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('photo_profile' as keyof SportsmanRecord, val)
      }
    />
  )

  const _PopularIcon = (
    <FormCheck
      name={'is_popular' as keyof SportsmanRecord}
      value={formik.values.is_popular ?? false}
      checked={formik.values.is_popular ?? false}
      offset={{ direction: 'left'}}
      title={'Popular'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue(
          'is_popular' as keyof SportsmanRecord,
          true
        )
      }}
      uncheckedCallback={() => {
        formik.setFieldValue(
          'popularity_order' as keyof SportsmanRecord,
          ''
        )
        formik.setFieldValue(
          'is_popular' as keyof SportsmanRecord,
          false
        )

      }}
      disabled={p.locked?.value}
    />
  )

  const _PopularOrder = (
    <FormItem
      label={<Form.Label>Popularity order</Form.Label>}
      input={
        <Form.Control
          name={'popularity_order' as keyof SportsmanRecord}
          value={formik.values.popularity_order ?? ''}
          onChange={formik.handleChange}
          readOnly={!_PopularIcon.props.checked}
        />
      }
    />
  )

  const _IsSazkaEvent = (
    <FormCheck
      name={'is_sazka_eleague' as keyof SportsmanRecord}
      value={formik.values.is_sazka_eleague ?? false}
      checked={formik.values.is_sazka_eleague ?? false}
      offset={{ direction: 'left'}}
      title={'Sazka Event player'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue(
          'is_sazka_eleague' as keyof SportsmanRecord,
          true
        )
      }}
      uncheckedCallback={() => {
        formik.setFieldValue(
          'is_sazka_eleague' as keyof SportsmanRecord,
          false
        )

      }}
      disabled={p.locked?.value}
    />
  )
  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useApprove: {
        defaultValue: !!p.data?.data_approved_at,
      },
      usePublish: {
        defaultValue: !!p.data?.published_at,
      },
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: false,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          approve: approve,
          unapprove: unapprove,
          publish: publish,
          unpublish: unpublish,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col lg={5}>
              <Form.Row>
              {_Image}
              </Form.Row>
              <ItemWrapper />
              <Form.Row>
              {_ImageProfile}
              </Form.Row>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <Form.Row>
                <Col as={ItemWrapper}>
                {_PopularIcon}
                </Col >
                <Col>
                {_PopularOrder}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_FirstName}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_LastName}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_FullName}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_BirthDate}</Col>
              </Form.Row>

              <ItemWrapper />

              <Form.Row>
                <Col as={ItemWrapper}>{_Nickname}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_TeamPosition}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Country}</Col>
              </Form.Row>

              <ItemWrapper />

              <Form.Row>
                <Col as={ItemWrapper}>{_Sport}</Col>
              </Form.Row>

              <ItemWrapper />

              <Form.Row>
                <Col as={ItemWrapper}>{_BindingId}</Col>
              </Form.Row>

              <ItemWrapper />

              <Form.Row>
                <Col as={ItemWrapper}>{_IsSazkaEvent}</Col>
              </Form.Row>
            </Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default SportmanForm
