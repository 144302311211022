import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"

export class SportRecord<TJsonData extends SportRecord.CtorJsonData = SportRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  ident?: string
  full_name?: string
  short_name?: string
  logo_url?: string
  data_frozen_at?: Date | null
  data_approved_at?: Date | null
  published_at?: Date | null

  /// >>> computed_values >>>
  get isApproved() {
    return !!this.data_approved_at
  }

  get isFrozen() {
    return !!this.data_frozen_at
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportRecord.CtorJsonData) {
    return new SportRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.ident = jsonData?.ident
    this.full_name = jsonData?.full_name
    this.short_name = jsonData?.short_name
    this.logo_url = jsonData?.logo_url
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.data_approved_at = DateTime.parseDateOrNull(jsonData?.data_approved_at)
    this.published_at = DateTime.parseDateOrNull(jsonData?.published_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      ident: this.ident,
      full_name: this.full_name,
      short_name: this.short_name,
      logo_url: this.logo_url,
      data_frozen_at: this.data_frozen_at?.toISOString(),
      data_approved_at: this.data_approved_at?.toISOString(),
      published_at: this.published_at?.toISOString()
    }) as TJsonData
  }
}

export module SportRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    ident: string
    full_name: string
    short_name: string
    logo_url: string
    data_frozen_at?: string
    data_approved_at?: string
    published_at?: string
  }

  export class Repository extends RecordRepository<SportRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneSport(record)
    }

    protected loadById(id: string) {
      return this.getApi().getOneSportById(id)
    }

    protected loadAll() {
      return this.getApi().getAllSports()
    }

    protected updateById(id: string, patch: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneSport(id, patch)
    }

    protected deleteById(id: string): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneSport(id)
    }

    protected approveById(id: string, patch: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.approveOneSportById(id, patch)
    }

    protected unapproveById(id: string, patch: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unapproveOneSportById(id, patch)
    }

    protected publishById(id: string, patch: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.publishOneSportById(id, patch)
    }
    protected unpublishById(id: string, patch: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unpublishOneSportById(id, patch)
    }

    protected freezeById(id: string, patch: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneSportById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<SportRecord<CtorJsonData>>): Promise<SportRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneSportById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllSports()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneSportById(id)
    }
  }
}
