import { type } from "os";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { DateTime } from "utils/dateTime";

export class MatchDatasourceIdentifierRecord<TJsonData extends MatchDatasourceIdentifierRecord.CtorJsonData = MatchDatasourceIdentifierRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  match_id?: string
  identifier?: string
  identifier_kind?: string
  is_enabled?: boolean | null
  admin_note?: string
  original_data?: string
  is_primary?: boolean | null
  last_data_change_at?: Date | null

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:MatchDatasourceIdentifierRecord.CtorJsonData) {
    return new MatchDatasourceIdentifierRecord(db, jsonData)
  }

  constructor (db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if(jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.match_id = jsonData?.match_id
    this.identifier = jsonData?.identifier
    this.identifier_kind = jsonData?.identifier_kind
    this.is_enabled = jsonData?.is_enabled
    this.admin_note = jsonData?.admin_note
    this.original_data = jsonData?.original_data
    this.is_primary = jsonData?.is_primary
    this.last_data_change_at = DateTime.parseDateOrNull(jsonData?.last_data_change_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      match_id: this.match_id,
      identifier: this.identifier,
      identifier_kind: this.identifier_kind,
      is_enabled: this.is_enabled,
      admin_note: this.admin_note,
      original_data: this.original_data,
      is_primary: this.is_primary,
      last_data_change_at: this.last_data_change_at?.toISOString()
    }) as TJsonData
  }
}

export module MatchDatasourceIdentifierRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    match_id?: string
    identifier?: string
    identifier_kind?: string
    is_enabled?: boolean | undefined
    priority?: number
    admin_note?: string
    original_data?: string
    is_primary?: boolean | null
    last_data_change_at?: string
  }

  export class Repository extends RecordRepository<MatchDatasourceIdentifierRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by match_id >>>
    #getByMatchId:RecordRepository.NestingGetFieldFlags = {}

    getByMatchId(matchId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByMatchId, matchId, options, {
        peek: () => this.peekByMatchId(matchId),
        load: () => this.loadByMatchId(matchId)
      })
    }

    peekByMatchId(matchId:string) {
      return this.peekAll(x => x?.match_id == matchId)
    }

    protected loadByMatchId(matchId:string) {
      return this.getApi().getMoreMatchDatasourceIdentifiersByMatchId(matchId)
    }
    /// <<< by match_id <<<

    create(record: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneMatchDatasourceIdentifier(record)
    }

    protected loadAll() {
      return this.getApi().getAllMatchDatasourceIdentifiers()
    }

    protected loadById(id: string): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      return this.getApi().getOneMatchDatasourceIdentifierById(id)
    }
    protected updateById(id: string, patch: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneMatchDatasourceIdentifier(id, patch)
    }
    protected deleteById(id: string): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApi()
      return api.deleteOneMatchDatasourceIdentifier(id)
    }
    protected approveById(id: string, patch: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<MatchDatasourceIdentifierRecord<CtorJsonData>>): Promise<MatchDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }

  }
}
