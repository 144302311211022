import React, { ComponentProps, useEffect, useState, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import FormDropdown from 'components/utils/FormDropdown'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { TournamentTeamRankRecord } from 'data/TournamentTeamRankRecord'
import { AppContext } from 'App'
import { TeamRankContext } from './__TeamRanks.context'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'

const SubmitKeys = [
  'rank_from',
  'rank_to',
  'tournament_id',
  'team_id',
] as Array<keyof TournamentTeamRankRecord>

type ValidationKeys = Record<
  keyof Pick<TournamentTeamRankRecord, 'team_id'>,
  any
>

const TeamRankForm: React.FC<FormHelper.Props<TournamentTeamRankRecord>> = (
  p
) => {
  const { db, alert, history } = useContext(AppContext)
  const { params: tr_params } = useContext(TeamRankContext)
  const { params: t_params } = useContext(TournamentContext)

  const [form, setForm] = useState<{
    data?: TournamentTeamRankRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Team rank',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      tournament_id: form.data?.tournament?.id ?? tr_params?.tournament_id,

      team_id: form.data?.team?.id,
      team_text: form.data?.team?.full_name,
    },

    onSubmit: async (values) => {
      if (!db?.tournamentTeamRanks) return

      try {
        const data = await FormHelper.submitChanges<TournamentTeamRankRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new TournamentTeamRankRecord(), values ?? {}),
          repository: db.tournamentTeamRanks,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getTeamRankDetailUrl({
              tournament:
                t_params?.tournament_id ?? RoutingHelper.NewRecordSymbol,
              teamRank: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      team_id: Yup.string().required('Team is a required field'),
    }),
  })

  const freeze = async () => {
    if (!db?.tournamentTeamRanks) return

    await FormHelper.freeze({
      data: Object.assign(new TournamentTeamRankRecord(), formik.values ?? {}),
      repository: db.tournamentTeamRanks,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.tournamentTeamRanks) return

    await FormHelper.unfreeze({
      data: Object.assign(new TournamentTeamRankRecord(), formik.values ?? {}),
      repository: db.tournamentTeamRanks,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.tournamentTeamRanks) return

    await FormHelper.delete_({
      data: Object.assign(new TournamentTeamRankRecord(), formik.values ?? {}),
      repository: db.tournamentTeamRanks,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.tournamentTeamRanks) return

    await FormHelper.touch({
      data: Object.assign(new TournamentTeamRankRecord(), formik.values ?? {}),
      repository: db.tournamentTeamRanks,
      optional: opt,
    })
  }

  const [teams, setTeams] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team_id ?? '',
      text: formik.values.team_text,
    },
    loaded: false,
  })

  const _setTeams = async () => {
    const _data = await DataHelper.getTeamsByTournamentId(
      db!,
      form.data?.tournament?.id ?? tr_params?.tournament_id ?? '-'
    )

    setTeams((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.full_name ?? '' } as Item
      }),
    }))
  }

  const handleTeamSelect = (item: Item) => {
    formik.setFieldValue('team_id' as keyof TournamentTeamRankRecord, item.id)

    setTeams((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  useEffect(() => {
    _setTeams()
  }, [])

  //#region Form Components
  const _Team = (
    <FormItem
      label={<Form.Label>Team</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'team_id' as keyof TournamentTeamRankRecord}
            items={teams.data ?? []}
            onSelect={handleTeamSelect}
            value={teams.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.team_id}
            disabled={p.locked?.value}
            loading={!teams.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.team_id &&
                  RoutingHelper.getTeamDetailUrl({
                    sport: RoutingHelper.NewRecordSymbol,
                    team: formik.values.team_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.team_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Tournament = (
    <Form.Control
      name={'tournament_id' as keyof TournamentTeamRankRecord}
      value={formik.values.tournament_id}
      hidden
    />
  )

  const _RankFrom = (
    <FormItem
      label={<Form.Label>Rank From</Form.Label>}
      input={
        <Form.Control
          name={'rank_from' as keyof TournamentTeamRankRecord}
          type={'number'}
          value={formik.values.rank_from ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _RankTo = (
    <FormItem
      label={<Form.Label>Rank To</Form.Label>}
      input={
        <Form.Control
          name={'rank_to' as keyof TournamentTeamRankRecord}
          type={'number'}
          value={formik.values.rank_to ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )
  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: false,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          {/* ----------- Hidden Inputs Section ----------- */}
          {_Tournament}
          {/* --------------------------------------------- */}

          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col as={ItemWrapper}>{_Team}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_RankFrom}</Col>
            <Col as={ItemWrapper}>{_RankTo}</Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default TeamRankForm
