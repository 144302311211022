import React, { useState, useEffect, useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
// Components
import _Pagination from './_Pagination'
import { Dropdown, DropdownButton } from 'react-bootstrap'
// Additional
import { OnTablePageChangeListener } from 'components/utils/Table/_Table'
import { AppContext } from 'App'
import { Url } from 'utils/url'

const PaginationBottomWrapper = styled.div`
  display: flex;
  justify-content: center;

  > * {
    margin: 0px 9px;
  }
`

export type URLParams = { page?: number; size?: number }

const globals: {
  [id: string]: {
    size: number
  }
} = {}

const DEFAULT_PAGE_SIZE = 10

const URL_BUILD = (url: string, params: URLParams) =>
  `${url}?page=${params?.page ?? 1}&size=${params?.size ?? DEFAULT_PAGE_SIZE}`

interface Props {
  readonly disableChangeUrl?: boolean
}

const Pagination: React.FC<Props & { syncKey?: string }> = (p) => {
  const { history: a_history } = useContext(AppContext)

  interface Content {
    page?: { index?: number; size?: number }
    num?: { pages: number; records: number }
  }

  const urlParams = Url.parseAndMapParams<URLParams>(
    a_history?.location?.search
  )

  const [content, setContent] = useState<Content>({})

  const { url } = useRouteMatch()

  useEffect(() => {
    if (p.disableChangeUrl) return

    if (!urlParams.page || !urlParams.size) {
      a_history?.replace(URL_BUILD(url, urlParams))
    }
  }, [a_history?.location])

  useEffect(() => {
    const handlePageResponse = (e: CustomEvent): void => {
      const _data = e.detail as OnTablePageChangeListener.PageChangeEventArgs

      if (!_data.response) return

      let _content: Content = {
        num: {
          pages: _data.response?.pages?.length ?? 0,
          records: _data.response?.records,
        },
      }

      switch (_data.response?.type) {
        case OnTablePageChangeListener.RequestType.SIZE: {
          const size = _data.response?.pages?.page?.size ?? DEFAULT_PAGE_SIZE

          // @ts-ignore
          globals[p.syncKey ?? ''] = {
            size
          }

          _content = {
            ..._content,
            page: {
              size,
            },
          }

          break
        }
        case OnTablePageChangeListener.RequestType.PAGE: {
          _content = {
            ..._content,
            page: {
              index: _data.response?.pages?.page?.index ?? 0,
            },
          }

          break
        }
      }

      setContent((prev) => ({ ...prev, ..._content }))
    }

    OnTablePageChangeListener.addAndRemove(
      handlePageResponse as EventListener,
      p.syncKey
    )
  }, [p.syncKey])

  const handleGoToPageRequest = (page: number) => {
    if (!p.disableChangeUrl) {
      urlParams.page &&
        a_history?.push(
          URL_BUILD(url, { page: page + 1, size: urlParams.size })
        )
    }

    OnTablePageChangeListener.dispatch(
      {
        request: {
          type: OnTablePageChangeListener.RequestType.PAGE,
          page: {
            index: page,
          },
        },
      },
      p.syncKey
    )
  }

  const handlePrevPageRequest = () => {
    if (!p.disableChangeUrl) {
      urlParams.page &&
        a_history?.push(
          URL_BUILD(url, {
            // @ts-ignore
            page: parseInt(urlParams.page) - 1,
            size: urlParams.size,
          })
        )
    }

    OnTablePageChangeListener.dispatch(
      {
        request: {
          type: OnTablePageChangeListener.RequestType.PREV,
        },
      },
      p.syncKey
    )
  }

  const handleNextPageRequest = () => {
    if (!p.disableChangeUrl) {
      urlParams.page &&
        a_history?.push(
          URL_BUILD(url, {
            // @ts-ignore
            page: parseInt(urlParams.page) + 1,
            size: urlParams.size,
          })
        )
    }

    OnTablePageChangeListener.dispatch(
      {
        request: {
          type: OnTablePageChangeListener.RequestType.NEXT,
        },
      },
      p.syncKey
    )
  }

  const handleChangeSizeRequest = (size: number) => {
    if (!p.disableChangeUrl) {
      urlParams.page &&
        a_history?.push(URL_BUILD(url, { page: urlParams.page, size }))
    }

    OnTablePageChangeListener.dispatch(
      {
        request: {
          type: OnTablePageChangeListener.RequestType.SIZE,
          page: { setSize: size },
        },
      },
      p.syncKey
    )
  }

  const paginationUrl = (page: string) =>
    URL_BUILD(url, { page: parseInt(page), size: urlParams.size })

  return (
    <PaginationBottomWrapper>
      {(content.num?.records ?? 0) > 5 && (
        <>
          <_Pagination
            gotoPageFn={handleGoToPageRequest}
            nextPageFn={handleNextPageRequest}
            previousPageFn={handlePrevPageRequest}
            pageIndex={content?.page?.index ?? 0}
            pageLength={content?.num?.pages ?? 0}
            syncKey={p.syncKey}
            urlHrefFn={p.disableChangeUrl ? undefined : paginationUrl}
          />
          <DropdownButton
            title={`Show ${globals[p.syncKey ?? ''].size ?? DEFAULT_PAGE_SIZE}`}
            variant={'outline-secondary'}
            drop={'up'}
          >
            {[5, 10, 25, 50].map((pageSize) => (
              <Dropdown.Item
                key={pageSize}
                onClick={() => handleChangeSizeRequest(pageSize)}

              >
                Show {pageSize}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </>
      )}
    </PaginationBottomWrapper>
  )
}

export default Pagination
