import React, { ComponentProps, useEffect, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
// Styles
import { InjectOverlayContentWrapper, TableWrapper } from './Table.styles'
import { Order, OrderItem } from './Table.styles'
// Components
import UITable from 'components/utils/Table/_Table'
import { LoadingSpinner } from 'components/utils/Loading'
import Warning from 'components/utils/Warning'
import Pagination from 'components/utils/Pagination/Pagination'
// Additional
import { Text } from 'static/text'
import { StyleProps } from './_Table.styles'
import { RecordsSetting } from './_Table.settings'
import { getUniqueId } from 'utils/common'
import { OnReloadTriggerListener } from 'components/utils/Reload.event'
import { OnReloadFilterListener } from 'components/utils/Filter/_Filter'
import { OnFilterChangeListener } from 'components/utils/Filter/_Filter'

interface Props<TRecord extends any>
  extends Pick<ComponentProps<typeof Pagination>, 'disableChangeUrl'> {
  readonly loadDataFn: () => Promise<Array<TRecord> | undefined>

  readonly settings: RecordsSetting<TRecord>
  readonly style?: StyleProps

  readonly reloadFrom?: Array<string>

  readonly onSelect?: {
    readonly value: TRecord
    readonly callback: (rowData: TRecord) => void
  }
}

const TableLoader = <T extends any>(p: Props<T>) => {
  const [content, setContent] = useState<{
    data?: Array<T>
    load: {
      isInProgress?: boolean
      isError?: boolean
      numFinished: number
    }
    syncKey: string
    selectedIdx?: number
  }>({ load: { numFinished: 0 }, syncKey: getUniqueId() })

  const loadAndSetData = async () => {
    try {
      const _data = await p.loadDataFn()

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false, numFinished: prev.load.numFinished + 1 },
        selectedIdx:
          p.onSelect &&
          // @ts-ignore
          _data?.findIndex((x) => x?.id == p.onSelect?.value?.id),
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: {
          isInProgress: true,
          isError: true,
          numFinished: prev.load.numFinished,
        },
      }))
    }
  }

  useEffect(() => {
    const handleReloadTrigger = async (
      from: OnReloadTriggerListener.Location
    ) => {
      setContent((prev) => ({
        ...prev,
        load: {
          isInProgress: true,
          numFinished: prev.load.numFinished,
        },
      }))

      await loadAndSetData()

      OnFilterChangeListener.dispatch(from, {
        options: { useForceFilter: true },
      })
      OnReloadFilterListener.dispatch(from)
    }

    p.reloadFrom?.forEach((r) => {
      OnReloadTriggerListener.addAndRemove({
        listener: () => handleReloadTrigger(r),
        from: r,
      })
    })
  }, [])

  useAsyncEffect(async () => {
    setContent((prev) => ({
      ...prev,
      load: {
        isInProgress: true,
        numFinished: prev.load.numFinished,
      },
    }))

    await loadAndSetData()
  }, [])

  const handleRowSelected = (idx: number) => {
    content.data && p.onSelect?.callback(content.data[idx])

    setContent((prev) => ({ ...prev, selectedIdx: idx }))
  }
  
  return (
    <_TableLoader
      table={{
        content: { columns: p.settings, data: content.data ?? [] },
        disableNoDataText: content.load.numFinished < 1,
        style: p.style,
        syncKey: content.syncKey,
        useRowSelect: p.onSelect && {
          selectCallback: handleRowSelected,
          selectedIdx: content.selectedIdx ?? -1,
        },
      }}
      pagination={{
        syncKey: content.syncKey,
        disableChangeUrl: p.disableChangeUrl,
      }}
      isLoading={content.load.isInProgress}
      isError={content.load.isError}
    />
  )
}

interface _Props {
  readonly table: ComponentProps<typeof UITable>
  readonly pagination: ComponentProps<typeof Pagination>

  readonly isLoading?: boolean
  readonly isError?: boolean
}

const _TableLoader: React.FC<_Props> = (p) => {
  const _InjectedOverlayContent = () => {
    if (p.isError) {
      return (
        <InjectOverlayContentWrapper>
          <Warning text={Text.NoServerData} />
        </InjectOverlayContentWrapper>
      )
    }
    if (p.isLoading) {
      return (
        <InjectOverlayContentWrapper>
          <LoadingSpinner />
        </InjectOverlayContentWrapper>
      )
    }

    return <></>
  }

  return (
    <Order style={{ minHeight: '100%' }}>
      {/* We need to render/prepare Pagination component first, because it has to be ready to process Table requests */}
      {/* Because of this, we only change the css flex order to keep it in the correct order */}
      {/* Pagination must be under the UITable component */}
      <OrderItem order={1}>
        <Pagination {...p.pagination} />
      </OrderItem>
      <OrderItem order={0} style={{ overflow: 'auto' }}>
        <TableWrapper
          pageHeightByRows={5}
          useScrollHide={p.isLoading || p.isError}
        >
          {_InjectedOverlayContent()}

          <UITable {...p.table} />
        </TableWrapper>
      </OrderItem>
    </Order>
  )
}

export default TableLoader
