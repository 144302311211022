import React, { useContext } from 'react'
import styled from 'styled-components'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import { AppContext } from 'App'
import { Form, InputGroup } from 'react-bootstrap'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 100%;

  overflow-x: hidden;
`

const Header = styled.div`
  position: absolute;
  right: 65px;
  top: 21px;

  font-weight: bold;
  font-size: 18px;
`

interface Props extends _Props {
  readonly header?: string
  readonly modal?: JSX.Element
}

const ModalButton: React.FC<Props> = (p) => {
  const { modal } = useContext(AppContext)

  const handleButtonClick = () => {
    modal?.open?.({
      content: (
        <Wrap>
          {p.header && <Header>{p.header}</Header>}
          {p.modal}
        </Wrap>
      ),
    })
  }

  return <_ModalButton {...p} buttonClickCallback={handleButtonClick} />
}

type FormControlType = {
  readonly value?: string

  readonly name: string
  readonly required?: boolean
  readonly isInvalid?: boolean
}

interface _Props {
  readonly form?: FormControlType

  readonly text?: string

  readonly append?: React.ReactNode

  readonly buttonClickCallback?: () => void
}

export const _ModalButton: React.FC<_Props> = (p) => (
  <>
    {p.form && (
      <Form.Control
        hidden
        readOnly
        name={p.form.name}
        value={p.form.value}
        isInvalid={p.form.isInvalid}
        required={p.form.required}
      />
    )}

    <InputGroup style={{ flex: 1 }}>
      <CTAButton theme={'white'} onClick={p.buttonClickCallback}>
        {p.text}
      </CTAButton>

      {p.append && (
        <InputGroup.Append style={{ order: 1 }}>{p.append}</InputGroup.Append>
      )}
    </InputGroup>
  </>
)

export default ModalButton
