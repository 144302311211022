//@ts-nocheck
import { AnyRecord } from "types/anyRecord";
import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { TeamRecord } from "./TeamRecord";
import { TournamentRecord } from "./TournamentRecord";
import { TournamentRoundRecord } from "./TournamentRoundRecord";

type WriteAnyRecordParams = { key: string | number, value: unknown }

export class MatchRecord<TJsonData extends MatchRecord.CtorJsonData = MatchRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  datasource_id?: string
  box?: number
  box_orig?: number
  binding_id?: string
  flag_for_nearest_matches?: boolean
  highlight_match?: boolean
  sport_id?: number
  team1_id?: string
  team2_id?: string
  team1_name?: string
  team2_name?: string
  team1_score?: number
  team2_score?: number
  team1_score_orig?: number
  team2_score_orig?: number
  starts_at?: Date | null
  starts_at_orig?: Date | null
  started_at?: Date | null
  started_at_orig?: Date | null
  finished_at?: Date | null
  finished_at_orig?: Date | null
  canceled_at?: Date | null
  canceled_at_orig?: Date | null
  team1_league_points?: number
  team2_league_points?: number
  team1_league_points_orig?: number
  team2_league_points_orig?: number
  youtube_code?: string
  match_text_summary?: string
  data_approved_at?: Date | null
  published_at?: Date | null
  data_frozen_at?: Date | null
  patch?: AnyRecord
  stats_config?: AnyRecord

  /// >>> team >>>
  #team:BaseRecord.RefField<TeamRecord> = {}
  get team() {
    return this.#team.object
  }
  getTeam() {
    return this.getObjectFromRefField(this.#team, "teams")
  }
  /// <<< team <<<

  private writeAnyRecord(property: keyof MatchRecord, params: WriteAnyRecordParams) {
    const { key, value } = params

    if (this[property] == null) {
      this[property] = {}
    }

    // if (value === '') {
    //   delete this[property][key]
    // }

    this[property][key] = value !== '' ? value : null
  }

  private writeStatsConfig(params: WriteAnyRecordParams) {
    // const { key, value } = params

    // if (this.stats_config == null) {
    //   this.stats_config = {}
    // }

    // this.stats_config[key] = value !== '' ? value : null

    this.writeAnyRecord('stats_config', params)
  }

  get ignore_league_points() {
    return !!this.stats_config?.["ignore_league_points"] as any
  }

  set ignore_league_points(value) {
    this.writeStatsConfig({ key: "ignore_league_points", value })
  }

  private writePatch(params: WriteAnyRecordParams) {
    // const { key, value } = params

    // if (this.patch == null) {
    //   this.patch = {}
    // }

    // this.patch[key] = value !== '' ? value : null

    this.writeAnyRecord('patch', params)
  }

  get team1_score_edit() {
    return this.patch?.["team1_score"] as any
  }

  set team1_score_edit(value) {
    this.writePatch({ key: "team1_score", value })
  }

  get team2_score_edit() {
    return this.patch?.["team2_score"] as any
  }

  set team2_score_edit(value) {
    this.writePatch({ key: "team2_score", value })
  }

  get team1_league_points_edit() {
    return this.patch?.["team1_league_points"] as any
  }

  set team1_league_points_edit(value) {
    this.writePatch({ key: "team1_league_points", value })
  }

  get team2_league_points_edit() {
    return this.patch?.["team2_league_points"] as any
  }

  set team2_league_points_edit(value) {
    this.writePatch({ key: "team2_league_points", value })
  }

  get starts_at_edit() {
    return DateTime.parseDateOrNull(this.patch?.["starts_at"] as any)
  }

  set starts_at_edit(value) {
    this.writePatch({ key: "starts_at", value })
  }

  get started_at_edit() {
    return DateTime.parseDateOrNull(this.patch?.["started_at"] as any)
  }

  set started_at_edit(value) {
    this.writePatch({ key: "started_at", value })
  }

  get finished_at_edit() {
    return DateTime.parseDateOrNull(this.patch?.["finished_at"] as any)
  }

  set finished_at_edit(value) {
    this.writePatch({ key: "finished_at", value })
  }

  get canceled_at_edit() {
    return DateTime.parseDateOrNull(this.patch?.["canceled_at"] as any)
  }

  set canceled_at_edit(value) {
    this.writePatch({ key: "canceled_at", value })
  }

  get box_edit() {
    return this.patch?.["box"] as any
  }

  set box_edit(value) {
    this.writePatch({ key: "box", value })
  }

  /// >>> tournament >>>
  #tournament:BaseRecord.RefField<TournamentRecord> = {}

  tournament_id?: string

  get tournament() {
    return this.peekObjectFromRefField(this.#tournament, "tournaments")
  }

  getTournament() {
    return this.getObjectFromRefField(this.#tournament, "tournaments")
  }
  /// <<< tournament <<<

  /// >>> score >>>
  get score() {
    return `${this.team1_score ?? 0}:${this.team2_score ?? 0}`
  }
  /// >>> score >>>

  /// >>> tournament_round >>>
  #tournamentRound:BaseRecord.RefField<TournamentRoundRecord> = {}

  tournament_round_id?: string

  get tournamentRound() {
    return this.#tournamentRound.object
  }

  getTournamentRound() {
    this.#tournamentRound.id = this.tournament_round_id

    return this.getObjectFromRefField(this.#tournamentRound, "tournamentRounds")
  }
  /// <<< tournament_round <<<

  /// >>> tournament_stage >>>
  get tournamentStage() {
    return this.tournamentRound?.tournamentStage
  }

  async getTournamentStage() {
    return await (await this.getTournamentRound())?.getTournamentStage()
  }
  /// <<< tournament_stage <<<

  /// >>> computed_values >>>
  get isApproved() {
    return !!this.data_approved_at
  }

  get isPublished() {
    return !!this.published_at
  }

  get isFrozen() {
    return !!this.data_frozen_at
  }

  get tournament_name() {
    return this.tournament?.name ?? 'null'
  }

  get tournamentStage_id_filter() {
    return this.tournamentStage?.id ?? 'null'
  }
  get tournamentRound_id_filter() {
    return this.tournamentRound?.id ?? 'null'
  }
  get tournamentStage_name() {
    return this.tournamentStage?.name
  }
  get tournamentRound_name() {
    return this.tournamentRound?.name
  }
  get sortValues() {
    return [
      this.tournamentStage?.order_number ?? Number.MAX_SAFE_INTEGER,
      this.tournamentRound?.order_number ?? Number.MAX_SAFE_INTEGER,
      // sort from the actual date to latest
      Number.MAX_SAFE_INTEGER - (this.starts_at?.getTime() ?? 0),
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:MatchRecord.CtorJsonData) {
    return new MatchRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    Object.defineProperty(this, "tournament_id", {
      configurable: false,
      enumerable: true,
      get: () => this.#tournament.id,
      set: (value) => this.#tournament.id = value
    })

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.datasource_id = jsonData?.datasource_id
    this.youtube_code = jsonData?.youtube_code
    this.match_text_summary = jsonData?.match_text_summary
    this.sport_id = jsonData?.sport_id
    this.tournament_id = jsonData?.tournament_id
    this.tournament_round_id = jsonData?.tournament_round_id
    this.box = jsonData?.box
    this.box_orig = jsonData?.box_orig
    this.binding_id = jsonData?.binding_id
    this.flag_for_nearest_matches = jsonData?.flag_for_nearest_matches
    this.highlight_match = jsonData?.highlight_match
    this.team1_id = jsonData?.team1_id
    this.team2_id = jsonData?.team2_id
    this.team1_name = jsonData?.team1_name
    this.team2_name = jsonData?.team2_name
    this.team1_score = jsonData?.team1_score
    this.team2_score = jsonData?.team2_score
    this.team1_score_orig = jsonData?.team1_score_orig
    this.team2_score_orig = jsonData?.team2_score_orig
    this.starts_at = DateTime.parseDateOrNull(jsonData?.starts_at)
    this.starts_at_orig = DateTime.parseDateOrNull(jsonData?.starts_at_orig)
    this.started_at = DateTime.parseDateOrNull(jsonData?.started_at)
    this.started_at_orig = DateTime.parseDateOrNull(jsonData?.started_at_orig)
    this.finished_at = DateTime.parseDateOrNull(jsonData?.finished_at)
    this.finished_at_orig = DateTime.parseDateOrNull(jsonData?.finished_at_orig)
    this.canceled_at = DateTime.parseDateOrNull(jsonData?.canceled_at)
    this.canceled_at_orig = DateTime.parseDateOrNull(jsonData?.canceled_at_orig)
    this.team1_league_points = jsonData?.team1_league_points
    this.team2_league_points = jsonData?.team2_league_points
    this.team1_league_points_orig = jsonData?.team1_league_points_orig
    this.team2_league_points_orig = jsonData?.team2_league_points_orig
    this.data_approved_at = DateTime.parseDateOrNull(jsonData?.data_approved_at)
    this.published_at = DateTime.parseDateOrNull(jsonData?.published_at)
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.patch = jsonData?.patch
    this.stats_config = jsonData?.stats_config
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      datasource_id: this.datasource_id,
      youtube_code: this.youtube_code,
      match_text_summary: this.match_text_summary,
      sport_id: this.sport_id,
      tournament_id: this.tournament_id,
      tournament_round_id: this.tournament_round_id,
      box: this.box,
      box_orig: this.box_orig,
      binding_id: this.binding_id,
      flag_for_nearest_matches: this.flag_for_nearest_matches,
      highlight_match: this.highlight_match,
      team1_id: this.team1_id,
      team2_id: this.team2_id,
      team1_name: this.team1_name,
      team2_name: this.team2_name,
      team1_score: this.team1_score,
      team2_score: this.team2_score,
      team1_score_orig: this.team1_score_orig,
      team2_score_orig: this.team2_score_orig,
      starts_at: this.starts_at?.toISOString(),
      starts_at_orig: this.starts_at_orig?.toISOString(),
      started_at: this.started_at?.toISOString(),
      started_at_orig: this.started_at_orig?.toISOString(),
      finished_at: this.finished_at?.toISOString(),
      finished_at_orig: this.finished_at_orig?.toISOString(),
      canceled_at: this.canceled_at?.toISOString(),
      canceled_at_orig: this.canceled_at_orig?.toISOString(),
      team1_league_points: this.team1_league_points,
      team2_league_points: this.team2_league_points,
      team1_league_points_orig: this.team1_league_points_orig,
      team2_league_points_orig: this.team2_league_points_orig,
      data_approved_at: this.data_approved_at?.toISOString(),
      published_at: this.published_at?.toISOString(),
      data_frozen_at: this.data_frozen_at?.toISOString(),
      patch: this.patch,
      stats_config: this.stats_config
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const tournament = await this.getTournament()
    await tournament?.preload()

    const round = await this.getTournamentRound()
    await round?.preload()

    const team = await this.getTeam()
    await team?.preload()
  }
}

export module MatchRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    datasource_id?: string
    youtube_code?: string
    match_text_summary?: string
    sport_id?: string
    tournament_id?: string
    tournament_round_id?: string
    box?: number
    box_orig?: number
    binding_id?: string
    flag_for_nearest_matches?: boolean
    highlight_match?: boolean
    team1_id?: string
    team2_id?: string
    team1_name?: string
    team2_name?: string
    team1_score?: number
    team2_score?: number
    team1_score_orig?: number
    team2_score_orig?: number
    starts_at?: string
    starts_at_orig?: string
    started_at?: string
    started_at_orig?: string
    finished_at?: string
    finished_at_orig?: string
    canceled_at?: string
    canceled_at_orig?: string
    team1_league_points?: number
    team2_league_points?: number
    team1_league_points_orig?: number
    team2_league_points_orig?: number
    data_approved_at?: string
    published_at?: string
    data_frozen_at?: string
    patch?: AnyRecord
    stats_config?: AnyRecord
  }

  export class Repository extends RecordRepository<MatchRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by tournament_id >>>
    #getByTournamentId:RecordRepository.NestingGetFieldFlags = {}

    getByTournamentId(tournamentId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTournamentId, tournamentId, options, {
        peek: () => this.peekByTournamentId(tournamentId),
        load: () => this.loadByTournamentId(tournamentId)
      })
    }

    peekByTournamentId(tournamentId:string) {
      return this.peekAll(x => x?.tournament_id == tournamentId)
    }


    protected loadByTournamentId(tournamentId:string) {
      return this.getApi().getMoreMatchesByTournamentId(tournamentId)
    }
    /// <<< by tournament_id <<<

    create(record: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneMatch(record)
    }

    protected loadAll() {
      return this.getApi().getAllMatches()
    }

    protected loadById(id: string) {
      return this.getApi().getOneMatchById(id)
    }

    protected updateById(id: string, patch: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneMatch(id, patch)
    }

    protected deleteById(id: string): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneMatch(id)
    }

    protected approveById(id: string, patch: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.approveOneMatchById(id, patch)
    }

    protected unapproveById(id: string, patch: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unapproveOneMatchById(id, patch)
    }

    protected publishById(id: string, patch: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.publishOneMatchById(id, patch)
    }

    protected unpublishById(id: string, patch: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unpublishOneMatchById(id, patch)
    }

    protected freezeById(id: string, patch: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneMatchById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<MatchRecord<CtorJsonData>>): Promise<MatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneMatchById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllMatches()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneMatchById(id)
    }
  }
}
