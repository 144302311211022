import styled, { css } from 'styled-components/macro'
// Components
import { CrossIcon } from 'styles/fontAwesome'
import { Breakpoint } from 'styles/responsive'

const OFFSET = '15px'

export const Close = styled(CrossIcon)`
  font-size: 2.25rem;

  color: black;

  z-index: 999;
`

export const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 999;

  padding: 45px;

  background-color: #000000cc;
  backdrop-filter: blur(5px);
`

export const InnerWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;

  max-width: calc(100vw - 15px);
  max-height: calc(100vh - 15px);
  
  align-items: flex-start;

  width: 80%;
  height: 80%;

  ${Breakpoint.ForBigMobile(css`
    width: 95%;
    height: 95%;
  `)}


  padding: ${OFFSET};
  padding-top: 50px;
  
  box-sizing: border-box;

  position: relative;
  ${Close} {
    position: absolute;

    top: ${OFFSET};
    right: ${OFFSET};

    padding: ${OFFSET};
    margin: -${OFFSET};

    cursor: pointer;
  }

  background-color: #ffffffaa;
  border-radius: 6px;
`
