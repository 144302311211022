import React, { useCallback } from 'react'
import { usePagination } from '@material-ui/lab/Pagination'
// Components
import { Pagination as BPagination } from 'react-bootstrap'
// Styles
import { CustomPaginationWrapper } from './Pagination.styles'
import { Breakpoint } from 'styles/responsive'
// Additional
import { DeviceType } from 'types/device'

interface Props {
  readonly previousPageFn: () => void
  readonly nextPageFn: () => void
  readonly gotoPageFn: (pageIndex: number) => void

  readonly pageIndex: number
  readonly pageLength: number
}

const Pagination: React.FC<
  Props & { syncKey?: string; urlHrefFn?: (page: string) => string }
> = (p) => {
  const { items } = usePagination({
    count: p.pageLength,
    page: p.pageIndex + 1,
  })

  const GetPagination = useCallback(
    (device: DeviceType) =>
      items.map(({ page, type, selected, ...item }, index) => {
        const isMobile = device === 'mobile'

        switch (type) {
          case 'start-ellipsis':
          case 'end-ellipsis': {
            if (isMobile && !selected) return null
            
            return (
              <BPagination.Ellipsis key={`${index}_${p.syncKey}`} disabled />
            )
          }
          case 'page': {
            if (isMobile && !selected) return null

            return (
              <BPagination.Item
                {...item}
                key={`${index}_${p.syncKey}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  if (e.button == 0) {
                    e.preventDefault()
                  }

                  p.gotoPageFn(page - 1)
                }}
                active={selected}
                href={p.urlHrefFn?.(page.toString())}
              >
                {page}
              </BPagination.Item>
            )
          }
          case 'next': {
            return (
              <BPagination.Next
                {...item}
                key={`${index}_${p.syncKey}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  if (e.button == 0) {
                    e.preventDefault()
                  }

                  p.nextPageFn()
                }}
                href={p.urlHrefFn?.(page.toString())}
              />
            )
          }
          case 'previous': {
            return (
              <BPagination.Prev
                {...item}
                key={`${index}_${p.syncKey}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  if (e.button == 0) {
                    e.preventDefault()
                  }

                  p.previousPageFn()
                }}
                href={p.urlHrefFn?.(page.toString())}
              />
            )
          }
          default:
            return null
        }
      }),
    [items]
  )

  return (
    <CustomPaginationWrapper>
      <BPagination>
        <Breakpoint.Desktop>{GetPagination('desktop')}</Breakpoint.Desktop>
        <Breakpoint.TabletMobile>
          {GetPagination('mobile')}
        </Breakpoint.TabletMobile>
      </BPagination>
    </CustomPaginationWrapper>
  )
}

export default Pagination
