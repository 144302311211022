import React from 'react'
import styled from 'styled-components'

/* eslint-disable @typescript-eslint/no-var-requires */
const jsonMarkup = require('json-markup')

interface Props {
  readonly json: string
}

const JsonView: React.FC<Props> = ({ json }) => (
  <Wrapper dangerouslySetInnerHTML={{ __html: json ? jsonMarkup(JSON.parse(json)) : <></>}} />
)

export default JsonView

const Wrapper = styled.div`
  .json-markup {
    line-height: 17px;
    font-size: 13px;
    font-family: monospace;
    white-space: pre;
  }
  .json-markup-key {
    font-weight: bold;
  }
  .json-markup-bool {
    color: firebrick;
  }
  .json-markup-string {
    color: green;
  }
  .json-markup-null {
    color: gray;
  }
  .json-markup-number {
    color: blue;
  }
`
