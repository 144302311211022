import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { SportsmanRecord } from "./SportsmanRecord";

export class SportsmanBiographyRecord<TJsonData extends SportsmanBiographyRecord.CtorJsonData = SportsmanBiographyRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
    perex?: string
    text?: string
    images?: string
    facebook?: string
    twich?: string
    twitter?: string
    instagram?: string
    data_frozen_at?: Date | null

    /// >>> sportsman >>>
    #sportsman:BaseRecord.RefField<SportsmanRecord> = {}

    sportsman_id?: string

    get sportsman() {
        return this.#sportsman.object
    }

    getSportsman() {
        this.#sportsman.id = this.sportsman_id

        return this.getObjectFromRefField(this.#sportsman, "sportsmen")
    }
    /// <<< sportsman <<<

    /// >>> computed_values >>>
    get isFrozen() {
        return !!this.data_frozen_at
    }

    get sortValues() {
        return [
            this.sportsman_id ?? Number.MAX_SAFE_INTEGER,
        ]
    }
    /// <<< comuted_values <<<

    static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportsmanBiographyRecord.CtorJsonData) {
        return new SportsmanBiographyRecord(db, jsonData)
    }

    constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
        super(db)

        if (jsonData) {
            this.patchData(jsonData)
        }
    }

    patchData(jsonData:TJsonData) {
        super.patchData(jsonData)

        this.perex = jsonData?.perex
        this.text = jsonData?.text
        this.images = jsonData?.images
        this.facebook = jsonData?.facebook
        this.twich = jsonData?.twich
        this.twitter = jsonData?.twitter
        this.instagram = jsonData?.instagram
        this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
        this.sportsman_id = jsonData?.sportsman_id
    }

    toJson():TJsonData {
        return Object.assign(super.toJson(), {
            perex: this.perex,
            text: this.text,
            images: this.images,
            facebook: this.facebook,
            twich: this.twich,
            twitter: this.twitter,
            instagram: this.instagram,
            data_frozen_at: this.data_frozen_at?.toISOString(),
            sportsman_id: this.sportsman_id
        }) as TJsonData
    }

    async preload() {
        await super.preload()

        const sportsman = await this.getSportsman()
        await sportsman?.preload()
    }
}

export module SportsmanBiographyRecord {
    export type CtorJsonData = BaseRecord.CtorJsonData & {
        perex?: string
        text?: string
        images?: string
        facebook?: string
        twich?: string
        twitter?: string
        instagram?: string
        data_frozen_at?: string
        sportsman_id?: string
    }

    export class Repository extends RecordRepository<SportsmanBiographyRecord> {

        /// >>> by sportsman_id >>>
        #getBySportsmanId:RecordRepository.NestingGetFieldFlags = {}
        getBySportsmanId(sportsmanId:string, options?:RecordRepository.GetOptions) {
            return this.nestedPeekOrLoad(this.#getBySportsmanId, sportsmanId, options, {
                peek: () => this.peekBySportsmanId(sportsmanId),
                load: async () => [await this.loadBySportsmanId(sportsmanId)]
            })
        }

        peekBySportsmanId(sportsmanId:string) {
            return this.peekAll(x => x.sportsman_id == sportsmanId)
        }

        protected loadBySportsmanId(sportsmanId:string) {
            return this.getApi().getOneSportmanBiographyBySportsmanId(sportsmanId)
        }
        /// <<< by sportman_id <<<

        loadAll() {
          return this.getApi().getAllSportsmanBiographies()
        }

        create(record: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
            const api = this.getApiOrThrow()
            return api.createOneSportsmanBiography(record)
        }

        protected loadById(id: string) {
            return this.getApi().getOneSportmanBiographyById(id)
        }

        protected updateById(id: string, patch: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
            const api = this.getApiOrThrow()
            return api.updateOneSportsmanBiography(id, patch)
        }

        protected deleteById(id: string): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
            const api = this.getApiOrThrow()
            return api.deleteOneSportsmanBiography(id)
        }

        /// >>> not implemented methods >>>
        protected approveById(id: string, patch: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
            throw new Error("Method not implemented.");
        }
        protected unapproveById(id: string, patch: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
            throw new Error("Method not implemented.");
        }
        protected publishById(id: string, patch: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
          throw new Error("Method not implemented.");
        }
        protected unpublishById(id: string, patch: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
          throw new Error("Method not implemented.");
        }
        protected freezeById(id: string, patch: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
            throw new Error("Method not implemented.");
        }
        protected unfreezeById(id: string, patch: Partial<SportsmanBiographyRecord<CtorJsonData>>): Promise<SportsmanBiographyRecord<CtorJsonData> | null> {
            throw new Error("Method not implemented.");
        }
        protected touchById(id: string): Promise<number | null> {
            throw new Error("Method not implemented.");
        }
        protected touchAll_(): Promise<number | null> {
            throw new Error("Method not implemented.");
        }
        /// <<< not implemented methods <<<
    }
}
