import { DateTime } from "utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { TeamRecord } from "./TeamRecord";

export class TeamBiographyRecord<TJsonData extends TeamBiographyRecord.CtorJsonData = TeamBiographyRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  perex?: string
  text?: string
  images?: string
  facebook?: string
  twich?: string
  twitter?: string
  instagram?: string
  data_frozen_at?: Date | null

  /// >>> Team >>>
  #team:BaseRecord.RefField<TeamRecord> = {}

  team_id?: string

  get team() {
    return this.#team.object
  }

  getTeam() {
    this.#team.id = this.team_id

    return this.getObjectFromRefField(this.#team, "teams")
  }
  /// <<< Team <<<

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sortValues() {
    return [
      this.team_id ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< computed_values

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TeamBiographyRecord.CtorJsonData) {
    return new TeamBiographyRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.perex = jsonData?.perex
    this.text = jsonData?.text
    this.images = jsonData?.images
    this.facebook = jsonData?.facebook
    this.twich = jsonData?.twich
    this.twitter = jsonData?.twitter
    this.instagram = jsonData?.instagram
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.team_id = jsonData?.team_id
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      perex: this.perex,
      text: this.text,
      images: this.images,
      facebook: this.facebook,
      twich: this.twich,
      twitter: this.twitter,
      instagram: this.instagram,
      data_frozen_at: this.data_frozen_at?.toISOString(),
      team_id: this.team_id
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const team = await this.getTeam()
    await team?.preload()
  }
}

export module TeamBiographyRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    perex?: string
    text?:string
    images?: string
    facebook?: string
    twich?: string
    twitter?: string
    instagram?: string
    data_frozen_at?: string
    team_id?: string
  }

  export class Repository extends RecordRepository<TeamBiographyRecord> {
    /// >>> by team_id >>>
    #getByteamId:RecordRepository.NestingGetFieldFlags = {}
    getByTeamId(teamId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByteamId, teamId, options, {
        peek: () => this.peekByTeamId(teamId),
        load: async () => [await this.loadByTeamId(teamId)]
      })
    }

    peekByTeamId(teamId:string) {
      return this.peekAll(x => x.team_id == teamId)
    }

    protected loadByTeamId(teamId:string) {
      return this.getApi().getOneTeamBiographyByTeamId(teamId)
    }
    /// <<< by team_id <<<

    loadAll() {
      return this.getApi().getAllTeamBiographies()
    }

    create(record: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTeamBiography(record)
    }

    protected loadById(id: string): Promise<TeamBiographyRecord<CtorJsonData> | null> {
      return this.getApi().getOneTeamBiographyById(id)
    }

    protected updateById(id: string, patch: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTeamBiography(id, patch)
    }

    protected deleteById(id: string): Promise<TeamBiographyRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTeamBiography(id)
    }

    /// >>> not implemented methods >>>
    protected approveById(id: string, patch: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
        throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
        throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
        throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<TeamBiographyRecord<CtorJsonData>>): Promise<TeamBiographyRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
        throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
        throw new Error("Method not implemented.");
    }
  /// <<< not implemented methods <<<
  }


}
