import React from 'react'
import styled from 'styled-components'
// Components
import { Form } from 'react-bootstrap'
// Additional
import { getUniqueId } from 'utils/common'

type CustomProps = {
  children?: React.ReactNode
}

const CustomGroupStls = styled.div`
  label {
    user-select: none;

    position: absolute;

    padding: 0.375rem 0.75rem;

    z-index: 3;

    color: #6c757d;

    transition: all 0.25s;
  }

  input {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  /* input:read-only ~ label,
  input:not([value='']) ~ label,
  input:focus ~ label  */
  input ~ label {
    /* background-color: white; */

    font-size: 0.85rem;

    top: -24px;
    left: 0px;
    
    padding: 0 0.25rem;

    transition: all 0.25s;
  }

  margin-top: 18px;

  input:read-only:not([hidden]) ~ label {
    background-color: #ffffff9e;
  }

  input:required[value='']:not(:disabled):not(:read-only) {
    background-color: #fcfcd3;
  }
`

const CustomGroup = React.forwardRef(
  (props: CustomProps, ref: React.Ref<HTMLElement>) => (
    <CustomGroupStls className={'input-group'}>
      {props.children}
    </CustomGroupStls>
  )
)
CustomGroup.displayName = 'CustomGroup'

const FormItem: React.FC<{
  label?: React.ReactNode
  input?: React.ReactNode
}> = (p) => (
  <Form.Group as={CustomGroup} controlId={getUniqueId()}>
    {p.input}
    {p.label}
  </Form.Group>
)

export default FormItem
