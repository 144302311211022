import React, { ComponentProps } from 'react'
// Components
import Filters from 'components/Team/Filters'
import Table from 'components/Team/Table/Table'

interface Props {
  readonly table?: Pick<ComponentProps<typeof Table>, 'onSelect'>
  readonly filter?: Pick<ComponentProps<typeof Filters>, 'selectedId'>
}

const ModalFilter: React.FC<Props> = (p) => (
  <>
    <Filters {...p.filter} />
    <Table {...p.table} />
  </>
)

export default ModalFilter
