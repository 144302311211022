import React, { ComponentProps, useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import { Tab } from 'static/tabs'
import { AvailableColumns, Settings } from './Table.settings'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { AppContext } from 'App'
import { TeamRecord } from 'data/TeamRecord'
import { Data } from 'helpers/data'
import { SportContext } from 'components/Sports/__Sports.context'
import { TeamOrganizationContext } from 'components/TeamOrganizations/__TeamOrganizations.context'

const DEFAULT_COLUMNS = [
  'isFrozen',
  'full_name',
  'short_name',
  'isApproved',
  'is_popular',
  'ctaButton',
] as Array<AvailableColumns>

const FILTER_COLUMNS = [
  'full_name',
  'short_name',
  'isApproved',
  'is_popular',
] as Array<AvailableColumns>

interface Props extends Pick<ComponentProps<typeof TableLoader>, 'onSelect'> {}

const Table: React.FC<Props> = (p) => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const { params: t_params } = useContext(TournamentContext)
  const { params: s_params } = useContext(SportContext)
  const { params: to_params } = useContext(TeamOrganizationContext)

  const loadData = async () => {
    if (s_params?.sport_id)
      return await Data.getTeamsBySportId(a_db!, s_params?.sport_id, {
        alert: a_alert,
      })

    if (t_params?.tournament_id)
      return await Data.getTeamsByTournamentId(a_db!, t_params?.tournament_id, {
        alert: a_alert,
      })

    if (to_params?.team_organization_id)
      return await Data.getTeamByTeamOrgId(
        a_db!,
        to_params.team_organization_id,
        {
          alert: a_alert,
        }
      )

    return await Data.getAllTeams(a_db!, { alert: a_alert })
  }

  const useAsFilterView = Boolean(p.onSelect)

  return (
    <TableLoader<TeamRecord>
      reloadFrom={[Tab.Teams]}
      loadDataFn={loadData}
      settings={Settings(useAsFilterView ? FILTER_COLUMNS : DEFAULT_COLUMNS, {
        disableNew: Boolean(t_params?.tournament_id),
      })}
      // @ts-ignore
      onSelect={p.onSelect}
      disableChangeUrl
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.GetTableRowStyle(),
        tbodyR: R.GetTableRowStyle(useAsFilterView),
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default Table
