import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class AzureBlobRecord<TJsonData extends AzureBlobRecord.CtorJsonData = AzureBlobRecord.CtorJsonData>
  extends BaseRecord<TJsonData> {
  prefix?: string
  name?: string
  url?: string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:AzureBlobRecord.CtorJsonData) {
    return new AzureBlobRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.prefix = jsonData?.prefix
    this.name = jsonData?.name
    this.url = jsonData?.url
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      prefix: this.prefix,
      name: this.name,
      url: this.url
    }) as TJsonData
  }

}

export module AzureBlobRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    prefix?: string
    name?: string
    url?: string
  }

  export class Repository extends RecordRepository<AzureBlobRecord> {

    //TODO vyresit peekOrLoad
    getAllByNameAndPrefix(name:string, prefix:string) {
      const api = this.getApiOrThrow()
      return api.getAllAzureBlobsByNameAndPrefix(name, prefix)
    }

    protected loadById(id: string): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected updateById(id: string, patch: Partial<AzureBlobRecord<CtorJsonData>>): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected deleteById(id: string): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected approveById(id: string, patch: Partial<AzureBlobRecord<CtorJsonData>>): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<AzureBlobRecord<CtorJsonData>>): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<AzureBlobRecord<CtorJsonData>>): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<AzureBlobRecord<CtorJsonData>>): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<AzureBlobRecord<CtorJsonData>>): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<AzureBlobRecord<CtorJsonData>>): Promise<AzureBlobRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
  }
}
