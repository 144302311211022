import { String as StringHelper } from 'helpers/string'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'App'
import useAsyncEffect from 'use-async-effect'
import { Tab } from 'static/tabs'
import { Text } from 'static/text'
import Warning from 'components/utils/Warning'
import Loading from 'components/utils/Loading'
import { ItemWrapper } from 'styles/common'
import { TournamentDatasourceIdentifierContext } from './TournamentDatasourceIdentifiers.context'
import { TournamentDatasourceIdentifierRecord } from 'data/TournamentDatasourceIdentifierRecord'
import TournamentDatasourceIdentifierForm from './Form'

const Detail: React.FC = (p) => {
  const {
    db: a_db,
    alert: a_alert,
    location: a_location,
  } = useContext(AppContext)

  const { params: s_params} = useContext(TournamentDatasourceIdentifierContext)
  const isNewRecord = RoutingHelper.getIsNewRecord(
    s_params?.tournament_datasource_identifier_id ?? RoutingHelper.NewRecordSymbol
  )

  // --------------------- Load Data --------------------- //
  const [content, setContent] = useState<{
    data?: TournamentDatasourceIdentifierRecord
    load: {
      isInProgress?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (isNewRecord || !s_params?.tournament_datasource_identifier_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true } }))

    try {
      const _data = await DataHelper.getTournamentDatasourceIdentifierById(a_db!, s_params?.tournament_datasource_identifier_id, {
        alert: a_alert,
      })

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false },
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true },
      }))
    }
  }, [s_params?.tournament_datasource_identifier_id])

  // -------------------- Set Location ------------------- //
  const _setLocation = (data?: TournamentDatasourceIdentifierRecord) => {
    a_location?.set?.(
      Tab.TournamentDatasourceIdentifiers,
      data && `${StringHelper.spaceTo("Tournament id: " + data?.tournament_id ?? '')}`
    )
  }

  useEffect(() => {
    _setLocation(content?.data)

    // content?.data
  }, [content?.data, isNewRecord])

  // ---------------------- Handlers --------------------- //
  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <TournamentDatasourceIdentifierForm
          data={content?.data}
          state={isNewRecord ? 'new' : 'existing'}
          submitCallback={_setLocation}
        />
      </ItemWrapper>
    </>
  )
}

export default Detail
