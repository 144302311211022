import { BaseRecord } from './BaseRecord'
import { MatchRecord } from './MatchRecord'
import { TeamRecord } from './TeamRecord'
import { GameMapRecord } from './GameMapRecord'
import { BaseRootRepository } from './repository/BaseRootRepository'
import { RecordRepository } from './repository/RecordRepository'

export class MatchMapVetoRecord<TJsonData extends MatchMapVetoRecord.CtorJsonData = MatchMapVetoRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  action_ident?: string

  /// >>> match >>>
  #match:BaseRecord.RefField<MatchRecord> = {}

  match_id?: string

  get match() {
    return this.#match?.object
  }
  getMatch() {
    this.#match.id = this.match_id

    return this.getObjectFromRefField(this.#match, "matches")
  }
  /// <<< match <<<

  /// >>> game map >>>
  #gameMap:BaseRecord.RefField<GameMapRecord> = {}

  game_map_id?: string

  get gameMap() {
    return this.#gameMap?.object
  }
  getGameMap() {
    this.#gameMap.id = this.game_map_id

    return this.getObjectFromRefField(this.#gameMap, "gameMaps")
  }
  /// <<< game map <<<

  /// >>> team >>>
  #team:BaseRecord.RefField<TeamRecord> = {}

  team_id?: string

  get team() {
    return this.#team?.object
  }
  getTeam() {
    this.#team.id = this.team_id

    return this.getObjectFromRefField(this.#team, "teams")
  }
  /// <<< team <<<

  /// >>> computed_values >>>
  get match_name() {
    return this.match?.team1_name && this.match?.team2_name
      ? `${this.match?.team1_name} vs ${this.match?.team2_name}`
      : ''
  }

  get game_map_name() {
    return this.gameMap?.name ?? ''
  }

  get team_name() {
    return this.team?.full_name ?? ''
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:MatchMapVetoRecord.CtorJsonData) {
    return new MatchMapVetoRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.match_id = jsonData?.match_id
    this.game_map_id = jsonData?.game_map_id
    this.team_id = jsonData?.team_id
    this.action_ident = jsonData?.action_ident
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      match_id: this.match_id,
      game_map_id: this.game_map_id,
      team_id: this.team_id,
      action_ident: this.action_ident,
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const match = await this.getMatch()
    await match?.preload()

    const gameMap = await this.getGameMap()
    await gameMap?.preload()

    const team = await this.getTeam()
    await team?.preload()
  }
}

export module MatchMapVetoRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    match_id: string
    game_map_id: string
    team_id: string
    action_ident: string
  }

  export class Repository extends RecordRepository<MatchMapVetoRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by match_id >>>
    #getByMatchId:RecordRepository.NestingGetFieldFlags = {}

    getByMatchId(matchId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByMatchId, matchId, options, {
        peek: () => this.peekByMatchId(matchId),
        load: () => this.loadByMatchId(matchId)
      })
    }

    peekByMatchId(matchId:string) {
      return this.peekAll(x => x?.match_id == matchId)
    }

    protected loadByMatchId(matchId:string) {
      return this.getApi().getMoreMatchMapVetosByMatchId(matchId)
    }
    /// <<< by match_id <<<

    create(record: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneMatchMapVeto(record)
    }

    protected loadById(id: string) {
      return this.getApi().getOneMatchMapVetoById(id)
    }

    protected loadAll() {
      return this.getApi().getAllMatchMapVetos()
    }

    protected updateById(id: string, patch: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneMatchMapVeto(id, patch)
    }

    protected deleteById(id: string): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneMatchMapVeto(id)
    }

    protected approveById(id: string, patch: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected publishById(id: string, patch: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      throw new Error('Method not implemented.')
    }

    protected unpublishById(id: string, patch: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      throw new Error('Method not implemented.')
    }

    protected freezeById(id: string, patch: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<MatchMapVetoRecord<CtorJsonData>>): Promise<MatchMapVetoRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllMatchMapVetos()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneMatchMapById(id)
    }
  }
}
