import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
// Styles
import { EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { MatchMapVetoRecord } from 'data/MatchMapVetoRecord'
import { RecordsSetting } from 'components/utils/Table/_Table.settings'

export module Settings {
  export const get = (id?: {
    tournament?: string
    match?: string
  }): RecordsSetting<MatchMapVetoRecord> => {
    return [
      {
        accessor: 'id',
        className: 'table-hidden',
      },
      {
        accessor: 'match_name',
        Header: 'Match',
        className: 'table-match',
      },
      {
        accessor: 'game_map_name',
        Header: 'Game Map',
        className: 'table-game-map',
      },
      {
        accessor: 'team_name',
        Header: 'Team',
        className: 'table-team ',
      },
      {
        accessor: 'action_ident',
        Header: 'Action Ident',
        className: 'table-ident',
      },
      {
        id: 'ctaButton',
        sticky: 'right',
        className: 'table-cta-button',
        Header: function HeaderContent({ row }: any) {
          const { toggle } = useContext(AccordionContext)

          return (
            <CTAButton onClick={toggle}>
              <EllipsisHIcon />
            </CTAButton>
          )
        },
        Cell: function CellContent({ row }: any) {
          return (
            <CTAButton
              path={Routing.getMatchMapVetoDetailUrl({
                tournament: id?.tournament ?? Routing.NewRecordSymbol,
                match: id?.match ?? Routing.NewRecordSymbol,
                matchMapVeto: row.values.id,
              })}
            >
              {row.values.isFrozen ? 'View' : 'Edit'}
            </CTAButton>
          )
        },
      },
    ]
  }
}
