import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import UIColumnFilter from 'components/utils/Filter/ColumnFilter'
// Styles
import { LockIcon, CheckIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { Tab } from 'static/tabs'
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { TournamentRecord } from 'data/TournamentRecord'
import { DateTime } from 'utils/dateTime'
import { RecordsSetting } from 'components/utils/Table/_Table.settings'

const Settings: RecordsSetting<TournamentRecord> = [
  {
    accessor: 'id',
    className: 'table-hidden',
  },
  {
    accessor: 'sport_id',
    className: 'table-hidden',
    Filter: function FilterContent(p: any) {
      return <UIColumnFilter from={Tab.Tournaments} filter={p} />
    },
    filter: 'fuzzyText',
  },
  {
    accessor: 'isFrozen',
    className: 'table-lock',
    Header: function HeaderContent({ row }: any) {
      return <LockIcon />
    },
    Cell: function CellContent({ row }: any) {
      return <>{row.values.isFrozen && <LockIcon />}</>
    },
  },
  {
    accessor: 'sport_name',
    Header: 'Sport',
    className: 'table-sport',
  },
  {
    accessor: 'name',
    Header: 'Name',
    className: 'table-name',
  },
  {
    accessor: 'short_name',
    Header: 'Short Name',
    className: 'table-short-name',
  },
  {
    accessor: 'starts_at',
    Header: 'Start',
    className: 'table-start',
    Cell: ({ row }: any) =>
      row.values?.starts_at ? DateTime.shortDate(row.values?.starts_at) : '---',
  },
  {
    accessor: 'ends_at',
    Header: 'Finish',
    className: 'table-finish',
    Cell: ({ row }: any) =>
      row.values?.ends_at ? DateTime.shortDate(row.values?.ends_at) : '---',
  },
  {
    accessor: 'isApproved',
    Header: 'Approved',
    className: 'table-approved',
    Cell: function CellContent({ row }: any) {
      return <>{row.values.isApproved && <CheckIcon />}</>
    },
  },
  {
    id: 'ctaButton',
    sticky: 'right',
    className: 'table-cta-button',
    Header: function HeaderContent({ row }: any) {
      const { toggle } = useContext(AccordionContext)

      return (
        <CTAButton onClick={toggle}>
          <EllipsisHIcon />
        </CTAButton>
      )
    },
    Cell: function CellContent({ row }: any) {
      return (
        <CTAButton
          path={Routing.getTournamentDetailUrl({ tournament: row.values.id })}
        >
          {row.values.isFrozen ? 'View' : 'Edit'}
        </CTAButton>
      )
    },
  },
]

export default Settings
