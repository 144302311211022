import { DateTime } from "utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { MatchRecord } from "./MatchRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { TeamRecord } from "./TeamRecord";
import { TournamentRecord } from "./TournamentRecord";

type WriteAnyRecordParams = { key: string | number, value: unknown }

export class NearestMatchRecord<TJsonData extends NearestMatchRecord.CtorJsonData = NearestMatchRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  order_number?: number
  match_id?: string
  box?: number
  team1_id?: string
  team1_name?: string
  team1_logo_url?: string
  team1_score?: number
  team2_id?: string
  team2_name?: string
  team2_logo_url?: string
  team2_score?: number
  tournament_id?: string
  tournament_name?: string
  tournament_logo_url?: string
  sport_ident?: string
  highlight_match?: boolean | null
  starts_at?: Date | null
  started_at?: Date | null
  finished_at?: Date | null
  canceled_at?: Date | null
  data_frozen_at?: Date | null
  flag_for_nearest_matches?: boolean | null

  #match:BaseRecord.RefField<MatchRecord> = {}
  // get match_id() {
  //   return this.#match.id
  // }
  // set match_id(value) {
  //   this.#match.id = value;
  // }

  peekMatch() {
    this.#match.id = this.match_id
    return this.peekObjectFromRefField(this.#match, "matches")
  }
  getMatch() {
    this.#match.id = this.match_id
    return this.getObjectFromRefField(this.#match, "matches")
  }

  #tournament:BaseRecord.RefField<TournamentRecord> = {}

  peekTournament() {
    this.#tournament.id = this.tournament_id
    return this.peekObjectFromRefField(this.#tournament, "tournaments")
  }

  getTournament() {
    this.#tournament.id = this.tournament_id
    return this.getObjectFromRefField(this.#tournament, "tournaments")
  }

  #team1:BaseRecord.RefField<TeamRecord> = {}

  peekTeam1() {
    this.#team1.id = this.team1_id
    return this.peekObjectFromRefField(this.#team1, "teams")
  }

  getTeam1() {
    this.#team1.id = this.team1_id
    return this.getObjectFromRefField(this.#team1, "teams")
  }

  #team2:BaseRecord.RefField<TeamRecord> = {}
  peekTeam2() {
    this.#team2.id = this.team2_id
    return this.peekObjectFromRefField(this.#team2, "teams")
  }

  getteam2() {
    this.#team2.id = this.team2_id
    return this.getObjectFromRefField(this.#team2, "teams")
  }

  async preload(): Promise<void> {
    await super.preload()

    const match = await this.getMatch()
    await match?.preload()

    const tournament = await this.getTournament()
    await tournament?.preload()

    const team1 = await this.getTeam1()
    await team1?.preload()

    const team2 = await this.getTeam1()
    await team2?.preload()
  }

  get finalTournamentName() {
    return this.peekFinalTournamentName()
  }

  peekFinalTournamentName() {
    const matchTournamentName = this.peekMatch()?.tournament?.name?.trim()
    if (matchTournamentName && matchTournamentName.length > 0) {
      return matchTournamentName
    }

    const tournamentName = this.peekTournament()?.name?.trim()
    if (tournamentName && tournamentName.length > 0) {
      return tournamentName
    }

    return this.tournament_name
  }

  get finalTeam1Name() {
    const matchTeam1Name = this.peekMatch()?.team1_name?.trim()
    if (matchTeam1Name && matchTeam1Name.length > 0) {
      return matchTeam1Name
    }

    const team1Name = this.peekTeam1()?.full_name?.trim()
    if (team1Name && team1Name.length > 0) {
      return team1Name
    }

    return this.team1_name
  }

  get finalTeam2Name() {
    const matchTeam2Name = this.peekMatch()?.team2_name?.trim()
    if (matchTeam2Name && matchTeam2Name.length > 0) {
      return matchTeam2Name
    }

    const team2Name = this.peekTeam2()?.full_name?.trim()
    if (team2Name && team2Name.length > 0) {
      return team2Name
    }

    return this.team2_name
  }

  get finalStartsAt() {
    const match = this.peekMatch()
    return match?.starts_at?.toUTCString() ?? this.starts_at
  }

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sortValues() {
    return [
      this.order_number ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< comuted_values <<<

  static createNew(db?: BaseRootRepository.OrNothing, jsonData?: NearestMatchRecord.CtorJsonData) {
    return new NearestMatchRecord(db, jsonData)
  }

  constructor(db?: BaseRootRepository.OrNothing, jsonData?: TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.order_number = jsonData?.order_number
    this.match_id = jsonData?.match_id
    this.box = jsonData?.box
    this.team1_id = jsonData?.team1_id
    this.team1_name = jsonData?.team1_name
    this.team1_logo_url = jsonData?.team1_logo_url
    this.team1_score = jsonData?.team1_score
    this.team2_id = jsonData?.team2_id
    this.team2_name = jsonData?.team2_name
    this.team2_logo_url = jsonData?.team2_logo_url
    this.team2_score = jsonData?.team2_score
    this.tournament_id = jsonData?.tournament_id
    this.tournament_name = jsonData?.tournament_name
    this.tournament_logo_url = jsonData?.tournament_logo_url
    this.sport_ident = jsonData?.sport_ident
    this.highlight_match = jsonData?.highlight_match
    this.started_at = DateTime.parseDateOrNull(jsonData?.started_at)
    this.starts_at = DateTime.parseDateOrNull(jsonData?.starts_at)
    this.finished_at = DateTime.parseDateOrNull(jsonData?.finished_at)
    this.canceled_at = DateTime.parseDateOrNull(jsonData?.canceled_at)
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.flag_for_nearest_matches = jsonData?.flag_for_nearest_matches
  }

  toJson(): TJsonData {
    return Object.assign(super.toJson(), {
      order_number: this.order_number,
      match_id: this.match_id,
      box: this.box,
      team1_id: this.team1_id,
      team1_name: this.team1_name,
      team1_logo_url: this.team1_logo_url,
      team1_score: this.team1_score,
      team2_id: this.team2_id,
      team2_name: this.team2_name,
      team2_logo_url: this.team2_logo_url,
      team2_score: this.team2_score,
      tournament_id: this.tournament_id,
      tournament_name: this.tournament_name,
      tournament_logo_url: this.tournament_logo_url,
      sport_ident: this.sport_ident,
      highlight_match: this.highlight_match,
      started_at: this.started_at?.toISOString(),
      starts_at: this.starts_at?.toISOString(),
      finished_at: this.finished_at?.toISOString(),
      canceled_at: this.canceled_at?.toISOString(),
      data_frozen_at: this.data_frozen_at?.toISOString(),
      flag_for_nearest_matches: this.flag_for_nearest_matches
    }) as TJsonData
  }

}

export module NearestMatchRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    order_number?: number
    match_id?: string
    box?: number
    team1_id?: string
    team1_name?: string
    team1_logo_url?: string
    team1_score?: number
    team2_id?: string
    team2_name?: string
    team2_logo_url?: string
    team2_score?: number
    tournament_id?: string
    tournament_name?: string
    tournament_logo_url?: string
    sport_ident?: string
    highlight_match?: boolean | null
    starts_at?: string
    started_at?: string
    finished_at?: string
    canceled_at?: string
    data_frozen_at?: string
    flag_for_nearest_matches?: boolean | null
  }

  export class Repository extends RecordRepository<NearestMatchRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneNearestMatch(record)
    }

    protected loadAll() {
      return this.getApi().getAllNearestMatches()
    }

    protected loadById(id: string): Promise<NearestMatchRecord<CtorJsonData> | null> {
      return this.getApi().getOneNearestMatchById(id)
    }
    protected updateById(id: string, patch: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneNearestMatch(id, patch)
    }
    protected deleteById(id: string): Promise<NearestMatchRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneNearestMatch(id)
    }
    /// >>> not implemented methods >>>
    protected approveById(id: string, patch: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<NearestMatchRecord<CtorJsonData>>): Promise<NearestMatchRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    /// <<< not implemented methods <<<
  }
}
