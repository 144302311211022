import { DateTime } from '../utils/dateTime'
import { BaseRecord } from './BaseRecord'
import { BaseRootRepository } from './repository/BaseRootRepository'
import { RecordRepository } from './repository/RecordRepository'
import { MatchMapRecord } from './MatchMapRecord'
import { RegionRecord } from './RegionRecord'
import { PeriodKindRecord } from './PeriodKindRecord'
import { GameMapRecord } from './GameMapRecord'
import { MatchRecord } from './MatchRecord'
import { TournamentRecord } from './TournamentRecord'

export class TimespacesRecord<
  TJsonData extends TimespacesRecord.CtorJsonData = TimespacesRecord.CtorJsonData
> extends BaseRecord<TJsonData> {
  sport_id?: string
  year?: number
  months?: number
  matches?: number
  tournaments?: number
  data_frozen_at?: Date | null

  /// >>> region >>>
  #region: BaseRecord.RefField<RegionRecord> = {}
  get region_id() {
    return this.#region.id
  }
  set region_id(value) {
    this.#region.id = value
  }

  get region() {
    return this.#region.object
  }
  async getRegion() {
    return this.getObjectFromRefField(this.#region, 'regions')
  }
  /// <<< region <<<

  /// >>> period kind >>>
  #periodKind: BaseRecord.RefField<PeriodKindRecord> = {}
  get period_kind_id() {
    return this.#periodKind.id
  }
  set period_kind_id(value) {
    this.#periodKind.id = value
  }

  get period_kind() {
    return this.#periodKind.object
  }
  async getPeriodKind() {
    return this.getObjectFromRefField(this.#periodKind, 'periodKinds')
  }
  /// <<< period kind <<<

  /// >>> game map >>>
  #gameMap: BaseRecord.RefField<GameMapRecord> = {}
  get game_map_id() {
    return this.#gameMap.id
  }
  set game_map_id(value) {
    this.#gameMap.id = value
  }

  get gameMap() {
    return this.#gameMap.object
  }
  async getGameMap() {
    return this.getObjectFromRefField(this.#gameMap, 'gameMaps')
  }
  /// <<< game map <<<

  /// >>> match >>>
  #match: BaseRecord.RefField<MatchRecord> = {}
  get match_id() {
    return this.#match.id
  }
  set match_id(value) {
    this.#match.id = value
  }

  get match() {
    return this.#match.object
  }
  async getMatch() {
    return this.getObjectFromRefField(this.#match, 'matches')
  }
  /// <<< match <<<

  /// >>> match map >>>
  #matchMap: BaseRecord.RefField<MatchMapRecord> = {}
  get match_map_id() {
    return this.#matchMap.id
  }
  set match_map_id(value) {
    this.#matchMap.id = value
  }

  get matchMap() {
    return this.#matchMap.object
  }
  async getMatchMap() {
    return this.getObjectFromRefField(this.#matchMap, 'matchMaps')
  }
  /// <<< match map <<<

  /// >>> tournament >>>
  #tournament: BaseRecord.RefField<TournamentRecord> = {}
  get tournament_id() {
    return this.#tournament.id
  }
  set tournament_id(value) {
    this.#tournament.id = value
  }

  get tournament() {
    return this.#tournament.object
  }
  async getTournament() {
    return this.getObjectFromRefField(this.#tournament, 'tournaments')
  }
  /// <<< tournament <<<

  /// >>> computed values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get has_game_map_id() {
    return !!this.gameMap?.id
  }

  get has_game_map_id_string() {
    return `${this.has_game_map_id}`
  }
  get region_name() {
    return this.region?.ident
  }
  get gameMap_name() {
    return this.gameMap?.name
  }
  get match_name() {
    return this.match?.team1_name && this.match?.team2_name
      ? `${this.match?.team1_name} vs ${this.match?.team2_name}`
      : ''
  }
  get tournament_name() {
    return this.tournament?.name
  }
  get period_kind_name() {
    return this.period_kind?.ident
  }
  get sortValues() {
    return [
      !this.has_game_map_id
    ]
  }
  /// <<< computed values <<<

  static createNew(
    db?: BaseRootRepository.OrNothing,
    jsonData?: TimespacesRecord.CtorJsonData
  ) {
    return new TimespacesRecord(db, jsonData)
  }

  constructor(db?: BaseRootRepository.OrNothing, jsonData?: TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.sport_id = jsonData?.sport_id
    this.region_id = jsonData?.region_id
    this.period_kind_id = jsonData?.period_kind_id
    this.game_map_id = jsonData?.game_map_id
    this.match_map_id = jsonData?.match_map_id
    this.match_id = jsonData?.match_id
    this.tournament_id = jsonData?.tournament_id
    this.year = jsonData?.year
    this.months = jsonData?.months
    this.matches = jsonData?.matches
    this.tournaments = jsonData?.tournaments
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
  }

  toJson(): TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      region_id: this.region_id,
      period_kind_id: this.period_kind_id,
      game_map_id: this.game_map_id,
      match_map_id: this.match_map_id,
      match_id: this.match_id,
      tournament_id: this.tournament_id,
      sport_id: this.sport_id,
      year: this.year,
      months: this.months,
      matches: this.matches,
      tournaments: this.tournaments,
      data_frozen_at: this.data_frozen_at?.toISOString(),
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const matchMap = await this.getMatchMap()
    await matchMap?.preload()

    const match = await this.getMatch()
    await match?.preload()

    const gameMap = await this.getGameMap()
    await gameMap?.preload()

    const region = await this.getRegion()
    await region?.preload()

    const periodKind = await this.getPeriodKind()
    await periodKind?.preload()

    const tournament = await this.getTournament()
    await tournament?.preload()
  }
}

export module TimespacesRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    region_id: string
    period_kind_id: string
    game_map_id: string
    match_map_id?: string
    match_id?: string
    tournament_id?: string
    sport_id?: string
    year?: number
    months?: number
    matches?: number
    tournaments?: number
    data_frozen_at?: string
  }

  export class Repository extends RecordRepository<TimespacesRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    protected loadById(id: string) {
      return this.getApi().getOneTimespaceById(id)
    }

    protected loadAll() {
      return this.getApi().getAllTimespaces()
    }

    protected updateById(id: string, patch: Partial<TimespacesRecord<CtorJsonData>>): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected deleteById(id: string): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected approveById(id: string, patch: Partial<TimespacesRecord<CtorJsonData>>): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<TimespacesRecord<CtorJsonData>>): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected publishById(id: string, patch: Partial<TimespacesRecord<CtorJsonData>>): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Method not implemented.')
    }

    protected unpublishById(id: string, patch: Partial<TimespacesRecord<CtorJsonData>>): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Method not implemented.')
    }

    protected freezeById(id: string, patch: Partial<TimespacesRecord<CtorJsonData>>): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<TimespacesRecord<CtorJsonData>>): Promise<TimespacesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTimespaces()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTimespaceById(id)
    }
  }
}
