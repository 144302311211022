import { type } from "os";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class TeamOrganizationRecord<TJsonData extends TeamOrganizationRecord.CtorJsonData = TeamOrganizationRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  name?: string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TeamOrganizationRecord.CtorJsonData) {
    return new TeamOrganizationRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.name = jsonData?.name
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      name: this.name
    }) as TJsonData
  }
}

export module TeamOrganizationRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    name?: string
  }

  export class Repository extends RecordRepository<TeamOrganizationRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTeamOrganization(record)
    }

    protected loadAll() {
      return this.getApi().getAllTeamOrganizations()
    }

    protected loadById(id: string): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      return this.getApi().getOneTeamOrganizationById(id)
    }
    protected updateById(id: string, patch: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTeamOrganization(id, patch)
    }
    protected deleteById(id: string): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTeamOrganization(id)
    }
    protected approveById(id: string, patch: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected publishById(id: string, patch: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unpublishById(id: string, patch: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<TeamOrganizationRecord<CtorJsonData>>): Promise<TeamOrganizationRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
  }
}
