export enum Tab {
  Datasources = 'Datasources',
  SportsmanStatScales = 'Sportsman Stat Scales',
  TeamStatScales = 'Team Stat Scales',
  Sports = 'Sports',
  Tournaments = 'Tournaments',
  TournamentBiographies = 'Tournament Biographies',
  TournamentDatasourceIdentifiers = 'Tournament Datasource identifiers',
  TournamentStageDatasourceIdentifiers = 'Tournament Stage Datasource identifiers',
  Stages = 'Stages',
  Rounds = 'Rounds',
  Matches = 'Matches',
  MatchDatasourceIdentifiers = `Match Datasource Identifiers`,
  MatchMaps = 'Match Maps',
  MatchMapVetos = 'Match Map Vetos',
  Teams = 'Teams',
  Players = 'Players',
  Sportsmen = 'Sportsmen',
  Ranks = 'Ranks',
  Statistics = 'Statistics',
  Timespaces = 'Timespaces',
  TeamStats = 'Team Stats',
  TeamOrganizations = 'Team Organizations',
  SportsmanDatasourceIdentifiers = 'Sportsman Datasource identifiers',
  SportsmanStats = 'Sportsman Stats',
  SportsmanRanks = 'Sportsman Ranks',
  SportsmanTeamMemberships = 'Sportsman Team Memberships',
  SportsmanBiographies = 'Sportsman Biographies',
  TeamRanks = 'Team Ranks',
  TeamDatasourceIdentifiers = 'Team Datasource identifiers',
  TeamOrganizatonDatasourceIdentifiers = 'Team Organization Datasource identifiers',
  TeamBiographies = 'Team Biographies',
  GammingGears = 'Gaming Gears',
  SportsmanGamingGears = 'Sportsman Gaming Gears',
  NearestMatches = 'Nearest Matches',
}
