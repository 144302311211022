import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import SportsmanNickname from '../../Sportsmen/SportsmanNickname'
// Styles
import { CheckIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { PlayerRecord } from 'data/PlayerRecord'
import { RecordsSetting } from 'components/utils/Table/_Table.settings'

export module Settings {
  export const get = (
    id?: {
      tournament?: string
      match?: string
    },
    options?: {
      disableNew?: boolean
    }
  ): RecordsSetting<PlayerRecord> => {
    return [
      {
        accessor: 'id',
        className: 'table-hidden',
      },
      {
        accessor: 'sportsman_is_approved',
        className: 'table-hidden',
      },
      {
        accessor: 'team_name',
        Header: 'Team',
        className: 'table-team-name',
      },
      {
        accessor: 'sportsman_nickname',
        Header: 'Sportsman',
        className: 'table-sportsman',
        Cell: function CellContent({ row }: any) {
          return (
            <SportsmanNickname
              nickname={row.values.sportsman_nickname}
              isApproved={row.values.sportsman_is_approved}
            />
          )
        },
      },
      {
        accessor: 'sportsman_role_name',
        Header: 'Role',
        className: 'table-role',
      },
      {
        accessor: 'isApproved',
        Header: 'Approved',
        className: 'table-approved',
        Cell: function CellContent({ row }: any) {
          return <>{row.values.isApproved && <CheckIcon />}</>
        },
      },
      {
        id: 'ctaButton',
        sticky: 'right',
        className: 'table-cta-button',
        Header: function HeaderContent({ row }: any) {
          const { toggle } = useContext(AccordionContext)

          if (options?.disableNew) return <></>

          return (
            <CTAButton onClick={toggle}>
              <EllipsisHIcon />
            </CTAButton>
          )
        },
        Cell: function CellContent({ row }: any) {
          return (
            <CTAButton
              path={Routing.getPlayerDetailUrl({
                tournament: id?.tournament ?? Routing.NewRecordSymbol,
                match: id?.match ?? Routing.NewRecordSymbol,
                player: row.values.id,
              })}
            >
              {row.values.isFrozen ? 'View' : 'Edit'}
            </CTAButton>
          )
        },
      },
    ]
  }
}
