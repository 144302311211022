import React, { useContext } from 'react'
// Components
import UIColumnFilter from 'components/utils/Filter/ColumnFilter'
// Styles
import CTAButton from 'components/utils/Button/CTAButton'
import { LockIcon, CheckIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { Tab } from 'static/tabs'
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { SportRecord } from 'data/SportRecord'
import { RecordsSetting } from 'components/utils/Table/_Table.settings'

const Settings: RecordsSetting<SportRecord> = [
  {
    accessor: 'id',
    className: 'table-hidden',
  },
  {
    accessor: 'isFrozen',
    className: 'table-lock',
    Header: function HeaderContent({ row }: any) {
      return <LockIcon />
    },
    Cell: function CellContent({ row }: any) {
      return <>{row.values.isFrozen && <LockIcon />}</>
    },
  },
  {
    accessor: 'full_name',
    Header: 'Full Name',
    className: 'table-full-name',
    Filter: function FilterContent(p: any) {
      return <UIColumnFilter from={Tab.Sports} filter={p} />
    },
    filter: 'fuzzyText',
  },
  {
    accessor: 'short_name',
    Header: 'Short Name',
    className: 'table-short-name',
    Filter: function FilterContent(p: any) {
      return <UIColumnFilter from={Tab.Sports} filter={p} />
    },
    filter: 'fuzzyText',
  },
  {
    accessor: 'logo_url',
    Header: 'Logo',
    className: 'table-logo',
  },
  {
    accessor: 'isApproved',
    Header: 'Approved',
    className: 'table-approved',
    Cell: function CellContent({ row }: any) {
      return <>{row.values.isApproved && <CheckIcon />}</>
    },
  },
  {
    id: 'ctaButton',
    sticky: 'right',
    className: 'table-cta-button',
    Header: function HeaderContent({ row }: any) {
      const { toggle } = useContext(AccordionContext)

      return (
        <CTAButton onClick={toggle}>
          <EllipsisHIcon />
        </CTAButton>
      )
    },
    Cell: function CellContent({ row }: any) {
      return (
        <CTAButton path={Routing.getSportDetailUrl({ sport: row.values.id })}>
          {row.values.isFrozen ? 'View' : 'Edit'}
        </CTAButton>
      )
    },
  },
]

export default Settings
